import { createSlice } from "@reduxjs/toolkit";

export const gatewaysSlice = createSlice({
  name: "gateways",
  initialState: {
    gateways: [],
  },
  reducers: {
    getGatewayData: (state, { payload }) => {
      state.gateways = payload;
    },
    editName: (state, { payload }) => {
      const index = state.gateways.findIndex(
        (device) => device.DEVEUI === payload.DEVEUI
      );
      if (index !== -1) {
        state.gateways[index].DEVICE_NAME = payload.DEVICE_NAME;
      }
    },
    clearData: (state) => {
      state.gateways = [];
    },
  },
});

export const {
  editName: editNameAction,
  getGatewayData: getGatewayDataAction,
  clearData: clearSettingsDataAction,
} = gatewaysSlice.actions;

export default gatewaysSlice.reducer;
