import React, { useEffect } from "react";
import Footer from "components/landing_page/footer/Footer";
import Header from "components/landing_page/header/Header";
import "../home/PrincipalDiv.css";
import BreadcrumbsBar from "../common/BreadcrumbsBar";
import TitleBanner from "../common/TitleBanner";
//import { makeStyles } from "@material-ui/core";
//import { createTheme } from "@material-ui/core";
import "./use-cases.css";
//import { useAuth0 } from "@auth0/auth0-react";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const UseCases = () => {
  //  const { loginWithRedirect } = useAuth0();

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/useCasesMobile");
    }
  }, [width, history]);

  useEffect(() => {
    document.title = "Use Cases | LoRa Cloud™ Locator | Semtech";
  }, []);

  const breadcrumbs_path = [
    ["HOME", "/landing"],"USE CASES"];

  return (
    <div>
      <Header />
      <TitleBanner title="Use Cases" />
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      <div className="useCaseMainLP">
        {/* <center><h3 className='TitleLDUsecases'>Unlimited Asset Tracking Possibilities With LoRa Edge™</h3></center>
                <p className="TextLDUsecases">
                Customers and consumers across several industry verticals face challenges finding vehicles in large yards
                , wheelchairs in hospitals, shopping carts in retail outlets
                , pallets in warehouses, cattle on farms, or pets in their homes.
                <br/>
                <br/>
                <b><i>
                 “Given the high prices of enterprise and industrial assets, especially fleet equipment,
                  the need for monitoring and tracking these assets is paramount. Further, in a bid to determine the total cost of ownership, 
                  managers across industries are finding the need for a centralized system that provides critical information such as location, 
                  maintenance history, contract (if any) among others, for all assets, in real-time.” 
                  </i></b> 
                  <br/>
                  Asset Tracking Market - Growth, Trends, and Forecast (2020 - 2025), Mordor Intelligence.
                  <br/> <br/>
                  LoRa Edge™ ultra-low power platform paves the way to a wide array of asset tracking use cases. 
                  LoRa Edge uses Semtech’s LoRa Cloud™ geolocation capabilities to significantly reduce power consumption by determining asset location 
                  in a Cloud-based solver.
                  <br/><br/>
                   The LoRa Edge LR1110 chip combines Wi-Fi and GNSS scanning to continuously obtain the latitude and longitude 
                  of devices for outdoor and indoor location. Combined together with LoRa® radio for transmission to the Cloud, LoRa Edge guarantees 
                  complete connectivity anytime and anywhere. Building an application with LoRa Edge provides cost-effective
                , deep indoor equipment tracking capabilities with device batteries lasting up to 10 years due to their ultra low-power capability.
                </p>
                <div className='TwoColumnsDivUseCasesLP'>
                    <div className='LeftColumnDivUseCasesLP'>
                     <center>  <p>Curious to find out if LoRa Edge is the right choice for your use case?</p></center> 
                        <button onClick={() => loginWithRedirect()} className="BlueButtonUseCasesLP">CREATE A LOCATOR ACCOUNT AND TRY OUT FOR FREE!</button>
                    </div>
                    <div className='RightColumnDivUseCasesLP'>
                    <center>  <p>Interested in building or buying your own LoRa Edge enabled asset tracking use case?</p></center> 
                        <a href="https://lora-developers.semtech.com/" target="_blank" rel="noopener noreferrer" className="BlueButtonUseCasesLP">VISIT DEVELOPER PORTAL</a>
                    </div>
                </div>
                */}

        <center>
          <div className="TitleLDUsecases">
            <h3 style={{ color: "#333" }}>Explore a Variety of Use Cases </h3>
          </div>
        </center>

        <div className="TwoColumnsDivUseCasesLP">
          <div className="LeftColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[0].url} alt="usecases1" id="Healthcare"></img>
            </div>
            <h3>SUPPLY CHAIN</h3>
            <h4>Pallet and Forklift Tracking</h4>
            <p className="TextUsecasesLPLeft">
              Significant time is lost, and cost is incurred locating and
              retrieving pallets, forklifts and other equipment throughout large
              warehouse areas at industrial sites, ports and airports. Assets
              equipped with LoRa Edge-enabled devices can always be located,
              whether in transit or stationed within a set wide area.
            </p>

            <h4>Cold Chain Monitoring</h4>
            <p className="TextUsecasesLPLeft">
              It is imperative for industries such as food and pharmaceuticals
              to provide continuously refrigerated production, distribution and
              storage of products. Companies can now have visibility into
              temperature-controlled supply chain to identify sections which may
              have already or maybe about to malfunction. LoRa Edge provides
              full traceability whether indoors, outdoors or in transit with
              precise location complementing temperature data.
            </p>
          </div>
          <div className="RightColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[1].url} alt="usecases2" id="HomeConsumer"></img>
            </div>
            <h3>AGRICULTURE</h3>
            <h4>Cattle Tracking </h4>
            <p className="TextUsecasesLPLeft">
              Tracking the health and location of livestock is essential to any
              cattle rearing business. Farmers can deploy a network of animal
              trackers to cost-effectively, accurately and remotely monitor
              their herd that are constantly on the move indoors and outdoors to
              ensure they are healthy and remain in the safe areas.
            </p>

            <h4>Tracking Equipment & Machinery </h4>
            <p className="TextUsecasesLPLeft">
              Livestock farms and croplands can take up incredibly large spaces
              of up to 2000 acres. For time and efficiency purposes, farmers
              greatly benefit from smart tracking systems to monitor the
              whereabouts of their equipment and machinery.
            </p>
          </div>
        </div>

        <div className="TwoColumnsDivUseCasesLP">
          <div className="LeftColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[2].url} alt="usecases3" id="Retail"></img>
            </div>
            <h3>CITIES</h3>
            <h4>Micro Mobility</h4>
            <p className="TextUsecasesLPLeft">
              The need for and access to eco-friendly micro vehicle
              transportation such as bikes and scooters increases with the
              rising costs of gasoline, climate initiatives and innovations in
              batteries and electric motors. Service providers seek ways to
              locate bikes and scooters even after the battery has depleted for
              service and battery charging. The ability to track and trigger
              location-based alerts to mitigate theft is highly desirable by
              operators and owners alike.
            </p>

            <h4>Trash Can Sensors </h4>
            <p className="TextUsecasesLPLeft">
              City services constantly seek ways to improve efficiency and waste
              management is no exception. With a citywide network of sensors
              attached to each bin, employees can monitor in real time which are
              full or empty, enabling them to send collection teams out when and
              wherever necessary.
            </p>
          </div>
          <div className="RightColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[3].url} alt="usecases4" id="Industrial"></img>
            </div>
            <h3>RETAIL</h3>
            <h4>Shopping Cart Tracking</h4>
            <p className="TextUsecasesLPLeft">
              Supermarkets lose shopping carts from their premises every year.
              Business owners can deploy a network of devices enabling the
              tracking of shopping carts indoors, underground and outside.
              Create geofences and instant notifications when a cart leaves the
              area and quickly locate every cart wherever they are. By deploying
              a private gateway around retail outlet premises, retail owners can
              benefit from avoiding monthly connectivity fees.
            </p>

            <h4>Inventory/Stock Management </h4>
            <p className="TextUsecasesLPLeft">
              Managing inventory in retail or warehouse settings, whether to
              prevent loss, increase product availability or improve overall
              customer experience, is crucial to any business. Asset trackers
              provide complete visibility into location of items indoors,
              outside and underground with deep penetration connectivity so
              users always know the exact location of assets and can even
              calculate the optimal route to collect them.
            </p>
          </div>
        </div>

        <div className="TwoColumnsDivUseCasesLP">
          <div className="LeftColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img
                src={locatorImages.useCasePageImg[4].url}
                alt="usecases5"
                id="AgricultureForestry"
              ></img>
            </div>
            <h3>HEALTHCARE</h3>
            <h4>Tracking Hospital Equipment</h4>
            <p className="TextUsecasesLPLeft">
              Hospital staff lose an inordinate amount of time searching for
              hospital equipment required to service patients. By deploying a
              network of tracking devices, users can follow any object
              throughout the entire hospital premises — indoors, underground and
              outside. This enables staff to immediately locate everything from
              hospital beds to wheelchairs while minimizing delays and adding
              more critical time for patient care.
            </p>
          </div>
          <div className="RightColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[5].url} alt="usecases6" id="BuildingInfra"></img>
            </div>
            <h3>UTILITIES</h3>
            <h4>Smart Metering</h4>
            <p className="TextUsecasesLPLeft">
              Utilities are heavily reliant on manual readings and lack
              personnel to provide accurate, real-time data for cost and
              efficiency optimization. Track and locate assets such as smart gas
              and water meters for targeted maintenance and automatic readings.
              With LoRa Edge, users can automate utility management to limit
              human error and contribute to smarter cities.
            </p>
          </div>
        </div>

        <div className="TwoColumnsDivUseCasesLP">
          <div className="LeftColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[6].url} alt="usecases7" id="SupplyChain"></img>
            </div>
            <h3>INDUSTRIAL </h3>
            <h4>Aerospace &Automotive </h4>
            <p className="TextUsecasesLPLeft">
              To accurately track the location of costly manufacturing assets
              within automobile or airplane factories, factory managers can
              deploy a network of asset trackers to trace equipment throughout
              large facilities, indoors, underground and outdoors. By quickly
              deploying trackers to monitor assets that are constantly in
              transit, production lead time is greatly reduced and alerts are
              sent whenever an asset leaves the factory’s floor.
            </p>

            <h4>Construction Employee & Equipment Tracking </h4>
            <p className="TextUsecasesLPLeft">
              Construction sites are wide open and easily accessible areas,
              which heightens risk of theft and security issues. Monitor and
              track workers, equipment and tools to improve safety, control
              entry points and prevent loss or theft of assets. LoRa
              Edge-enabled trackers help secure entire zones and detect
              unauthorized access.
            </p>
          </div>
          <div className="RightColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[7].url} alt="usecases8" id="SmartCities"></img>
            </div>
            <h3>BUILDING</h3>
            <h4>Indoor Asset Inventory</h4>
            <p className="TextUsecasesLPLeft">
              To maximize utilization of equipment, building managers can deploy
              a network of devices to cost-effectively, accurately and remotely
              locate equipment around a specific location as well as perform
              regular, automated inventory of both fixed and moving assets.
              Asset tracking within a building space can involve machinery,
              documents, amenities in public or private spaces including
              warehouses, offices and hospitals.
            </p>

            <h4>Building Security </h4>
            <p className="TextUsecasesLPLeft">
              The wide areas and vertical structure of buildings make enhancing
              security beyond the capabilities of camera surveillance systems a
              challenge. With sensors tracking items, equipment and employee
              movements both indoors and outdoors, emergencies or issues can be
              identified automatically and addressed quicker.
            </p>
          </div>
        </div>

        <div className="TwoColumnsDivUseCasesLP">
          <div className="LeftColumnDivUseCasesLP">
            <div className="imgleftalignusecasesLP">
              <img src={locatorImages.useCasePageImg[8].url} alt="usecases9" id="Utilities"></img>
            </div>
            <h3>CONSUMER</h3>
            <h4> Tracking Personal High Value Assets </h4>
            <p className="TextUsecasesLPLeft">
              General purpose trackers with LoRa Edge can enable consumers to
              track a variety of their personal high value assets. Whether it’s
              tracking your bike, a package shipped across the country or
              tracking garden equipment and tools, simple asset tracking is
              possible thanks to continuous coverage provided by LoRa Edge.
            </p>
          </div>
          <div className="RightColumnDivUseCasesLP"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UseCases;
