import React, { useEffect } from "react";

import BreadcrumbsBar from "../common/BreadcrumbsBar";
import Header from "../header/Header";
import Footer from "components/landing_page/footer/Footer";
import TitleBanner from "../common/TitleBanner";
import { makeStyles } from "@material-ui/core";
import useWindowDimensions from "components/useWindowDimensions";
import { locatorImages } from "components/img-content/img-content";
import { useHistory } from "react-router-dom";
import { height } from "@fortawesome/free-solid-svg-icons/faCircle";

const breadcrumbs_path = [["HOME", "/landing"], "REFERENCES", "BROWAN"];
export default function Browan() {
  const css = useStyle();
  useEffect(() => {
    document.title =
      "References - Browan | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/references/browanMobile");
    }
  }, [width, history]);

  return (
    <div className="page">
      <Header userData={"false"} />
      <TitleBanner title="Browan"/>
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />

      <div className={css.landingPage}>
        <div className={css.introText}>
              <h2 style={{ color: "#333",fontWeight: '500',fontSize: '2.8rem' }}>
                 What our partners say about LoRa Cloud Locator</h2>
          
 
          <h3 style={{ color: "#333" }}>    <img
                className={css.logoRow1}
                src={locatorImages.referencePages[3].url}
                alt="browanLogo"
              /></h3>
        </div>

        <div style={{display:"center"}}>
          <p style={{fontSize:"24px"}}>
BROWAN COMMUNICATIONS, part of Gemtek Group, is a professional wireless equipment company founded in 2002. 

<br/><br/>
BROWAN dedicates its efforts on the integration of wireless technology such as WiFi, BLE, LTE, UWB and LoRa® to customize products for different customers in the vertical applications of IoT. They are devoted to helping the world realize the vast potential of the IoT world by offering competitive, comprehensive, and compatible IoT products.

<br/><br/>
We developed our Industrial Tracker, a general-purpose tracker, designed for GPS tracking on various applications: bicycles, cars, or pets. It is equipped with GPS and a 3-axis accelerometer, which provides a much more cost-effective way for service providers to deploy their  tracking applications when compared with using a  GPRS network. For this reason, we opted for LoRa Cloud Locator to allow customers to experience the difference in battery life between LoRa Edge™ and On-Device GNSS trackers as well as the ability to obtain location indoors and outdoors. In addition, LoRa Cloud Locator also gives our customers the option to compare different network provider’s performance and coverage using our Industrial Tracker.

<br/><br/>
As of LoRa Cloud Locator’s launch (June 2023), our customers can now visualize the remaining the battery level of the tracker and its current and historical location on the application’s map.

<br/><br/>
We believe that the integration of this application with AWS IoT Core demonstrates that IoT applications can be user-friendly. Furthermore, using different services provided by AWS IoT Core reduces the effort of development and integration with third parties like Mapbox, Cloudflare, LoRa Cloud Services and others. In our case, it has also empowered users to track historical and real-time location data of their devices in several networks and visualize the information on a map easily and with minimal technical knowledge. In addition, because we can see all messages sent by devices, we are able to better understand our customer’s behavior and preferences which, in turn, allows us to offer solutions that will meet their evolving needs. 

<br/><br/>
</p>
</div>
<br></br><div></div>
<center>
 <img
 style={{width:"100%"}}
                src={locatorImages.referencePages[4].url}
                alt="browanMap"
 /></center>

   <div style={{display:"center"}}>
   <p style={{fontSize:"20px",lineHeight: 'normal'}}>

            
<br/><br/><b>
About Semtech</b><br></br><br></br>
Semtech Corporation is a high-performance semiconductor, IoT systems, and cloud connectivity service provider dedicated to delivering high-quality technology solutions that enable a smarter, more connected, and sustainable planet. Our global teams are committed to empowering solution architects and application developers to develop breakthrough products for the infrastructure, industrial, and consumer markets.  To ensure scalability for our services and customer offering, Semtech is now part of the APN (AWS Partner Network), and our AirVantage® Smart Connectivity product is available on AWS Marketplace.

</p></div>

      </div>
      <Footer />
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important

    "& h5": {
      fontSize: " 2.66vh",
      fontWeight: "450",
      color: "#00AEEF",
      textAlign: "center",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
      margin: "0.5%",
    },
  },

  introText: {
    textAlign: "center",
    color: "#333",
    "& h3": {
      fontWeight: "500",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      fontSize: "2.25rem",
      width: "62%",
      margin: "4.8% auto",
      textAlign: "center",
      marginBottom: "5%",
    },

    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "1.3125rem",
      textAlign: "center",
      transition: ".4s background-color",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      padding: ".5% .6%",
      "&:hover": {
        color: "#00aeef",
        backgroundColor: "#ffffff",
        border: "1px solid #00aeef",
      },
    },
  },
  operator: {
    borderBottom: "solid 1px #00AEEF",
    paddingBottom: "3%",
  },
  sectionGrid: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "47.5% 47.5%",
    width: "100%",
    margin: "0 auto",

    rowGap: "2vh",
    columnGap: "5%",
    marginTop: "10%",
    marginBottom: "20%",

    "& div": {
      textAlign: "center",
      minWidth: "30vw",
    },

    "& h3": {
      fontWeight: "550",
      color: "#333",
      fontFamily: "Fira sans",
      fontSize: "24px",
      marginTop: "1%",
      marginBottom: "5%",
    },

    "& a": {
      color: "#00AEEF",
      textDecoration: "underline",
      fontSize: "1.3125rem" /*!important*/,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },

    "& img": {},
  },

  descriptionSection: {
    fontSize: "1.3125rem",
    color: "#333",
  },
  logoRow1: {
    maxWidth: "15.255vw",
  },
  logoRow2: {
    maxWidth: "17vw",
  },
  logoRow3: {
    maxHeight: "17vh",
  },
}));
