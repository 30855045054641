import React, { useEffect } from "react";
import ReactGA from "react-ga";

import Header from "../header/Header";
import Footer from "components/landing_page/footer/Footer";
import TitleBanner from "../common/TitleBanner";
import BreadcrumbsBar from "../common/BreadcrumbsBar";

import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import "./TrackersProductPage.css";

/*Product images*/
import { locatorImages } from "components/img-content/img-content";
import { makeStyles } from "@material-ui/core";
import useAnalyticsEventTracker from "../google-analytics/useAnalyticsEventTracker";
import { GOOGLE_ANALYTICS_TAG } from "../../../environment";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "TRACKERS"];

export default function TrackerProductsPage() {
  ReactGA.initialize(GOOGLE_ANALYTICS_TAG, {gaAddress: 'https://br4dk9unqa.execute-api.eu-west-1.amazonaws.com/Prod/analytics'});
  ReactGA.pageview(window.location.pathname);
  const gaEventTracker = useAnalyticsEventTracker("TRACKER");

  const css = useStyle();
  useEffect(() => {
    document.title = "Trackers | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/trackersMobile");
    }
  }, [width, history]);

  return (
    <div className="page">
      <Header userData={"false"} />
      <TitleBanner title="Trackers" alt="trackers" />
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />

      <div className={css.HeaderTrackersLandingPage}>
        <h3 style={{ color: "#333" }}>
          Order Your LoRa Cloud™ Locator Compatible Trackers
        </h3>
      </div>
      <br />
      <br />

      <div className={css.trackerSectionGrid}>
        {/* Product 1 */}
        <div className={css.deviceImgLeftLanding}>
          <img
            className="carousselProductImage"
            alt="carousselProductImage"
            src={locatorImages.trackersPageImg[0].url}
          ></img>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://www.semtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[14].url} alt="logoSemtech"></img>
          </a>
          <a
            href="https://www.semtech.com/products/wireless-rf/lora-edge/lr1110trk1cks"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-Reference")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              LoRa Edge™ Tracker Reference Design, LR1110{" "}
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN&reg; </td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            The LoRa Edge Tracker Reference Design featuring Semtech's LoRa Edge
            LR1110, is pre-programmed with LoRa Basics™ Modem-E and is ready to
            connect to Semtech’s LoRa Cloud Modem & Geolocation Services. There
            are different versions available multiple bands including US-915,
            EU-868, AU-922, JP-923, AS1-923. The LoRa Edge Tracker Reference
            Design is LoRaWAN Certified<sup>CM</sup>
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />

              <a
                href="https://www.calchipconnect.com/products/semtech-lora-edge-tracker"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Reference-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />

              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/454-lora-edge-tracker-reference-design-lr1110/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Reference-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 2 */}
        <div className={css.deviceImgLeftLanding}>
          <img
            className="carousselProductImage"
            src={locatorImages.trackersPageImg[1].url}
            alt="semtechM2"
          ></img>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://www.semtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[14].url} alt="logoSemtech"></img>
          </a>
          <a
            href="https://www.semtech.com/products/wireless-rf/lora-edge/lr1110dvk1tcks"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-DevKit")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              LoRa Edge Development Kit, LR1110
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN </td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            This evaluation kit features Semtech's LoRa Edge LR1110. It kit
            comes with an STM32 MCU board together with an LR1110 shield which
            plugs into the MCU board together with antennas and everything
            required for your development. There are different versions
            available multiple bands including US-915, EU-868, and CN-490.
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/semtech-lora-edge-development-kit"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("DevKit-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/455-lora-edge-development-kit-lr1110/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("DevKit-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 3 */}
        <div className={css.deviceImgLeftLanding}>
          <Carousel
            className="carousselProductsTrackersLP"
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[2].url}
              alt="digitalmatter1"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[3].url}
              alt="digitalmatter2"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[4].url}
              alt="digitalmatter3"
            />
          </Carousel>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://www.digitalmatter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[15].url} alt="digitalMatter"></img>
          </a>
          <a
            href="https://www.digitalmatter.com/devices/yabby-edge-lorawan/tech-specs/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-Yabby")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              Yabby Edge for LoRaWAN
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN </td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            The Yabby Edge for LoRaWAN is Digital Matter's new compact and
            ultra-rugged battery powered IoT asset tracking device for
            indoor/outdoor tracking and management.
            <br />
            <br /> The Yabby Edge integrates GNSS, Wi-Fi AP MAC Address
            Scanning, and LoRaWAN geolocation for seamless Indoor/Outdoor asset
            tracking and management in key verticals such as supply chain and
            logistics management, parts and inventory tracking, connected
            packaging, and more. <br />
            <br />
            The location processing workload on the Yabby Edge is handled in the
            cloud (versus on-device), substantially decreasing power
            consumption, and contributing to ‘deploy once’ battery life – over
            12 years on LoRaWAN networks on user-replaceable AAA batteries.{" "}
            <br />
            <br />
            The Yabby Edge for LoRaWAN is available in 868 or 902–928 MHz
            versions.
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/digital-matter-yabby-edge-lorawan"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Yabby-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/473-digital-matter-yabby-edge-lorawanreg-compatible-with-semtechs-lora-cloud-locator-service-/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Yabby-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 4 */}
        <div className={css.deviceImgLeftLanding}>
          <Carousel
            className="carousselProductsTrackersLP"
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[2].url}
              alt="digitalmatter1"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[3].url}
              alt="digitalmatter2"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[4].url}
              alt="digitalmatter3"
            />
          </Carousel>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://www.digitalmatter.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[15].url} alt="digitalMatter"></img>
          </a>
          <a
            href="https://www.digitalmatter.com/devices/yabby-edge-cellular/tech-specs/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-YabbyCelullar")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              Yabby Edge Cellular
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>Cellular </td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            The Yabby Edge is Digital Matter's new compact and ultra-rugged
            battery powered IoT asset tracking device for indoor/outdoor
            tracking and management.
            <br />
            <br /> The Yabby Edge integrates GNSS, Wi-Fi AP MAC Address
            Scanning, and Cell Tower location technologies for seamless
            Indoor/Outdoor asset tracking and management in key verticals such
            as supply chain and logistics management, parts and inventory
            tracking, connected packaging, and more. <br /> <br /> The location
            processing workload on the Yabby Edge is handled in the cloud
            (versus on-device), substantially decreasing power consumption, and
            contributing to ‘deploy once’ battery life – over 10 years on
            Cellular LTE-M / NB-IoT networks on user- replaceable AAA batteries.
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/digital-matter-yabby-edge-cellular"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("YabbyCelullar-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/264-digital-matter-yabby-4g-lte-cat-m1-nb-iot-/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("YabbyCelullar-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 5 */}
        <div className={css.deviceImgLeftLanding}>
          <Carousel
            className="carousselProductsTrackersLP"
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[5].url}
              alt="miromico1"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[6].url}
              alt="miromico2"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[7].url}
              alt="miromico3"
            />
          </Carousel>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://miromico.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[16].url} alt="logoMiromico"></img>
          </a>
          <a
            href="https://public-locator.loracloud.com/production/docs/DS_miro_TrackIt_V1_1.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-Miro")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              miro TrackIt
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN </td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            miro TrackIt is a LR1110 based tracking device that integrates a
            multi-standard GPS scanner and passive Wi-Fi MAC address scanner
            with Bluetooth 5.2 connectivity and various sensors such as
            accelerometer, humidity and temperature sensor, into a compact
            device. The IP67-rated housing with different mounting options.
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/miro-trackit"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Miro-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/461-miromico-miro-trackit/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Miro-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 6 */}
        <div className={css.deviceImgLeftLanding}>
          <Carousel
            className="carousselProductsTrackersLP"
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[8].url}
              alt="miromicoEdge1"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[9].url}
              alt="miromicoEdge2"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[10].url}
              alt="miromicoEdge3"
            />
          </Carousel>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://miromico.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[16].url} alt="logoMiromico"></img>
          </a>
          <a
            href="https://public-locator.loracloud.com/production/docs/DS_miro_Edge_V1_2.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-Miro")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              miro TrackIt POC Kit
            </h3>
          </a>
          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>LoRa Edge</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN</td>
              </tr>
            </tbody>
          </table>
          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            Suppose your area is not served by any public network operators
            supported by LoRa Cloud Locator. The TrackIt POC Kit is the ideal
            start to evaluate the low-power miro TrackIt device in a private
            network. The POC Kit comes with a TrackIt device plus a LoRaWAN
            Gateway. It’s as simple as this:
            <ol>
              <li>Connect the Gateway to the Internet (Ethernet version)</li>
              <li>No registration is needed – it’s plug and play</li>
              <li>Follow the Getting Started Guide</li>
            </ol>
          </p>
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/miromico-mirotrackit-poc-kit"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Miro-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/461-miromico-miro-trackit/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Miro-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>

        {/* Product 7 */}
        <div className={css.deviecImgLeftLanding}>
          <Carousel
            className="carousselProductsTrackersLP"
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[11].url}
              alt="browan1"
            />
            <img
              className="carousselProductImage"
              src={locatorImages.trackersPageImg[12].url}
              alt="browan2"
            />
          </Carousel>
        </div>
        <div className={css.deviceRightLanding}>
          <a
            href="https://www.browan.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={locatorImages.trackersPageImg[17].url} alt="logoBrowan"></img>
          </a>
          <a
            href="https://public-locator.loracloud.com/production/docs/Industrial_Tracker_DS.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#00AEEF ", cursor: "pointer" }}
            onClick={() => gaEventTracker("PARTNER-Browan")}
          >
            <h3 style={{ color: "#00AEEF ", cursor: "pointer" }}>
              Industrial Tracker
            </h3>
          </a>

          <table className="tableProductLP">
            <tbody>
              <tr>
                <td className={css.titleType}>Tracker Type</td>
                <td>On-device GNSS</td>
              </tr>
              <tr>
                <td className={css.titleType}>Network Type</td>
                <td>LoRaWAN </td>
              </tr>
            </tbody>
          </table>

          <p
            className={css.trackersLandingPageDeviceDetails}
            style={{ marginBottom: "2%" }}
          >
            The Industrial Tracker is a general purpose tracker, designed for
            GPS tracking on various applications: bicycles, cars or pets. It is
            equipped with GPS and 3-axis accelerometer, that provides a much
            more cost-effective way for service providers to deploy this for
            tracking applications than to use GPRS network.
          </p>
          <p className={css.descriptionBrowan}>
            This device is supported as part of LoRa Cloud Locator to experience
            the difference in battery life between LoRa Edge and On-Device GNSS
            trackers and the ability to obtain location indoors.
          </p>
          <br />
          <p className={css.productDetailsTrackersLanding}></p>
          <br />
          <div className={css.buttonsSection}>
            <div className={css.calchipButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[18].url}
                alt="calchipImg"
              />
              <a
                href="https://www.calchipconnect.com/products/browan-industrial-tracker"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Browan-CALCHIP")}
              >
                ORDER - NA <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
            <div className={css.indesMatechButton}>
              <img
                className={css.logoOrder}
                src={locatorImages.trackersPageImg[19].url}
                alt="indesMatchImg"
              />
              <a
                href="https://iot-webshop.com/shop/9-lora-cloudtrade-locator-compatible-products/463-browan-industrial-tracker/"
                target="_blank"
                rel="noopener noreferrer"
                className={css.productButtonTrackersLanding}
                onClick={() => gaEventTracker("Browan-INDESMATECH")}
              >
                ORDER - EU <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
//  /* <ProductList type='tracker' />*/

const useStyle = makeStyles((theme) => ({
  HeaderTrackersLandingPage: {
    width: "62%",
    fontFamily: "Fira Sans",
    color: "#333",
    textAlign: "center",
    display: "flex",
    margin: "2% auto",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: { fontSize: "190%" },
    "& img": {
      marginRight: "2%",
      marginBottom: "2%",
      maxWidth: "9%",
      height: "-webkit-fill-available",
    },
    "& p": {},
    "& h3": {
      fontSize: "2.25rem",
      color: "#00AEEF",
      fontWeight: "500",
      marginTop: "2%",
      marginBottom: "2%",
    },
  },

  iconTrackersLanding: {
    height: "8vh",
    marginTop: "1%",
    marginBottom: "1%",
  },

  trackerSectionGrid: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
    width: "80%",
    margin: "0 auto",
    rowGap: "3em",
    marginBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "100%",
    },
  },
  divTrackerSectionLanding: {
    marginTop: "5%",
    marginBottom: "5%",
    marginLeft: "15%",
    maxWidth: "70%",
    display: "flex",
  },
  deviceImgLeftLanding: {
    margin: "0 auto",
    textAlign: "center",
    "& img": {
      width: "80%",
    },
  },
  deviceRightLanding: {
    marginBottom: "5%",
    fontFamily: "Fira Sans",
    borderBottom: "solid 1px #00AEEF",
    paddingBottom: "4%",

    "& h3": {
      fontWeight: "550",
      color: "#333",
      fontFamily: "Fira sans",
      fontSize: "1.3125rem",
      marginTop: "3%",
      marginBottom: "3%",
      marginLeft: "0",
      lineHeight: "0%",
    },
    "& h4": {
      color: "#00AEEF",
      fontFamily: "Fira sans",
      fontSize: "2.222vh",
      fontweight: "500",
      margin: "0",
    },
    "& P": {
      color: "#333",
      marginTop: "0%",
      fontFamily: "Fira sans",
      marginBottom: "0%",
      width: "85%",
      lineHeight: "normal",
    },
    "& img": {
      maxWidth: "12.98vw",
      height: "auto",
    },
    "& ul": {
      display: "flex",
    },
    "& li": {
      marginRight: "2em",
      fontWeight: "400",
    },
  },
  productDetailsTrackersLanding: {
    "& a": {
      color: "#00AEEF",
      textDecoration: "underline",
      fontSize: "2.24vh" /*!important*/,
    },
    textDecoration: "underline",
    color: "#00AEEF" /*!important*/,
    fontFamily: "Fira sans",
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
  },

  productLinkTrackersLanding: {
    "& a": {
      color: "#00AEEF" /*!important*/,
      textDecoration: "none",
      marginBottom: "3%",
      paddingBottom: "3%",
    },
  },

  productButtonTrackersLanding: {
    color: "#FFF",
    backgroundColor: "#00AEEF",
    border: "solid 1px #00AEEF",
    padding: "6px 10px",
    textDecoration: "none",
    fontFamily: "Fira sans",
    fontWeight: "lighter",
    fontSize: "1.3125rem",
    marginLeft: "10%",

    textAlign: "left",
    opacity: "100",
    transition: "opacity .15s ease-in-out",
    height: "fit-content",
    "&:hover": {
      color: "#fff",
      border: "1px solid #00aeef",
      transition: "opacity .15s ease-in-out",
      opacity: ".8",
    },
  },

  trackersLandingPageDeviceDetails: {
    fontSize: "1.3125rem",
  },
  descriptionBrowan: {
    fontWeight: "bold",
    fontSize: "1.3125rem",
    marginBottom: "2%",
  },

  indesMatechButton: {
    color: "#333",
    fontSize: "1.95vh",
    textAlign: "left",
    display: "flex",
    width: "80%",
    minWidth: "30vw",
    alignItems: "center",
  },
  calchipButton: {
    color: "#333",
    fontSize: "1.95vh",
    textAlign: "left",
    display: "flex",
    width: "80%",
    minWidth: "30vw",
    alignItems: "center",
  },
  buttonsSection: {
    width: "100%",
  },

  titleType: {
    fontWeight: "500",
  },
}));
