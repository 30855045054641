import { createSlice } from "@reduxjs/toolkit";

export const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    docs:[]
  },
  
  reducers: {
    getDoc: (state, { payload }) => {
      state.docs = payload;
    }
  },
});

export const {
    getDoc:getDocsAction
} = documentsSlice.actions;

export default documentsSlice.reducer;
