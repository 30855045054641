import axios from "axios";
import { AUTH0_DOMAIN, ATK_API_URL, ATK_API_URL_GATEWAYS, ATK_API_URL_DEVICES,AUTH0_AUDIENCE, ATK_API_URL_QR } from "../environment";
import { getEndingDayDate, getStartingDayDate } from "utils/helperFunctions";

const rootURL = ATK_API_URL;
const gatewaysURL = ATK_API_URL_GATEWAYS;
const devicesURL = ATK_API_URL_DEVICES;
const audience = AUTH0_AUDIENCE;
const qrCodeURL = ATK_API_URL_QR;

const axiosCall = (method, url, data, headers) =>
  axios({ method, url, data, headers });

const getBearerToken = async () => {
  const token = window.localStorage.token

  return `Bearer ${token}`
};

export default {
  getDocuments: async () => {
    const response = await axiosCall(
      "GET",
      `${rootURL}/support/doc/list`,
      {},
    
      
    );
    return response.data;
  },
  qrRead: async (image) => {
    const jsonImage={"content":image};

   
    const response = await axiosCall(
      "POST",
      `${qrCodeURL}/qrcode`,jsonImage
    );
    return response.data;
  },
  getDocument: async (doc) => {
    
    const response = await axiosCall(
      "GET",
      `${rootURL}/support/doc/download?files=${doc}`,
      {},
   
    );
    return response.data;
  },
  getNews: async () => {
  
    const response = await axiosCall(
      "GET",
      `${rootURL}/support/news/list`,
      {},
    );
    return response.data;
  },
  getNew: async (news) => {
    
    const response = await axiosCall(
      "GET",
      `${rootURL}/support/news/download?files=${news}`,
      {},
   
    );
    return response.data;
  },


  getDeviceLogs: async (devEui, previous_timestamp) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    if(previous_timestamp === "")
    {
    const response = await axiosCall(
      "GET",
      `${devicesURL}/device/${devEui}/logs`,
      {},
      header
    );
    return response.data;
    }else{
      const response = await axiosCall(
        "GET",
        `${devicesURL}/device/${devEui}/logs?previous_timestamp=${previous_timestamp}`,
        {},
        header
      );
      return response.data;
    }

  },


  getGatewayLogs: async (devEui, previousTstamp = null) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    let response;
    if(!previousTstamp)
    {
      response = await axiosCall(
        "GET",
        `${gatewaysURL}/gateway/${devEui}/logs`,
        {},
        header
      ).catch(function (error) {
        return error
      })
    }else{
      response = await axiosCall(
        "GET",
        `${gatewaysURL}/gateway/${devEui}/logs?previous_timestamp=${previousTstamp}`,
        {},
        header
      ).catch(function (error) {
        return error
      })
    }
    return response.data;

  },


  getBatteryHistory: async (devEui) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const responseBattery = await axiosCall(
      "GET",
      `${devicesURL}/device/${devEui}/battery/history`,
      {},
      header
    );
    return responseBattery.data;
  },

  resetBattery: async (devEui) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const responseBattery = await axiosCall(
      "POST",
      `${devicesURL}/device/${devEui}/battery/reset`,
      {},
      header
    );
    return responseBattery.data;
  },

  getUserTerms: async () => {
    const token = await getBearerToken();
    const header = { Authorization: `${token}` };
    let response;

    try {
      response = await axiosCall(
        "GET",
        `${rootURL}/atk/users`,
        {},
        header
      );
    } catch (error) {
      console.error('error', error);
    }
    return response.data;
  },

  CompleteUserRegistration: async (auth0_id,email, country , companyName) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const body = {};
    body['auth0_id']=auth0_id;
    body['email']=email;
    body['country']=country;
    body['companyName']=companyName;
    body['audience']=audience;
    const response = await axiosCall(
      "PATCH",
      `${rootURL}/atk/users`,
      body,
      header
    );
    return response.data;
  },

  postUserTerms: async () => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${rootURL}/atk/users`,
      {},
      header
    );
    return response.data;
  },
  getAllDevices: async () => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${rootURL}/atk/devices/getuserdevices`,
      {},
      header
    );
    return response.data;
  },
  getTrackerData: async (
      a = new Date(),
      addDays = -30,
      latestPositions = false,
      deveui = null,
      limit=null,
      lastEvaluatedKey = null
  ) => {
    let body = {};
    if (latestPositions) {
      body = { "ONLY_LAST_RECORD_PER_DEVICE": true };
    } else {
      if (!a) { a = new Date(); }

      const b = new Date(a.getTime());
      b.setDate(b.getDate() + addDays);

      let startDate, endDate;
      if (a >= b) {
        startDate = getStartingDayDate(b);
        endDate = getEndingDayDate(a);
      } else {
        startDate = getStartingDayDate(a);
        endDate = getEndingDayDate(b);
      }

      body = {
        "START_DATE": parseInt(startDate.getTime() / 1000),
        "END_DATE": parseInt(endDate.getTime() / 1000)
      }
    }

    if (deveui !== null){
      body['DEVEUI'] = deveui
    }
    if (limit !== null){
      body['LIMIT'] = limit
    }
    if (lastEvaluatedKey !== null){
      body['LAST_EVALUATED_KEY'] = lastEvaluatedKey
    }

    const response = await axiosCall(
      "POST",
      `${rootURL}/atk/devices/gettrackerdata`,
      body,
      { authorization: await getBearerToken() }
    );
    return response.data;
  },
  getDeviceDataDateMarks: async (year = (new Date()).getFullYear()) => {
    try {
      return (
        await axiosCall(
          "GET",
          `${rootURL}/atk/devices/data/date/list?year=${year}`,
          null,
          { authorization: await getBearerToken() }
        )
      ).data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },
  getAllGateways: async () => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "GET",
      `${gatewaysURL}/list_gateways`,
      {},
      header
    );
    return response.data;
  },
  getAllDevices2: async () => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "GET",
      `${devicesURL}/list_devices`,
      {},
      header
    );
    return response.data;
  },
  addDevice: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${devicesURL}/add_devices`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },
  sendSettingsRequest: async(devEui, paramsToGet, paramsToSet) => {
    try {
      return (await axiosCall(
        "POST",
        `${devicesURL}/device/${devEui}/settings`,
        JSON.stringify({
          get_params: paramsToGet,
          set_params: paramsToSet
        }),
        { authorization: await getBearerToken() }
      )).data;
    } catch(err) {
      console.error(err);
      return null;
    }
  },
  getCurrentSettings: async(devEui) => {
    try {
      return (await axiosCall(
        "GET", `${devicesURL}/device/${devEui}/settings`, {},
        { authorization: await getBearerToken() }
      )).data;
    } catch(err) {
      console.error(err);
      return null;
    }
  },
  deleteDevice: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${devicesURL}/remove_devices`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },
  deleteGateway: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "DELETE",
      `${gatewaysURL}/remove_gateways?gateways=${data}`,
      // JSON.stringify(data),
      {},
      header
    );
    return response.data;
  },

  updateTrackerName: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${rootURL}/atk/devicesettings/changetrackername`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },
  updateGatewayName: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${gatewaysURL}/update_gateway`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },
  updateTrackerFrequency: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };
    const response = await axiosCall(
      "POST",
      `${rootURL}/atk/devicesettings/changetrackerfrequency`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },
  addGateway: async (data) => {
    const token = await getBearerToken();
    const header = { authorization: token };

    const response = await axiosCall(
      "POST",
      `${gatewaysURL}/add_gateways`,
      JSON.stringify(data),
      header
    );
    return response.data;
  },

  getAuth0UserInfo: async () => {
    const token = await getBearerToken();

    const response = await axiosCall(
      'GET',
      `https://${AUTH0_DOMAIN}/userinfo`,
      {},
      { Authorization: `${token}` },
    );

    return response.data
  },

};
