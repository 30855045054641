/**
 * Environment variables defined in .env file
 */

export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_COGNITO_POOL_ID = process.env.REACT_APP_AWS_COGNITO_POOL_ID;
export const AWS_COGNITO_APP_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID;
export const ATK_API_URL = process.env.REACT_APP_ATK_API_URL;
export const ATK_API_URL_GATEWAYS = process.env.REACT_APP_ATK_API_URL_GATEWAYS;
export const ATK_API_URL_DEVICES = process.env.REACT_APP_ATK_API_URL_DEVICES;
export const ATK_API_URL_QR = process.env.REACT_APP_QR_API_URL;
export const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_SCOPE = process.env.REACT_APP_AUTH0_SCOPE;
export const APPSYNC_URL = process.env.REACT_APP_APPSYNC_URL;
export const APPSYNC_TOKEN = process.env.REACT_APP_APPSYNC_TOKEN;
export const GOOGLE_ANALYTICS_TAG = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const IMAGES_BUCKET_URL = process.env.REACT_APP_IMAGES_BUCKET_URL
export const IMAGES_BUCKET_ENVIRONMENT= process.env.REACT_APP_IMAGES_BUCKET_ENVIRONMENT;