import { createSlice } from "@reduxjs/toolkit";

const state = {
  hiddenDevices: [],
  hiddenLines: [],
  hiddenDevicesGNSS: [],
  hiddenDevicesWIFI: [],
  sliderValues: [-7, 0],
  showLatest: false,
  devices: [],
  devicesRealTime: [],
  devicesRealTimeBackup: [],
  devicesBackup: [],
  deviceSearch: "",
  deviceLR1110: [],
  showCalendar: false,
  calendarDate: (new Date()).toISOString().split('T')[0],
  firstDeviceDate: (new Date()).toISOString().split('T')[0],
};

export const trackingSlice = createSlice({
  name: "tracking",
  initialState: state,
  reducers: {
    getData: (state, { payload }) => {
      state.devices = payload;
      state.devicesBackup = payload;
    },
    setDevicesRealTime: (state, { payload }) => {
      state.devicesRealTime = payload;
      state.devicesRealTimeBackup = payload;
    },
    setDevicesRealTimeProcessed: (state, { payload }) => {

      let filtered = []

      payload.forEach(p => {

        let found = false
        filtered.forEach(f => {

          if (f.TSTAMP === p.TSTAMP) {
            found = true
          }
        })
        if (!found) {

          filtered.push(p)
        }
      })

      state.devicesRealTime = filtered;
    },
    appendDeviceRealTime: (state, { payload }) => {
      state.devicesRealTimeBackup = [...payload, ...state.devicesRealTimeBackup, ];

      state.devices = [...payload, ...state.devices, ];
      state.devicesBackup = [...payload, ...state.devicesBackup, ];
    },
    filterDeviceRealTime: (state, { payload }) => {
      state.devicesRealTime = payload;
    },
    getLR1110: (state, { payload }) => {
      if(payload.length > 0) state.deviceLR1110 = payload
    },
    filterDevices: (state, { payload }) => {
      state.devices = payload;
    },
    hideDevices: (state, { payload }) => {
      state.hiddenDevices.push(payload.id);
    },
    showDevices: (state, { payload }) => {
      state.hiddenDevices = payload;
    },
    setSliderValues: (state, { payload }) => {
      state.sliderValues = payload;
    },
    setShowLatest: (state, { payload }) => {
      state.showLatest = payload;
    },
    hideLines: (state, { payload }) => {
      state.hiddenLines.push(payload.id);
    },
    showLines: (state, { payload }) => {
      state.hiddenLines = state.hiddenLines.filter(item => item !== payload.id);
    },
    hideDevicesGNSS: (state, { payload }) => {
      state.hiddenDevicesGNSS.push(payload.id);
    },
    showDevicesGNSS: (state, { payload }) => {
      state.hiddenDevicesGNSS = state.hiddenDevicesGNSS.filter(item => item !== payload.id);
    },
    hideDevicesWIFI: (state, { payload }) => {
      state.hiddenDevicesWIFI.push(payload.id);
    },
    showDevicesWIFI: (state, { payload }) => {
      state.hiddenDevicesWIFI = state.hiddenDevicesWIFI.filter(item => item !== payload.id);
    },
    hideCalendar: (state, { payload }) => {
      state.showCalendar = false;
    },
    showCalendar: (state, { payload }) => {
      state.showCalendar = true;
    },
    setDeviceSearch: (state, { payload }) => {
      state.deviceSearch = payload;
    },
    setCalendarDate: (state, { payload }) => {
      state.calendarDate = payload;
    },
    setFirstDeviceDate: (state, { payload }) => {
      state.firstDeviceDate = payload;
    },
    clearData: (state) => {
      state.hiddenDevices = [];
      state.hiddenLines = [];
      state.hiddenDevicesGNSS = [];
      state.hiddenDevicesWIFI = [];
      state.sliderValues = [-30, 0];
      state.showLatest = false;
      state.devices = [];
      state.devicesBackup = [];
      state.deviceSearch = "";
      state.deviceLR1110 = [];
      state.devicesRealTime = [];
      state.showCalendar = false;
      state.calendarDate = (new Date()).toISOString().split('T')[0];
      state.firstDeviceDate = (new Date()).toISOString().split('T')[0];
    },
  },
});

export const {
  setDevicesRealTimeProcessed: setDevicesRealTimeProcessedAction,
  filterDeviceRealTime: filterDeviceRealTimeAction,
  setDevicesRealTime: setDevicesRealTimeAction,
  setDevColorMap: setDevColorMapAction,
  appendDeviceRealTime: appendDeviceRealTimeAction,
  getData: getDataAction,
  getLR1110: getLR1110Action,
  filterDevices: filterDevicesAction,
  hideDevices: hideDevicesAction,
  showDevices: showDevicesAction,
  hideLines: hideLinesAction,
  showLines: showLinesAction,
  hideDevicesGNSS: hideDevicesGNSSAction,
  showDevicesGNSS: showDevicesGNSSAction,
  hideDevicesWIFI: hideDevicesWIFIAction,
  showDevicesWIFI: showDevicesWIFIAction,
  setSliderValues: setSliderValuesAction,
  setShowLatest: setShowLatestAction,
  showCalendar: showCalendarAction,
  hideCalendar: hideCalendarAction,
  clearData: clearTrackingDataAction,
  setDeviceSearch: setDeviceSearchAction,
  setCalendarDate: setCalendarDateAction,
  setFirstDeviceDate: setFirstDeviceDateAction,
} = trackingSlice.actions;

export default trackingSlice.reducer;
