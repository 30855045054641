import React from "react";

import { locatorImages } from "components/img-content/img-content";
import  "../title/title.css";

const TitleDocument = () =>{
    return(
        <div style={{height:"35%"}}>
                <div className="content-title">
                    <div className="title-documentation">
                        <img src={locatorImages.menuDocument[1].url} alt="Documentation" />
                    </div>
                    <div className="documentation-text">
                        <img src={locatorImages.menuDocument[2].url} alt="iconTitleDoc"  style={{  marginBottom: "-2%" , marginRight:"10px"}}></img>
                            Documentation
                    </div>
                </div>

        </div>
    )
}

export default TitleDocument;