import React from "react";
import { locatorImages } from "components/img-content/img-content";
import "./footer.css";

const Footer = () => {
  return (
    <div style={{ maxWidth: "100vw", overflow: "hidden", marginTop: "6%" }}>
      <div className="footer_landing_container">
        <div className="footer-bottom_landing">
          <img className="img_footer_landing" src={locatorImages.footerImg[0].url} alt={"footer"} />
          <div className="footer_links">
          <span className="footer-links_landing">
            <a href="https://www.loracloud.com/legal" rel="noopener noreferrer"  target="_blank">LEGAL</a>
          </span>
          <span className="footer-links_landing">
            <a href="https://www.loracloud.com/legal?open-section=privacy-policy" rel="noopener noreferrer"  target="_blank">PRIVACY POLICY</a>
          </span>
          <span className="footer-links_landing">
            <a href="/#">©2022 ALL RIGHTS RESERVED</a>
          </span>

          <span className="blue_links_landing">
            <a
              href="https://lora-developers.semtech.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              LoRa DEVELOPERS
            </a>
          </span>
          <span className="blue_links_landing">
            <a
              href="https://www.loracloud.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              LoRa Cloud™
            </a>
          </span>
          <span className="blue_links_landing">
            <a
              href="https://lora-alliance.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              LoRa Alliance®
            </a>
          </span>
          <span className="blue_links_landing">
            <a
              href="https://www.semtech.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              SEMTECH
            </a>
          </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
