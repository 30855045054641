import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const SiteSubLinkButton = withStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    color: "#00AEEF",
    textTransform: "none !important",
    display: "block",
    borderRadius: "0",

    textAlign: "left",
    fontSize: "1.3125rem",
    fontFamily: '"Fira Sans"',
    fontWeight: "normal",
    whiteSpace: "nowrap",
    padding: "10px 1rem",
    "&:hover": {
      color: "#333",
      backgroundColor: "#EBEBEB !important",
      textDecoration: "none",
    },
    '&[data-active="true"]': {
      color: "#333",
    },
  },
}))(Button);

export default SiteSubLinkButton;
