import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@material-ui/icons/Check";
import CachedIcon from "@material-ui/icons/Cached";
import mobile from "is-mobile";
import { makeStyles } from "@material-ui/styles";

const CompleteRegistration = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [isUserValidated, setisUserValidated] = useState(false);
  const isUserLogged = !isLoading && isAuthenticated;
  const isMobile = mobile();
  const classes = useStyles();

  useEffect(() => {
    try {
      setisUserValidated(user.email_verified);
    } catch {
      setisUserValidated(false);
    }
  }, [user]);

  return (
    isUserLogged &&
    !isUserValidated && (
      <div>
        <div className={classes.divOpacity}></div>
        <div className={classes.bannerEmail}>
          <div
            className={
              isMobile ? classes.BannerEmailIconMobile : classes.BannerEmailIcon
            }
          >
            <CheckIcon style={{ fontSize: "35px" }} />
          </div>
          <div>
            <h3 className={classes.BannerEmailTitle}>COMPLETE REGISTRATION</h3>
            <div className={classes.BannerEmailText}>
              Confirm your email address by clicking the link sent to your
              inbox. You may need to check your spam folder.
              <br />
              Please refresh this page when you have finished. If you have not
              received an email, please contact us at
              <span>
                {" "}
                <a
                  className={classes.BannerEmailTextBlue}
                  href="mailto:support@loracloud.com"
                >
                  support@loracloud.com
                </a>
              </span>
            </div>
          </div>
          <div className={classes.BannerDivButton}>
            <button
              onClick={() => {
                window.location.reload();
              }}
              className={classes.BannerEmailButton}
            >
              REFRESH PAGE
              <CachedIcon />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CompleteRegistration;

const useStyles = makeStyles((theme) => ({
  divOpacity: {
    position: "absolute",
    height: "100vh",
    width: "100%",
    background: "#E9F4FC",
    opacity: ".9",
    zIndex: "3",
    top: "0",
  },
  bannerEmail: {
    position: "absolute",
    zIndex: "10",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#d5d5d5 0px 0px 20px 8px",
    height: "auto",
    top: "30%",
    left: "15%",
    width: "70%",
  },
  BannerEmailIcon: {
    marginTop: "-26px",
    color: "#fff",
    position: "absolute",
    left: "47.2%",
    background: "#00AEEF",
    borderRadius: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "51px",
    width: "51px",
    top: "0px",
  },
  BannerEmailIconMobile: {
    marginTop: "-30px",
    color: "#fff",
    position: "absolute",
    left: "43.2%",
    background: "#00AEEF",
    borderRadius: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "51px",
    width: "51px",
    top: "0px",
  },
  BannerEmailTitle: {
    paddingTop: "1.9rem",
    textAlign: "center",
    fontFamily: "Fira sans",
    fontWeight: "500",
    color: "#00AEEF",
    fontSize: "2rem",
    marginTop: "0px",
    marginBottom: "0px",
  },
  BannerEmailText: {
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingTop: "2%",
    fontFamily: "Fira sans",
    textAlign: "center",
    fontSize: "1.3125rem",
    color: "#333",
  },
  BannerEmailTextBlue: {
    fontFamily: "Fira sans",
    textDecoration: "none",
    color: "#00AEEF",
    fontWeight: "500",
    fontSize: "1.3125rem",
  },
  BannerDivButton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
  },
  BannerEmailButton: {
    fontFamily: "Fira sans",
    display: "flex",
    fontWeight: "lighter",
    color: "#fff",
    padding: "1%",
    marginBottom: "2%",
    marginTop: "2%",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    border: "solid 1px #00AEEF",
    background: "#00AEEF",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "1.7rem",
    transition: ".4s background-color",
    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "#fff",
    },
    "&:active": {
      color: "#00AEEF",
      backgroundColor: "#fff",
    },
  },
}));
