import React, {  useState , useEffect } from "react";
import { useParams } from "react-router-dom";
import './whatsNewMobile.css';
import API from "services/axiosCall";
import ReactMarkdown from "react-markdown";
import {getDateDayMonthYear} from "utils/helperFunctions";
import { locatorImages } from "components/img-content/img-content";
import CompleteRegistration from "components/registration/CompleteRegistration";

const WhatsNewMobile = () => {
  const [isLoadingNotices,setLoading] = useState(true);
  const [markdownShown, setmarkdownShown] = useState("");
  const { handle } = useParams();


  useEffect(() => {
   getNews();
   setLoading(false);
   // eslint-disable-next-line
  }, [handle]);


  const getNews = async  () => {
      let allNews  = [];
      await API.getNews().then((response) => {
        let responseSplitted= [];

          response.map(async  (notice) => {

            notice = notice.substring(notice.search("/") + 1);
            await API.getNew(notice).then((response2) => {
              responseSplitted=response2[0].split("\n");
              allNews.push(responseSplitted);
            })
            orderNews(allNews);
          })
      })

}


const orderNews =  (allNews) => {
  allNews.sort( function(a, b) {
    return  ( getDateDayMonthYear(b[1].substring(4,14))   - getDateDayMonthYear(a[1].substring(4,14)))
  })
  let newsConcated="";
  let limit=0;
  // eslint-disable-next-line array-callback-return
  const requests = allNews.map((notice) => {
    if(limit>=3)
    {return "";}
    limit++;
    newsConcated += notice[0] + "\n";
  })
  return Promise.all(requests).then(() => {
  setmarkdownShown(newsConcated);
  });

}


useEffect(() => {
  getNews();
  setLoading(false)
  // eslint-disable-next-line
}, [handle]);

  return (
    <div>

        <section
          style={{ maxWidth: "100vw", overflow: "hidden" }}
        >
          <img
            alt={"home"}
            src={locatorImages.homePageImg[12].url}
            style={{
              objectPosition: "right bottom",
              objectFit: "cover",
              width: "100%",
              height: "45vh",
              marginTop: '10vh'
            }}
          />

          <div className="text-welcome-semtech-mobile">
            <span className="message-welcome-mobile">
                <p>
                        <b className="titleMobileWhatsNew">Welcome to LoRa Cloud™ - Locator </b><br/>
                        <span className="subtitleMobileWhatsNewMobile">
                            The easiest way to experience first hand the accuracy and power
                            consumption of LoRa Edge™ for asset tracking in any IoT vertical!
                        </span>
                </p>
            </span>
          </div>

          {isLoadingNotices ? (
            <div></div>
          ) : (
            <div className="whatsNewBox-mobile">
                <h3 >
                  What’s new?
                </h3>

                  <ReactMarkdown className="noticeMobileLP">{markdownShown}</ReactMarkdown>


                  <center>
                      <a className="buttonCarousselNewsLP" href="/whatsNewMobile">SEE MORE</a>
                  </center>
            </div>
          )}

            <CompleteRegistration />

        </section>

    </div>
  );
};

export default WhatsNewMobile;
