import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

export default function BreadcrumbsBar({ path = [] , id }) {
  const css = useStyle();

  return (
    <div className={css.rootContainer}>
      <Breadcrumbs aria-label="breadcrumb" separator="❯❯">
        {path.map((link, index) =>
          Array.isArray(link) ? (
            <Link id={id} key={index} href={link[1]}>
              {link[0]}
            </Link>
          ) : (
            <Typography key={index}>{link}</Typography>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}

const useStyle = makeStyles(() => ({
  rootContainer: {
    position: "relative",
    display: "flex",

    alignItems: "flex-start",
    fontSize: "2.035vh",
    flex: 1,
    marginTop: "1vh",
    marginLeft: "10.42%",
    color: "#00AEEF",
    "& ol *": {
      fontSize: "2.035vh",
    },
    "& li *": {
      textDecoration: "none !important",
      textTransform: "UPPERCASE",
      color: "#00AEEF",
      fontFamily: '"Fira Sans"',
      fontWeight: "normal",
      fontSize: "2.035vh",
    },
    "& li:not(:last-child) *": {
      color: "#333",
      fontSize: "2.035vh",
    },
    "& li:first-child *": {
      color: "#00AEEF",
      fontSize: "2.035vh",
    },
    "& li:first-child *:hover": {
      color: "#00AEEF",
      fontSize: "2.035vh",
    },
    "& li:last-child *": {
      color: "#333",
      fontWeight: "500",
      fontSize: "2.035vh",
    },
  },
}));
