import React  from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import AuthButton from './AuthButton';
import { setUserAction } from "../../../store/reducers/userReducers";
import {LandingPageAuthLinks} from "components/landing_page/common/textsLandingPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';


export default function AuthLinks () {

  
  const { isAuthenticated,  logout } = useAuth0();
  const css = useStyle();
  const dispatch = useDispatch();
  const {loginWithRedirect} = useAuth0();
  


 


  const signOut = async () => {
    try {
      // await Auth.signOut();
      logout({returnTo: window.location.origin})
      dispatch(setUserAction(""));
      // window.location.replace(window.location.origin);
    } catch (error) {
      console.error("error signing out", error);
    }
  };

  const redirect = async () => {

    if (isAuthenticated){
    
    window.location.replace("/");
    }else{

    window.location.replace("/landing");
    }
    
  };

  return (
   <div>
    { !isAuthenticated ?
      <div className={css.authLinks}> <AuthButton id='loginButton' variant="contained" color="primary" 
       onClick={() => loginWithRedirect({ appState: { target: '/device-tracking' }})}disableElevation>
       {LandingPageAuthLinks.LoginButton}<FontAwesomeIcon  className={css.icon} icon= {faAngleDoubleRight} />
      </AuthButton>
      <AuthButton id='signUpButton' variant="contained" color="primary"   
      onClick={() => {loginWithRedirect({ appState: { target: '/device-tracking' }}); window.lintrk('track', { conversion_id: 8308554 }) }} disableElevation>
      {LandingPageAuthLinks.SignUpButton}<FontAwesomeIcon  className={css.icon} icon= {faAngleDoubleRight} />  
      </AuthButton></div>
  :<div className={css.authLinks} >
    <AuthButton id='backTrackingButton' variant="contained" color="primary"  onClick={() => redirect()} disableElevation> BACK TO TRACKING <FontAwesomeIcon  className={css.icon} icon= {faAngleDoubleRight} />  </AuthButton>
    
      <AuthButton id='signOutButton' variant="contained" color="primary"  onClick={() => signOut()} disableElevation> SIGN OUT <FontAwesomeIcon  className={css.icon} icon= {faAngleDoubleRight} /> </AuthButton></div>}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  authLinks: {
    width: 'max-content',
    borderLeft: '1px solid #707070',
    marginLeft: '0.5vw',
    fontSize : '150% !important',
    padding: '0 0.5vw', 
    [theme.breakpoints.only('xs')]: { borderLeft: 'none',},
    [theme.breakpoints.only('sm')]: {borderLeft: 'none',},
  },
  icon:{
    marginLeft:"5px"
  }

  

  
}));
