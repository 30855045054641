import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

import FooterMobile from "./../footerMobile";
import HeaderMobile from "./..//headerMobile";
import BreadcrumbsBar from "../../landing_page/common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "Truvami"];

export default function TruvamiMobile() {
  const css = useStyle();
  useEffect(() => {
    document.title = "Truvami | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/references/truvami");
    }
  }, [width, history]);

  const donwloadFileMobile = (fileURL) => {
    window.location.href = fileURL;
  };

  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <div className="page">
        <h3 className="mainHeaderPages"> What our partners say about LoRa Cloud Locator</h3>
        <div className={css.landingPage}>
          <div className={css.introductionNetworks}>
            <div className={css.titleSection} id="title">
              <h3 style={{ color: "#333" }}>
          <center> <img
                style={{width:'70vw'}}
                src={locatorImages.referencePages[0].url}
                alt="TruvamiLogo"
              /></center>
              </h3>
            </div>
            <div className={css.contentNetwork}>
                <p style={{fontSize:"1.5rem", textAlign: 'justify'}}>
                Truvami is reshaping the future of security through innovative tracking solutions. We combine Swiss precision with advanced technology to offer tiny, 
                yet powerful devices that enable continuous geolocation, both indoor and outdoor. Our mission is to empower our customers with the insights they need to 
                achieve ultimate peace of mind for their assets and maximize their operational efficiency.
                <br/>
                <br/>
                We have chosen to integrate our truvami tag XL device with Semtech’s LoRa Cloud Locator, 
                to allow our customers to easily switch between any public LoRaWAN® network operator supported by LoRa Cloud Locator or any private LoRaWAN network built on AWS IoT Core.
                This integration showcases the performance of our tag XL device, 
                in addition to leveraging LoRa® benefits such as low power consumption and the LoRa Edge indoor and outdoor geolocation capabilities.
                  Thanks to the integration of AWS IoT Core Services into Semtech’s LoRa Cloud Locator, the device is provisioned on AWS IoT Core, 
                  utilizing services like AWS DynamoDB and AWS AppSync to extract and store all LoRaWAN messages automatically. 
                  This empowers users to track their device’s historical and real-time location data and to view their location on a map with all data captured on a dashboard.
                  <br/><br/>
                Combined with our application platform,
                our customers have a simple and easy way to test our trackers and their features,
                  reducing the time and effort required for provisioning and onboarding the devices. 
                  They can see the geolocation of their devices on the map in just a couple of minutes, instead of hours. 
                  LoRa Cloud Locator makes it easy for our customers to adopt our devices and scale their IoT asset tracking applications.
                </p>


                
<center>
 <img
 style={{width:"70vw"}}
                src={locatorImages.referencePages[1].url}
                alt="TruvamiMap"
 /></center>


<p style={{fontSize:"1.2rem"}}>
<br/>
<b>About truvami</b><br/><br/>
truvami is an IoT startup specialized in flexible end-to-end tracking solutions, based in Zürich, Switzerland. The company’s mission is to support enterprises in safeguarding their workforce and valuable assets through cutting-edge tracking technologies at smallest size. truvami offers a portfolio of different trackers that are designed to serve different use-cases in vertical markets like transport, logistics, industrial, construction, fleet- and cattle-tracking. The innovative multi-protocol approach combined with a dedicated geolocation engine allows seamless in- and outdoor tracking. Together with truvami’s cloud software platform, customers can easily integrate the solution into their existing IT landscape.
<br/><br/>
<b>About Semtech</b><br/><br/>
Semtech Corporation is a high-performance semiconductor, IoT systems, and cloud connectivity service provider dedicated to delivering high-quality technology solutions that enable a smarter, more connected, and sustainable planet. Our global teams are committed to empowering solution architects and application developers to develop breakthrough products for the infrastructure, industrial, and consumer markets.  To ensure scalability for our services and customer offering, Semtech is now part of the APN (AWS Partner Network), and our AirVantage® Smart Connectivity product is available on AWS Marketplace.
</p>
            </div>
        </div>

</div>
</div>       <FooterMobile />
</div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important
  },
  introductionNetworks: {
    borderBottom: "1px solid #00AEEF",
    paddingBottom: "7%",
  },
  gatewayLandingMain: {
    textAlign: "center",
  },
  gatewayTitleLanding: {
    color: "#00AEEF",
    fontFamily: "Fira sans",
    fontWeight: "500",
    fontSize: "3.33vh",
  },

  gatewayLandingButton: {
    color: "#FFF",
    textDecoration: "none",
    backgroundColor: "#00AEEF",
    border: "solid 1px #00AEEF",

    fontWeight: "600",
    fontSize: "2.22vh",
    transition: ".4s background-color",

    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "#FFF",
    },
  },
  titleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "5vw",
    fontWeight: "450",
    width: "100%",

    textAlign: "center",
    marginBottom: "5%",
    "& h3": {
      fontWeight: "500",
    },
  },
  descriptionSection: {
    fontFamily: "Fira sans", //!important,
    fontSize: "4vw",
    color: "#333",
    "& p": {
      marginBlockStart: "0",
      marginBlockEnd: "0",
    },
    "& a": {
      color: "#00AEEF",
      fontWeight: "500",
    },
    "& span": {
      fontWeight: "500",
    },
  },

  titleNetwork: {
    color: "#00AEEF",
    fontSize: "2.80vh",
    fontWeight: "450",
    width: "62%",
    margin: "4.8% auto",
    textAlign: "center",
    marginBottom: "5%",
  },
  subtitleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "4.5vw",
    fontWeight: "450",
    textAlign: "center",
  },

  useCases: {
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  gateways: {
    "& img": {
      width: "30%",
    },
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  descriptionGateway: {
    display: "flex",
    flexDirection: "row",
  },

  networkSectionGrid: {
    "& center": {
      marginTop: "5%",
    },
    "& img": {
      width: "50vw",
    },
    width: "100%",
    marginBottom: "20%",
    marginTop: "5%",
  },
  swisscomLogo: {
    "& img": {
      width: "8vw",
    },
  },
  operator: {
    marginTop: "5%",
    "& img": {
      width: "34%",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
  coverage: {
    "& a": {
      color: "#333",
      textDecoration: "none",
      wordBreak: "break-all",
      fontWeight: "100",
    },
  },
  contactSupport: {
    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "2.222vh",
      textAlign: "center",
      transition: "opacity .15s ease-in-out",
      opacity: "100",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      marginRight: "5%",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      marginTop: "2%",
      padding: ".5% .6%",
      "&:hover": {
        color: "#fff",
        border: "1px solid #00aeef",
        transition: "opacity .15s ease-in-out",
        opacity: ".8",
      },
    },
  },
  logoDiv: {
    margin: "auto",
    "& img": {
      maxWidth: "25vw",
    },
  },
  rowBorder: {
    borderBottom: "1px solid #00AEEF",
    gridColumn: "1 / 3",
  },
}));
