import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BreadcrumbsBar from "../common/BreadcrumbsBar";
import TitleBanner from "../common/TitleBanner";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import QuestionsAccordion from "./QuestionsAccordion";
import faqDataset from "./faqDataset";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "HELP", "FAQ"];

export default () => {
  const css = useStyle();
  useEffect(() => {
    document.title = "Help - FAQ | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/help/faqMobile");
    }
  }, [width, history]);

  return (
    <div className="page">
      <Header />

      <TitleBanner title="FAQ" />
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      <div
        className={css.sectionFAQ}
        style={{ fontFamily: "'Fira sans' !important" }}
      >
        <div className={css.pageContent}>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={css.sectionSubtitle}
          >
            FAQ
          </Typography>
          <QuestionsAccordion faqList={faqDataset} />
        </div>
        <div className={css.sectionFAQButton}>
          {" "}
          <p className={css.sectionFAQtext} style={{ marginRight: "5px" }}>
            If you have more questions, please
          </p>
          <a
            className={css.visitForum}
            href="https://forum.lora-developers.semtech.com/c/LoRa-Cloud-Services/LoRa-Cloud-Locator/44"
            rel="noopener noreferrer"
            target="_blank"
          >
            visit our forum
          </a>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  pageContent: {
    fontFamily: "'Fira sans' !important",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
    marginLeft: "15%",
  },
  sectionSubtitle: {
    fontFamily: "'Fira sans' !important",
    color: "#00AEEF",
    fontWeight: "550",
    fontSize: "2.25rem",
  },
  sectionFAQtext: {
    fontSize: "1.3125rem",
    marginRight: "1%",
  },
  sectionFAQButton: {
    display: "flex",
    marginBottom: "10vh",
    color: "#333",
    marginRight: "18%",
    position: "relative",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  visitForum: {
    color: "#00AEEF",
    fontFamily: "Fira sans",
    fontSize: "1.3125rem",
    marginTop: "3px",
    marginLeft: "0",
    "&:hover": {
      color: "#333",
    },
  },
}));
