import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedUser: "",
    token: '',
    maxDevices: 0,
    maxGateways : 0 
  },
  
  reducers: {
    setUser: (state, { payload }) => {
      state.loggedUser = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setMaxDevices: (state, { payload }) => {
      state.maxDevices = payload;
    },
    setMaxGateways: (state, { payload }) => {
      state.maxGateways = payload;
    },
  },
});

export const {
  setUser: setUserAction,
  setToken: setTokenAction,
  setMaxDevices: setMaxDevicesAction,
  setMaxGateways: setMaxGatewaysAction,

} = userSlice.actions;

export default userSlice.reducer;
