import React, { useState} from "react";
import { slide as Menu } from "react-burger-menu";
import './headerMobile.css'
import CloseIcon from "img/iconCloseMobile.PNG";
import { useAuth0 } from "@auth0/auth0-react";
import { setUserAction } from "../../store/reducers/userReducers";
import { useDispatch } from "react-redux";
import { locatorImages } from "components/img-content/img-content";


export default function HeaderMobile () {
    // eslint-disable-next-line no-unused-vars  
    const [showNetowrkSublinks, setShowNetowrkSublink] = useState(false);
    const [showSupportSublinks, setShowSupportSublinks] = useState(false);
    const [MenuIsOpen, SetMenuIsOpen] = useState(false);
    const dispatch = useDispatch();
    const {loginWithRedirect} = useAuth0();
    const { isAuthenticated,  logout } = useAuth0();


    
  const signOut = async () => {
    try {
      // await Auth.signOut();
      logout({returnTo: window.location.origin})
      dispatch(setUserAction(""));
      // window.location.replace(window.location.origin);
    } catch (error) {
      console.error("error signing out", error);
    }
  };


   
    const HandleShowSupportSublinks = (SublinksName) => {
        console.log(SublinksName)

        if(SublinksName==="Network"){
            setShowNetowrkSublink(true)
            setShowSupportSublinks(false)
        }
        if(SublinksName==="Support"){
            setShowNetowrkSublink(false)
            setShowSupportSublinks(true)
        }
    }
    var isMenuOpen = function(state) {
        SetMenuIsOpen(state.isOpen)
      };
    
    return (
    <div>
      <header  className="headerLandingMobile">
            <a href="/landing"><img alt="LoraCloud™" src={locatorImages.headerImg[0].url} /><p>Locator</p></a>
        { !isAuthenticated ?(<img src={locatorImages.headerImg[1].url} alt="logInIcon" className={ MenuIsOpen ? "hiddeLogInIcon" : undefined}  onClick={() => {
          loginWithRedirect();
          window.lintrk('track', { conversion_id: 8308554 })
         }} />):
                            (<button className={ MenuIsOpen ? "hiddeLogInIcon" : "logOutButton" } onClick={() => signOut()}><img src={locatorImages.headerImg[2].url} alt="logoutmobile" /></button>)
        }
            <Menu  onStateChange={ isMenuOpen } width={ '100%' }   customCrossIcon={ <img alt="closeIcon" src={CloseIcon} /> } >
            <div className="menuOptionsMobileLandingPage">
                <h1 > <a href="/landing">HOME</a></h1>
                <h1 > <a href="/useCasesMobile">USE CASES</a></h1>


 <h1> 
                  {/*eslint-disable-next-line*/}
                  <a className={ showSupportSublinks ? "landing_page_sublinks_active" : undefined} 
                   onClick={() =>HandleShowSupportSublinks("Support")}>REFERENCES</a></h1>
            { showSupportSublinks && 
            <>
                <h2> <a href="/references/browanMobile" >Browan</a></h2>
                <h2> <a href="/references/digitalMatterMobile">Digital Matter</a></h2>
                <h2> <a href="/references/everynetMobile">Everynet</a></h2>
                  <h2> <a href="/references/truvamiMobile">Truvami</a></h2>
               </>
            } 

                <h1> <a  href="/networksMobile">NETWORKS</a></h1>
                <h1> <a href="/trackersMobile">TRACKERS</a></h1>
                <h1> 
                  {/*eslint-disable-next-line*/}
                  <a className={ showSupportSublinks ? "landing_page_sublinks_active" : undefined}  onClick={() =>HandleShowSupportSublinks("Support")}>HELP</a></h1>
            { showSupportSublinks && 
            <>
                <h2> <a href="/GetStartedMobile" >Getting Started</a></h2>
                <h2> <a href="/help/faqMobile">FAQ</a></h2>
                <h2> <a href="/useCasesMobile">Forum</a></h2>
                <h2> <a href="https://semtech.force.com/ldp/ldp_support">Support Ticket</a></h2>
               </>
            } 





                <h1><a href="/whatsNewMobile">WHAT´S NEW</a></h1>
              {isAuthenticated ?( <h1><a href="/home">BACK TO TRACKING</a></h1>):(<div></div>)}  
            
            </div>


            <div>
  
</div>

          </Menu>
      
    </header>
  </div>

  );
}

  