import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

import FooterMobile from "./../footerMobile";
import HeaderMobile from "./..//headerMobile";
import BreadcrumbsBar from "../../landing_page/common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "Everynet"];

export default function EverynetMobile() {
  const css = useStyle();
  useEffect(() => {
    document.title = "Everynet | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/references/everynet");
    }
  }, [width, history]);


  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <div className="page">
        <h3 className="mainHeaderPages"> What our partners say about LoRa Cloud Locator</h3>
        <div className={css.landingPage}>
          <div className={css.introductionNetworks}>
            <div className={css.titleSection} id="title">
              <h3 style={{ color: "#333" }}>
          <center> <img
                style={{width:'70vw'}}
               src={locatorImages.networksPageImg[0].url}
                alt="EverynetLogo"
              /></center>
              </h3>
            </div>
            <div className={css.contentNetwork}>
                <p style={{fontSize:"1.5rem", textAlign: 'justify'}}>
         
Everynet is a leading LPWA pioneer and network operator, providing innovative solutions that help companies around the world to solve their biggest IoT challenges and scale quickly. Everynet has a portfolio of intellectual property, products and solutions which include network infrastructure, a flexible management platform, ready-to-deploy proof-of-concept kits, reference designs and a large third-party device and software partner eco-system. We believe that IoT is "bits, billion and cents" and our vision is to make it simple to drive massive subscriber growth on LoRaWAN® IoT networks.
<br/><br/>
We were looking for a tool that was able to demonstrate our network capacity, coverage and scalability to end users, and the ability  to allow different LoRa® trackers to join our network. Previously, customers had to create their network environment to test and use their devices, which required a lot of time, effort, and knowledge. With our partnership with Semtech, we were able to create a system using AWS Services and LoRa Cloud services, to demonstrate the power of trackers enabled with Semtech´s LoRa Edge™ and LoRaWAN to show the device´s location on a map using Everynet’s LoRaWAN network. The site is hosted in S3 with a Cloudfront distribution. This communicates with the backend via AppSync and/or API Gateway. AppSync communicates to Dynamo to gather the user’s data, and the API Gateway calls the lambdas to get all the information asynchronously. On the other side, Devices are registered on AWS IoT Core to receive LoRa messages. Once the messages are received, lambdas are called to communicate with LoRa Cloud Services and resolve the location of the device. Lastly, the position is saved in the Dynamo database.  In doing this, our customers can experience how easy it is to scale an asset tracking application using our LoRaWAN network.
<br/><br/>
Thanks to LoRa Cloud Locator and AWS Services, as of July 2022, our customers can use their LoRa trackers in a matter of minutes by leveraging our network and we can track the time it takes for our users to see their location on the map, the regions where network demand is higher, as well as overall network performance.
      </p>


                
<center>
 <img
 style={{width:"70vw"}}
                src={locatorImages.referencePages[2].url}
                alt="EverynetMap"
 /></center>


   <p style={{fontSize:"1.2rem"}}>
    <b>
About Semtech</b><br/><br/>
Semtech Corporation is a high-performance semiconductor, IoT systems, and cloud connectivity service provider dedicated to delivering high-quality technology solutions that enable a smarter, more connected, and sustainable planet. Our global teams are committed to empowering solution architects and application developers to develop breakthrough products for the infrastructure, industrial, and consumer markets.  To ensure scalability for our services and customer offering, Semtech is now part of the APN (AWS Partner Network), and our AirVantage® Smart Connectivity product is available on AWS Marketplace.

   </p>
            </div>
        </div>

</div>
</div>  <FooterMobile />
</div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important
  },
  introductionNetworks: {
    borderBottom: "1px solid #00AEEF",
    paddingBottom: "7%",
  },
  gatewayLandingMain: {
    textAlign: "center",
  },
  gatewayTitleLanding: {
    color: "#00AEEF",
    fontFamily: "Fira sans",
    fontWeight: "500",
    fontSize: "3.33vh",
  },

  gatewayLandingButton: {
    color: "#FFF",
    textDecoration: "none",
    backgroundColor: "#00AEEF",
    border: "solid 1px #00AEEF",

    fontWeight: "600",
    fontSize: "2.22vh",
    transition: ".4s background-color",

    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "#FFF",
    },
  },
  titleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "5vw",
    fontWeight: "450",
    width: "100%",

    textAlign: "center",
    marginBottom: "5%",
    "& h3": {
      fontWeight: "500",
    },
  },
  descriptionSection: {
    fontFamily: "Fira sans", //!important,
    fontSize: "4vw",
    color: "#333",
    "& p": {
      marginBlockStart: "0",
      marginBlockEnd: "0",
    },
    "& a": {
      color: "#00AEEF",
      fontWeight: "500",
    },
    "& span": {
      fontWeight: "500",
    },
  },

  titleNetwork: {
    color: "#00AEEF",
    fontSize: "2.80vh",
    fontWeight: "450",
    width: "62%",
    margin: "4.8% auto",
    textAlign: "center",
    marginBottom: "5%",
  },
  subtitleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "4.5vw",
    fontWeight: "450",
    textAlign: "center",
  },

  useCases: {
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  gateways: {
    "& img": {
      width: "30%",
    },
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  descriptionGateway: {
    display: "flex",
    flexDirection: "row",
  },

  networkSectionGrid: {
    "& center": {
      marginTop: "5%",
    },
    "& img": {
      width: "50vw",
    },
    width: "100%",
    marginBottom: "20%",
    marginTop: "5%",
  },
  swisscomLogo: {
    "& img": {
      width: "8vw",
    },
  },
  operator: {
    marginTop: "5%",
    "& img": {
      width: "34%",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
  coverage: {
    "& a": {
      color: "#333",
      textDecoration: "none",
      wordBreak: "break-all",
      fontWeight: "100",
    },
  },
  contactSupport: {
    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "2.222vh",
      textAlign: "center",
      transition: "opacity .15s ease-in-out",
      opacity: "100",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      marginRight: "5%",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      marginTop: "2%",
      padding: ".5% .6%",
      "&:hover": {
        color: "#fff",
        border: "1px solid #00aeef",
        transition: "opacity .15s ease-in-out",
        opacity: ".8",
      },
    },
  },
  logoDiv: {
    margin: "auto",
    "& img": {
      maxWidth: "25vw",
    },
  },
  rowBorder: {
    borderBottom: "1px solid #00AEEF",
    gridColumn: "1 / 3",
  },
}));
