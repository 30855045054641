import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
import ReactGA from 'react-ga';
import { PersistGate } from 'redux-persist/integration/react'

import "./index.css";
import "./index_fonts.css";
import AppWithAuth from "./AppWithAuth";
import * as serviceWorker from "./serviceWorker";
import awsConfig from "./awsConfig";
import { store, persistedStore } from "./store/store";
import { Auth0Provider } from "@auth0/auth0-react";
import {
    AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN,
    AUTH0_SCOPE,
    GOOGLE_ANALYTICS_TAG
} from "./environment";

import "@fontsource/fira-sans";

Amplify.configure(awsConfig);
ReactGA.initialize(GOOGLE_ANALYTICS_TAG, {gaAddress: 'https://br4dk9unqa.execute-api.eu-west-1.amazonaws.com/Prod/analytics'});
ReactGA.pageview(window.location.pathname);

ReactDOM.render(
 <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
     <BrowserRouter>
         <Auth0Provider
             domain={AUTH0_DOMAIN}
             clientId={AUTH0_CLIENT_ID}
             redirectUri={window.location.origin}
             audience={AUTH0_AUDIENCE}
             scope={AUTH0_SCOPE}
             cacheLocation="localstorage"
         >
             <AppWithAuth />
         </Auth0Provider>
     </BrowserRouter>
     </PersistGate>
 </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
