import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "services/axiosCall";
import Header from "components/landing_page/header/Header";
import Footer from "components/landing_page/footer/Footer";
import TitleDocument from "../title/title";
import BreadcrumbsBar from "../../common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";

import "../principal/principal.css";

const Principal = (user) => {
  const { handle } = useParams();
  const [document, setDoc] = useState("");

  useEffect(() => {
    getDoc();
  });

  const getDoc = async () => {
    try {
      const response = await API.getDocuments();
      await API.getDocument(response[handle]).then((response2) => {
        setDoc(response2[0]);
      });
    } catch (error) {}
  };

  const breadcrumbs_path = [
    ["Documentation", "/docs"],
    ["Technical documents"],
    [document["title"], `/docs/${document["title"]}`],
  ];

  return (
    <section style={{ minHeight: "100vh" }}>
      <div>
        <div>
          <Header />
          <div id="content" style={{ marginTop: "16vh" }}>
            <TitleDocument />

            <img alt={"pointerDocs"} src={locatorImages.menuDocument[3].url} className="pointerIcon" />
            <div className="maxWidth">
              <BreadcrumbsBar path={breadcrumbs_path} />

              <div className="DocTittle">
                <h1>{document["title"]}</h1>
              </div>
            </div>

            <div className="DocumentRow">
              <div className="squareBlue">
                <div className="squareBlueIcon">
                  {" "}
                  <img
                    alt={"arrow"}
                    src={locatorImages.menuDocument[4].url}
                    style={{ width: "15vh", marginRight: "1vw" }}
                  />
                </div>
                <div className="squareBlueText"> {document["section2"]}</div>
              </div>
              <div className="squareBorderBlue"> {document["section2"]}</div>
            </div>

            <div className="DocumentRow">
              <div className="DocumentSection2">
                <div className="leftRow">
                  {" "}
                  <div></div>
                </div>
                <div className="RightRow">
                  <div className="tittleSection2">{document["title"]}</div>
                  <div className="textSection2"> {document["section2"]}</div>
                </div>
              </div>
            </div>

            <div className="DocumentRow  DocumentSection3">
              <div className="section3Text">
                {" "}
                You can also have all the information whenever you need it.
              </div>

              <div className="section3Icon">
                <a
                  href="publicURLFromS3"
                  download
                  style={{ textDecoration: "none", textAlign: "center" }}
                >
                  <img
                    alt={"arrow"}
                    src={locatorImages.menuDocument[5].url}
                    style={{ width: "15vh" }}
                  />
                  <p className="downloadText">Download</p>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default Principal;
