import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const SiteLinkButton = withStyles((theme) => ({
  root: {
    borderRadius: "0",
    color: "#00AEEF",
    background: "none",
    fontSize: "1.3125rem",
    fontFamily: '"Fira Sans"',
    fontWeight: "normal",
    padding: "6px 8px !important",
    whiteSpace: "nowrap",
    transition: ".5s font-size !important",

    "&:hover": {
      color: "#333",
      fontSize: "1.3125rem",
      textDecorationLine: "underline",
      textDecoration: "solid #00AEEF 2px",
      textUnderlineOffset: "4px",
      background: "white",
      padding: "6px 8px",
     
    },
  },
}))(Button);

export default SiteLinkButton;
