import React from 'react';
import './footerMobile.css'
import { locatorImages } from 'components/img-content/img-content';

const FooterMobile = () => {
return (
<div style={{maxWidth:'100vw',overflow:'hidden', marginTop: '6%'}} >
  <div style={{textAlign:'center', marginBottom:'2%'}}>
    <img className="img_footer_landing_mobile" src={locatorImages.footerImg[0].url} alt={'footer'}/>
  </div>
  <div className='footer_landing_container_mobile'>
      <span className="blue_links_landing_mobile"><a href='https://lora-developers.semtech.com/' rel="noopener noreferrer" target="_blank">LoRa DEVELOPERS</a></span> 
      <span className="blue_links_landing_mobile"><a href='https://www.loracloud.com/'  rel="noopener noreferrer"target="_blank">LoRa Cloud™</a></span> 
      <span className="blue_links_landing_mobile"><a href='https://lora-alliance.org/' rel="noopener noreferrer" target="_blank">LoRa Alliance®</a></span> 
      <span className="blue_links_landing_mobile"><a href='https://www.semtech.com/' rel="noopener noreferrer" target="_blank">SEMTECH</a></span> 
  </div>
  <div className='footer_landing_container_mobile'>
    <span className="footer-links_landing_mobile "><a href="https://www.loracloud.com/legal" rel="noopener noreferrer" target="_blank">LEGAL</a></span>
    <span className="footer-links_landing_mobile_last " ><a href="https://www.loracloud.com/legal?open-section=privacy-policy" rel="noopener noreferrer" target="_blank">PRIVACY POLICY</a></span>
    <span className="footer_date"><a href="/#">©2022 ALL RIGHTS RESERVED</a></span>
  </div>   
  
</div>
  );
};

export default FooterMobile;
