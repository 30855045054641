import React, { useEffect } from "react";
import FooterMobile from "./footerMobile";
import HeaderMobile from "./headerMobile";
import BreadcrumbsBar from "../landing_page/common/BreadcrumbsBar";
import "./use-cases-mobile.css";
import { locatorImages } from "components/img-content/img-content";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "components/useWindowDimensions";

const UseCasesMobile = () => {
  const { width } = useWindowDimensions();

  const history = useHistory();

  useEffect(() => {
    document.title = "Use Cases | LoRa Cloud™ Locator | Semtech";
  }, []);

  useEffect(() => {
    if (width > 900) {
      history.push("/useCases");
    }
  }, [width, history]);

  const breadcrumbs_path = [
    ["HOME", "/landing"],
    ["USE CASES", "/useCasesMobile"],
  ];

  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <h3 className="mainHeaderPages">Use Cases</h3>

      <div className="useCaseMainLP">
        <center>
          <div className="TitleLDUsecasesMoblie">
            <h3 style={{ color: "#333" }}>Explore a Variety of Use Cases </h3>
          </div>
        </center>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[0].url} alt="usecases1" id="Healthcare"></img>
        </div>

        <h3>SUPPLY CHAIN</h3>
        <h4>Pallet and Forklift Tracking</h4>
        <p className="TextUsecasesLPLeft">
          Significant time is lost, and cost is incurred locating and retrieving
          pallets, forklifts and other equipment throughout large warehouse
          areas at industrial sites, ports and airports. Assets equipped with
          LoRa Edge-enabled devices can always be located, whether in transit or
          stationed within a set wide area.
        </p>

        <h4>Cold Chain Monitoring</h4>
        <p className="TextUsecasesLPLeft">
          It is imperative for industries such as food and pharmaceuticals to
          provide continuously refrigerated production, distribution and storage
          of products. Companies can now have visibility into
          temperature-controlled supply chain to identify sections which may
          have already or maybe about to malfunction. LoRa Edge provides full
          traceability whether indoors, outdoors or in transit with precise
          location complementing temperature data.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[1].url} alt="usecases2" id="HomeConsumer"></img>
        </div>

        <h3>AGRICULTURE</h3>
        <h4>Cattle Tracking </h4>
        <p className="TextUsecasesLPLeft">
          Tracking the health and location of livestock is essential to any
          cattle rearing business. Farmers can deploy a network of animal
          trackers to cost-effectively, accurately and remotely monitor their
          herd that are constantly on the move indoors and outdoors to ensure
          they are healthy and remain in the safe areas.
        </p>

        <h4>Tracking Equipment & Machinery </h4>
        <p className="TextUsecasesLPLeft">
          Livestock farms and croplands can take up incredibly large spaces of
          up to 2000 acres. For time and efficiency purposes, farmers greatly
          benefit from smart tracking systems to monitor the whereabouts of
          their equipment and machinery.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[2].url} alt="usecases3" id="Retail"></img>
        </div>
        <h3>CITIES</h3>
        <h4>Micro Mobility</h4>
        <p className="TextUsecasesLPLeft">
          The need for and access to eco-friendly micro vehicle transportation
          such as bikes and scooters increases with the rising costs of
          gasoline, climate initiatives and innovations in batteries and
          electric motors. Service providers seek ways to locate bikes and
          scooters even after the battery has depleted for service and battery
          charging. The ability to track and trigger location-based alerts to
          mitigate theft is highly desirable by operators and owners alike.
        </p>

        <h4>Trash Can Sensors </h4>
        <p className="TextUsecasesLPLeft">
          City services constantly seek ways to improve efficiency and waste
          management is no exception. With a citywide network of sensors
          attached to each bin, employees can monitor in real time which are
          full or empty, enabling them to send collection teams out when and
          wherever necessary.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[3].url} alt="usecases4" id="Industrial"></img>
        </div>
        <h3>RETAIL</h3>
        <h4>Shopping Cart Tracking</h4>
        <p className="TextUsecasesLPLeft">
          Supermarkets lose shopping carts from their premises every year.
          Business owners can deploy a network of devices enabling the tracking
          of shopping carts indoors, underground and outside. Create geofences
          and instant notifications when a cart leaves the area and quickly
          locate every cart wherever they are. By deploying a private gateway
          around retail outlet premises, retail owners can benefit from avoiding
          monthly connectivity fees.
        </p>

        <h4>Inventory/Stock Management </h4>
        <p className="TextUsecasesLPLeft">
          Managing inventory in retail or warehouse settings, whether to prevent
          loss, increase product availability or improve overall customer
          experience, is crucial to any business. Asset trackers provide
          complete visibility into location of items indoors, outside and
          underground with deep penetration connectivity so users always know
          the exact location of assets and can even calculate the optimal route
          to collect them.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[4].url} alt="usecases5" id="AgricultureForestry"></img>
        </div>
        <h3>HEALTHCARE</h3>
        <h4>Tracking Hospital Equipment</h4>
        <p className="TextUsecasesLPLeft">
          Hospital staff lose an inordinate amount of time searching for
          hospital equipment required to service patients. By deploying a
          network of tracking devices, users can follow any object throughout
          the entire hospital premises — indoors, underground and outside. This
          enables staff to immediately locate everything from hospital beds to
          wheelchairs while minimizing delays and adding more critical time for
          patient care.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[5].url} alt="usecases6" id="BuildingInfra"></img>
        </div>
        <h3>UTILITIES</h3>
        <h4>Smart Metering</h4>
        <p className="TextUsecasesLPLeft">
          Utilities are heavily reliant on manual readings and lack personnel to
          provide accurate, real-time data for cost and efficiency optimization.
          Track and locate assets such as smart gas and water meters for
          targeted maintenance and automatic readings. With LoRa Edge, users can
          automate utility management to limit human error and contribute to
          smarter cities.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[6].url} alt="usecases7" id="SupplyChain"></img>
        </div>
        <h3>INDUSTRIAL </h3>
        <h4>Aerospace &Automotive </h4>
        <p className="TextUsecasesLPLeft">
          To accurately track the location of costly manufacturing assets within
          automobile or airplane factories, factory managers can deploy a
          network of asset trackers to trace equipment throughout large
          facilities, indoors, underground and outdoors. By quickly deploying
          trackers to monitor assets that are constantly in transit, production
          lead time is greatly reduced and alerts are sent whenever an asset
          leaves the factory’s floor.
        </p>

        <h4>Construction Employee & Equipment Tracking </h4>
        <p className="TextUsecasesLPLeft">
          Construction sites are wide open and easily accessible areas, which
          heightens risk of theft and security issues. Monitor and track
          workers, equipment and tools to improve safety, control entry points
          and prevent loss or theft of assets. LoRa Edge-enabled trackers help
          secure entire zones and detect unauthorized access.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[7].url} alt="usecases8" id="SmartCities"></img>
        </div>

        <h3>BUILDING</h3>
        <h4>Indoor Asset Inventory</h4>
        <p className="TextUsecasesLPLeft">
          To maximize utilization of equipment, building managers can deploy a
          network of devices to cost-effectively, accurately and remotely locate
          equipment around a specific location as well as perform regular,
          automated inventory of both fixed and moving assets. Asset tracking
          within a building space can involve machinery, documents, amenities in
          public or private spaces including warehouses, offices and hospitals.
        </p>

        <h4>Building Security </h4>
        <p className="TextUsecasesLPLeft">
          The wide areas and vertical structure of buildings make enhancing
          security beyond the capabilities of camera surveillance systems a
          challenge. With sensors tracking items, equipment and employee
          movements both indoors and outdoors, emergencies or issues can be
          identified automatically and addressed quicker.
        </p>

        <div className="imgUseCasesMobile">
          <img src={locatorImages.useCasePageImg[8].url} alt="usecases9" id="Utilities"></img>
        </div>
        <h3>CONSUMER</h3>
        <h4> Tracking Personal High Value Assets </h4>
        <p className="TextUsecasesLPLeft">
          General purpose trackers with LoRa Edge can enable consumers to track
          a variety of their personal high value assets. Whether it’s tracking
          your bike, a package shipped across the country or tracking garden
          equipment and tools, simple asset tracking is possible thanks to
          continuous coverage provided by LoRa Edge.
        </p>
      </div>

      <FooterMobile />
    </div>
  );
};

export default UseCasesMobile;
