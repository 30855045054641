/*
const hardwareSublinks = [
  {
    name: 'Gateways',
    href: '/hardware/gateways',
    pathMatch: {
      path: "/hardware/gateways",
    },
  },
  {
    name: 'Trackers',
    href: '/hardware/trackers',
    pathMatch: {
      path: "/hardware/trackers",
    },
  },
  {
    name: 'How to set up?',
    href: '/hardware/how-to',
    pathMatch: {
      path: "/hardware/how-to",
    },
  }
];
*/
const supportSublinks = [
  {
    name: 'Getting Started',
    href: '/help/gettingStarted',
    pathMatch:{
      path:"/help/gettingStarted",
      exact: true
    }
    
    },
  {
    name: 'FAQ',
    href: '/help/faq',
    pathMatch: {
      path: "/help/faq",
      exact: true
    },
  },
  {
    name: 'Forum',
    href: 'https://forum.lora-developers.semtech.com/',
    target:"_blank"
    
  },
  {
    name: 'Support Ticket',
    href: 'https://semtech.force.com/ldp/ldp_support',
    target:'_blank',
    pathMatch: {
      path: "/support/supportTicket",
      exact: true,
    },
    
  },

];

const referencesSublinks=[

 
 
  {
    name: 'Truvami',
    href: '/references/truvami',
    pathMatch: {
      path: "/references/truvami",
      exact: true
    },
    
    },
  
  {
    name: 'Digital Matter',
    href: '/references/digitalMatter',
    pathMatch: {
      path: "/references/digitalMatter",
      exact: true
    },
  },
  {
    name: 'Browan',
    href: '/references/browan',
    pathMatch: {
      path: "/references/browan",
      exact: true
    },
  },
  {
    name: 'Everynet',
    href: '/references/everynet',
    pathMatch: {
      path: "/references/everynet",
      exact: true
    },
  },
];


const networkSublinks = [
  {
    name: 'Private',
    href: '/networks/gateways#private',
    pathMatch: {
      path: "/networks/gateways#private",
      exact: true
    }
  },
  {
    name: 'Public',
    href: '/networks/gateways#public',
    pathMatch: {
      path: "/networks/gateways#public",
      exact: true
    }
  }
  
];
export const headerLinksMap = [

  {
    name: 'Use Cases',
    href: '/useCases',
    pathMatch: {
      path: "/use-cases/:page",
      exact: false
    },
  },
   {
    name: 'References',
    pathMatch: {
     // path: "/support/:page",
    },
    sublinks: referencesSublinks
  },
  {
    name: 'Networks',
    href: '/networks',
    pathMatch: {
      path: "/networks/:page",
      exact: false
    },
    sublinks: networkSublinks
  },
  {
    name: 'Trackers',
    href: '/trackers',
    pathMatch: {
      path: "/trackers/:doc",
      exact: false
    }
  },
  {
    name: 'Help',
    pathMatch: {
     // path: "/support/:page",
    },
    sublinks: supportSublinks
  },
  {
    name: "WHAT'S NEW",
    href: '/whatsNew',
    pathMatch: {
      path: "/whatsNew",
      exact: true
    }
  },
];
