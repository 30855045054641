import Moment from "moment";

export const getDate = (epochTime) => {
  let formated = Moment(epochTime * 1000).format(
    "dddd, MMMM Do YYYY, h:mm:ss a"
  );
  return formated;
};

export const getDateDayMonthYear = (date) => {
  const [day, month, year] = date.split("/");
  const dateFormated = new Date(+year, +month - 1, +day);
  return dateFormated;
};

export const getDaysLeft = (value) => {
  if (value !== 0){
  let realDate = Math.abs(value);
  let dateString = realDate.toString() + " days ago";
  return dateString;
  } else {
    return "Today";
  }
};

export const getDaysRight = (value) => {
  if (value !== 0){
  let realDate = Math.abs(value);
  let dateString = realDate.toString() + " days ago";
  return dateString;
  } else {
    return "Today";
  }
};

export const getDateString = date => date.toISOString().split('T')[0]
export const getDateFromStringWithOffset = dateString => dateAdd(new Date(dateString), 'minute', new Date().getTimezoneOffset())
export const getDateHuman = date => {
  let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}

export function dateAdd(date, interval, units) {
  if(!(date instanceof Date))
    return undefined;
  var ret = new Date(date); //don't change original date
  var checkRollover = function() { if(ret.getDate() !== date.getDate()) ret.setDate(0);};
  switch(String(interval).toLowerCase()) {
    case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
    case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
    case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
    case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
    case 'day'    :  ret.setDate(ret.getDate() + units);  break;
    case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
    case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
    case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
    default       :  ret = undefined;  break;
  }
  return ret;
}

export const getLeftSlider = (value, firstDeviceDate) => {
  const value_string=getDateString(value)
  const a=new Date(value_string)
  const b=new Date(firstDeviceDate)
  const lower_limit=Math.abs(Math.round((a.getTime() - b.getTime())/60/60/24/1000))
  let left_slider = -7

  if (lower_limit < 7){
    left_slider = lower_limit*-1
  }

  return left_slider
}

export const getStartingDayDate = (date) => {
  const newDate = new Date(date);
  newDate.setMilliseconds(0);
  newDate.setSeconds(0);
  newDate.setMinutes(0);
  newDate.setHours(0);

  return newDate;
}

export const getEndingDayDate = (date) => {
  const newDate = new Date(date);
  newDate.setMilliseconds(999);
  newDate.setSeconds(59);
  newDate.setMinutes(59);
  newDate.setHours(23);

  return newDate;
}
