import React, { useEffect, useState } from "react";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import { useDispatch } from "react-redux";
import API from "services/axiosCall";
import "../menu/menuDocument.css";
import Carrousel from "../carrousel/carrousel";
import Footer from "../../footer/Footer";
import { locatorImages } from "components/img-content/img-content";
import { getDocsAction } from "../../../../store/reducers/documentsReducers";

const MenuDocument = () => {
  const dispatch = useDispatch();

  const [technicalDocuments, setTechnicalDocuments] = useState(false);
  const [intro, setIntro] = useState(true);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const response = await API.getDocuments();
        let responses = [];
        for (let doc of response) {
          await API.getDocument(doc).then((response) => {
            responses.push(response[0]);
          });
        }
        dispatch(getDocsAction(responses));
      } catch (error) {}
    };

    getDocs();
  }, [dispatch]);

  const changeSection = () => {
    setTechnicalDocuments(true);
    setIntro(false);
  };

  const changeIntro = () => {
    setTechnicalDocuments(false);
    setIntro(true);
  };

  return (
    <div>
      <div className="menu-docs-row">
        <div className="menu-content">
          <div className="menu-title">
            <img src={locatorImages.menuDocument[0].url} alt="boxIcon"></img>
            <p>MENU</p>
          </div>
          <div className="menu-list">
            <ul>
              <li className={intro ? "MenuDocsActiveTab" : ""}>
                <button onClick={changeIntro}>
                  {" "}
                  <RoomOutlinedIcon style={{ color: "#00aeef" }} />
                  Intro
                </button>
              </li>
              <li className={technicalDocuments ? "MenuDocsActiveTab" : ""}>
                <button onClick={changeSection}>
                  {" "}
                  <RoomOutlinedIcon style={{ color: "#00aeef" }} />
                  Technical Documents
                </button>
              </li>
              <li>
                <button>
                  {" "}
                  <RoomOutlinedIcon style={{ color: "#00aeef" }} />
                  Specifications and data sheets
                </button>
              </li>
              <li>
                <button>
                  {" "}
                  <RoomOutlinedIcon style={{ color: "#00aeef" }} />
                  Trademark guidelines
                </button>
              </li>
            </ul>
          </div>
        </div>

        {technicalDocuments === true ? (
          <div className="menu-display">
            <Carrousel />
          </div>
        ) : (
          <div></div>
        )}
        {intro === true ? (
          <div className="menu-display">
            <p>
              Looking for more information about the LoRa Edge™ Asset Tracker or
              LoRa Cloud Geolocation Services?
            </p>
            <p>Need support in setting up your account and devices?</p>
            <br></br>
            <p class="docs-menu-second-text">
              Our documentation offers you all you need to know.
            </p>
          </div>
        ) : (
          <div></div>
        )}
        <div></div>
      </div>
      <Footer />
    </div>
  );
};

export default MenuDocument;
