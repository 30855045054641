import React, { useEffect, useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/styles";
import "./PrincipalContent.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import { locatorImages } from "components/img-content/img-content";

const useStyles = makeStyles(iconStyles);

export default function PrincipalContent() {
  useEffect(() => {
    document.title = "LoRa Cloud™ Locator - Semtech";
  }, []);

  const { loginWithRedirect } = useAuth0();

  // eslint-disable-next-line no-unused-vars
  const css = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ExpandMore = () => {
    return (
      <button className={css.collapseButton} onClick={handleExpandClick}>
        Read more
      </button>
    );
  };

  const ExpandLess = () => {
    return (
      <button className={css.collapseButton} onClick={handleExpandClick}>
        Read less
      </button>
    );
  };

  return (
    <div className="section " style={{ maxWidth: "100%" }}>
      <div className="section3">
        <div className="Section3TextLandingPage">
          <h3 style={{ color: "#333" }}>Easy to Set Up as 1, 2, 3</h3>
          <p>
            LoRa Cloud Locator helps you evaluate the accuracy and power
            consumption of LoRa Edge for asset tracking in any IoT segment. It
            gives you real-life insight in how LoRa Edge can work for your use
            case.
          </p>
        </div>
        <div className="steps-tracking">
          <div className="circle-steps1">1</div>
          <div className="circle-steps2">2</div>
          <div className="circle-steps3">3</div>
          <div className="steps">
            <a href="/trackers" style={{ color: "#00AEEF" }}>
              Purchase
            </a>{" "}
            your LoRa Edge-enabled tracker (and gateway if required)
          </div>
          <div className="steps">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              id="signUp"
              onClick={() =>
                loginWithRedirect({ appState: { target: "/device-tracking" } })
              }
            >
              Sign up{" "}
            </a>
            or use your existing Lora Cloud account to log in to LoRa Cloud
            Locator
          </div>
          <div className="steps">
            <a href="/help/gettingStarted">Configure</a> your hardware and view
            its location on the map
          </div>
        </div>

        <div className="Section3TextLandingPage">
          <p>Create your LoRa Cloud Locator account for free.</p>
          <center>
            {" "}
            <button
              onClick={() => loginWithRedirect()}
              className="sign-up-section2"
            >
              GET STARTED <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </center>
        </div>
      </div>

      <div className="section4Background">
        <div className="section4">
          <div className="section4Text">
            <h3 style={{ color: "#333" }}>What Makes LoRa Edge so Powerful?</h3>
            <p>
              LoRa Edge is an ultra-low power platform that integrates a
              long-range LoRa® transceiver, multi-constellation scanner and
              passive Wi-Fi AP MAC address scanner that targets GNSS asset
              management applications.
            </p>
          </div>

          <div className="leftRightPanelsLP">
            <div className="leftImageHome">
              <img src={locatorImages.homePageImg[1].url} alt="batteryLifeIcon" />
            </div>
            <div className="rightTextHome">
              <h3>Ultra low-power geolocation</h3>
              <p>
                Significantly reduced power consumption via Cloud-based solvers
                extends device battery life up to 10 years.
              </p>
            </div>
          </div>

          <div className="leftRightPanelsLP">
            <div className="leftImageHome">
              <img src={locatorImages.homePageImg[2].url} alt="AntenaIcon" />
            </div>
            <div className="rightTextHome">
              <h3>Complete connectivity and coverage everywhere</h3>
              <p>
                Combining indoor Wi-Fi scanning with GNSS outdoor scanning
                guarantees continuous tracking of your devices throughout
                warehouses, stores, office buildings, and anywhere in between.
              </p>
            </div>
          </div>

          <div className="leftRightPanelsLP">
            <div className="leftImageHome">
              <img src={locatorImages.homePageImg[3].url} alt="chipIcon" />
            </div>
            <div className="rightTextHome">
              <h3>Full service flexibility and ecosystem support</h3>
              <p>
                Tracking your devices and building your own custom application
                is made incredibly easy thanks to a solid ecosystem of hardware
                vendors, systems integrators and Cloud platforms. Interested in
                building your own application for your asset tracking use case?
                Unlock the full power of LoRa Edge. Visit loracloud.com for more
                information.
              </p>
            </div>
          </div>

          <div className="Section3TextLandingPage">
            <center>
              {" "}
              <a
                href="https://www.semtech.com/products/wireless-rf/lora-edge"
                target="_blank"
                rel="noopener noreferrer"
                className="sign-up-section2"
              >
                LEARN MORE ABOUT LoRa Edge{" "}
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </a>
            </center>
          </div>
        </div>
      </div>

      {/* <div className="leftImageSection">

                <div className="SectionLeftAlignmentCenter">
                    <img src={section2Image} />
                    <div className="RightImageSection">
                        <h3>Unlimited asset tracking possibilities</h3>
                        <p>
                    <b> Want to be sure LoRa Edge™ is right for your solution?</b><br/> LoRa Cloud™ Locator shows you how to unlock any asset tracking solution across the entire global supply chain. 
                        Whether it’s for wheelchairs in hospitals, shopping carts in retail outlets, pallet|s in warehouses, cattle on farms, or pets in your home
                        , assets should be located everywhere in real time. 
                        <br/><br/><b style={{color:'#00aeef',fontWeight:'400',fontSize:'2.222vh',fontWeight:'550'}}>Check out just some of the use cases you can deploy using LoRa™:</b>
                        </p>
                        <div className="RightImageSection3ColumnsGrid">
                            <div className="RightImageSection3Columns">              
                                <ul>
                                <li><a href="/useCases#Healthcare">Healthcare</a></li>
                                <li><a href="/useCases#HomeConsumer">Home & Consumer</a></li>
                                <li><a href="/useCases#Retail">Retail</a></li>                   
                                <li><a href="/useCases#Industrial">Industrial</a></li>
                                <li><a href="/useCases#AgricultureForestry">Agriculture & Forestry</a></li>
                                <li><a href="/useCases#BuildingInfra">Building & Infrastructure</a></li>
                                <li><a href="/useCases#SupplyChain">Supply Chain And Logistics</a></li>
                                <li><a href="/useCases#SmartCities">Smart Cities</a></li>
                                <li><a href="/useCases#Utilities">Utilities</a> </li>
                                </ul>
                            </div>
                        </div>
                     
                        <center> <button onClick={() => loginWithRedirect()} className="sign-up-sectionleft buttonBluesectionleft">I WANT TO TRY LoRa Cloud™ Locator</button></center> 
                        
                
                    </div>

                </div>
                 
                </div> */}

      <div className="section5Background">
        <div className="section5">
          <div className="section5-title">Customers</div>
          <div className="reviews">
            <Carousel
              style={{ maxWidth: "100vw" }}
              className="carouselcustomers"
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    id="prevButton"
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="prevArrow"
                  ></button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    id="nextButton"
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="nextArrow"
                  ></button>
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  minHeight: "55vh",
                }}
              >
                <div className="review-format">
                  <img
                    className="double-quotes-style"
                    src={locatorImages.homePageImg[10].url}
                    alt="doubleQuotesIcon"
                  />
                  <div className="logo-format">
                    <img
                      src={locatorImages.homePageImg[4].url}
                      style={{ marginTop: "13%" }}
                      alt="vermontIcon"
                    />
                  </div>
                  <div className="text-format">
                    <p>
                      Semtech’s LoRa Cloud Locator proved to be an excellent
                      tool to evaluate the capabilities of the LoRa Edge™
                      Platform to our customers in Brazil. Registering my
                      tracker on the service was incredibly easy, and I was able
                      to retrieve the location of my unit all the time as well
                      as follow the track on the map right from the beginning.
                    </p>
                  </div>
                </div>

                <div className="review-format">
                  <img
                    className="double-quotes-style"
                    src={locatorImages.homePageImg[10].url}
                    alt="doubleQuotesIcon"
                  />
                  <div className="logo-format">
                    <img
                      src={locatorImages.trackersPageImg[18].url}
                      style={{
                        width: "85%",
                        marginTop: "9%",
                      }}
                      alt="calchipIcon"
                    />
                  </div>
                  <div className="text-format">
                    <p>
                      The experience of adding devices and gateways is
                      straightforward. No one should have any issues getting up
                      and running in no time. I was quite impressed with the
                      location accuracy. While outdoors you get the best
                      accuracy via satellite, when the tracker is indoors, Wi-Fi
                      sniffing accuracy is far better and addresses the
                      challenges of GPS drift.
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  minHeight: "55vh",
                }}
              >
                <div className="review-format">
                  <img
                    className="double-quotes-style"
                    src={locatorImages.homePageImg[10].url}
                    alt="doubleQuotesIcon"
                  />
                  <div className="logo-format">
                    <img
                      src={locatorImages.homePageImg[5].url}
                      style={{
                        width: "40%",
                        marginTop: "2%",
                        marginBottom: "0",
                      }}
                      alt="tencentLogo"
                    />
                  </div>
                  <div className="text-format">
                    <p>
                      “Upon testing Semtech’s LoRa Cloud Locator firsthand, the
                      service itself was very straightforward; quick and easy to
                      set up, enabling us to efficiently test the accuracy and
                      responsiveness of the service in a variety of
                      environments. The results of our tests further confirmed
                      the broad portfolio of use cases that will directly
                      benefit from the precision geolocation and ultra-low power
                      capabilities of the LoRa Edge platform. We believe that
                      this high-resolution tracking combined with very long
                      battery life is a game changer for our cold chain
                      monitoring and supply chain projects.”
                    </p>
                  </div>
                </div>
                <div className="review-format">
                  <img
                    className="double-quotes-style"
                    src={locatorImages.homePageImg[10].url}
                    alt="doubleQuotesIcon"
                  />
                  <div className="logo-format">
                    <img
                      src={locatorImages.homePageImg[6].url}
                      style={{ width: "50%" }}
                      alt="GalileoIcon"
                    />
                  </div>
                  <div className="text-format">
                    <p>
                      “Semtech's LoRa Cloud Locator is the most efficient and
                      fast way to evaluate the LoRa Edge platform as it can
                      measure the performance of the technology and
                      differentiate when a device is tracked by GNSS or Wi-Fi.
                      With the Wi-Fi location feature, we can now receive GNSS
                      signals without paying for the prohibitive power
                      consumption of traditional GNSS technologies. Through
                      leveraging LoRa Edge™, locating assets around the world is
                      much simpler with the unprecedented years of battery
                      life.”
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  minHeight: "55vh",
                }}
              >
                <div className="review-format">
                  <img
                    className="double-quotes-style"
                    src={locatorImages.homePageImg[10].url}
                    alt="doubleQuotesIcon"
                  />
                  <div className="logo-format">
                    <img
                      src={locatorImages.homePageImg[7].url}
                      style={{ marginTop: "1%" }}
                      alt="vermontIcon"
                    />
                  </div>
                  <div className="text-format">
                    <p>
                      “At INDUTRAX we are constantly exploring the location
                      tracking market in search of new and innovative
                      technologies to integrate in our software products. As
                      experts in creating industry 4.0 solutions in production
                      and logistics, we believe LoRa Cloud™ Locator is a
                      breakthrough in asset tracking solutions. It is an enabler
                      for our applications and apps that seamlessly combine
                      outdoor and indoor tracking. We had the opportunity to
                      verify the accuracy and power consumption of LoRa
                      Edge™-enabled devices and see it as a suitable source
                      system for the asset tracking use cases that we can offer
                      to our clients.”
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="section6Background">
        <div className="section6">
          <h3 className="section6-title">Testimonial</h3>
          <div className="reviews">
            <Carousel
              style={{ maxWidth: "100vw" }}
              className="carouselcustomers"
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    id="prevButton"
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="prevArrow"
                  ></button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    id="nextButton"
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    className="nextArrow"
                  ></button>
                )
              }
            >
              <div className="testimonials">
                <div className="leftSide">
                  <img src={locatorImages.homePageImg[8].url} alt="testimonial" />
                </div>
                <div className="rightSide">
                  <div className="testimonialTitle">
                    Stolen Luggage Quickly Located With LoRa Cloud™ Locator
                  </div>
                  <div className="testimonialGeneral">
                    <div className="review-format1">
                      <img
                        className="double-quotes-testimonial-style"
                        src={locatorImages.homePageImg[10].url}
                        alt="doubleQuotesIcon"
                      />
                      <div className="logo-format-testimonial">
                        <img
                          src={locatorImages.homePageImg[9].url}
                          style={{ marginTop: "23%" }}
                          alt="vermontIcon"
                        />
                      </div>
                    </div>
                    <div className="testimonial-text">
                      <p>
                        “I think this technology is very effective. The
                        Amsterdam police were surprised by the accuracy!” <br />
                        <h4
                          style={{
                            fontWeight: "500",
                            marginBlockStart: "0",
                            marginBlockEnd: "0",
                          }}
                        >
                          Hussein Daj, Developer.
                        </h4>
                      </p>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div className="storyTestimonial">
                          <p>
                            I participated in the “Ultra-low-power Geolocation
                            with LoRa Cloud™ Locator” workshop during The Things
                            Conference 2022 presented by Karthik Ranjan and
                            received a LoRa Edge™ Tracker Reference Design.
                            <br />
                            Unfortunately, my bags were stolen from the hotel
                            where I was staying, but thanks to this tracking
                            device which I had put in my bag I was able to see
                            the thief's location in LoRa Cloud™ Locator in
                            near-real time and managed to catch the thief at the
                            train station with the help of the Amsterdam police
                            and recovered my stolen items.
                          </p>
                          <p>
                            I think this technology is very promising and
                            effective — the Amsterdam police were surprised by
                            its accuracy. It is great to test the effectiveness
                            of this technique on the ground. Combining Wi-Fi
                            passive scan and GNSS scanning to obtain the
                            location of devices provides coverage in any indoor
                            or outdoor location. The superior battery life of
                            LoRa Edge-enabled trackers will also be a game
                            changer in the asset tracking market.
                          </p>
                        </div>
                      </Collapse>
                      {!expanded ? (
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        ></ExpandMore>
                      ) : (
                        <ExpandLess
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show less"
                        ></ExpandLess>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

function iconStyles() {
  return {
    addcircleicon: {
      color: "#00aeef",
      width: "3rem",
      height: "3rem",
      fontWeight: "600",
    },
    circleicon: {
      color: "#00aeef",
      width: "1.25rem",
      height: "1.25rem",
    },
    collapseButton: {
      color: "#00aeef",
      border: "none",
      backgroundColor: "#ffffff",
      cursor: "pointer",
      textDecoration: "underline",
      marginLeft: "auto",
      display: "flex",
      fontFamily: ["Fira sans", "sans-serif"],
    },
  };
}
