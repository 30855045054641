import React ,{ useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import SiteLinks from "./SiteLinks";
import AuthLinks from "./AuthLinks";
import { slide as Menu } from "react-burger-menu";
import "./header.css";
import mobile from "is-mobile";
import useWindowDimensions from "components/useWindowDimensions"; 

const Header = () => { 
  
  let isMobile;
  const {  width } = useWindowDimensions();

  useEffect(() => {
    if(width <= 900){
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isMobile=true;
    }else{
      isMobile=mobile();
    }
    },width)

    
  const css = useStyle();

  return (
  
  
    <>
    {!isMobile ?(
    <header  className={`${css.header}`}>
    <Logo />
      <div className={css.navbar}>
        <SiteLinks />
        <AuthLinks />
      </div>
    </header>):(

    <header  className={`${css.headerMobile}`}>
    
    <div >
    <Menu styles={{width:'100%'}}>
    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center',width: '100vw'}}> <Logo /></div>
    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}> <SiteLinks /></div> 
    <div style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'center'}}> <AuthLinks /> </div>
    </Menu>
    </div>
    <center>  <Logo /></center> 
  </header>)}
  </>
 

  );
}

const useStyle = makeStyles((theme) => ({
  headerMobile:{
    background: '#FFF',
    width: '100vw',
    position:'fixed',
    zIndex:'999',
    height: '15vh',
    left:'0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bmOverlay :{
    backgroundColor:'red',
  },
  header: {
    alignItems: 'center',
    left:'0',
    zIndex:'999',
    boxShadow:'#00000029 0px 1px 5px 0px',
    height: '12.1vh',
    maxWidth:'100vw',
    position: 'sticky',
    top:"0",
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
    paddingLeft:'10.42%',
    paddingRight:'10.42%',
    paddingBotton:'1.56%',
    borderLeft:'0px transparent',
    borderRight:'0px transparent',
    [theme.breakpoints.only('xs')]: {
      paddingLeft:'1%',
      paddingRight:'1%',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft:'3%',
      paddingRight:'3%',
      },
      
      [theme.breakpoints.only('md')]: {  
        paddingLeft:'5%',
      paddingRight:'5%',
        
      
      },
      [theme.breakpoints.only('lg')]: {  
        paddingLeft:'5%',
      paddingRight:'5%',
        
      
      },



   
    
  },
  navbar: {
    display: 'flex',
    width:'40vw',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  [theme.breakpoints.only('xs')]: {  
    display:'flex',
  
  },
  [theme.breakpoints.only('sm')]: {  
    display:'flex',
    
  
  },
}));

export default Header;