import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";


import { headerLinksMap } from './linksMap';


export default function useNavLinkList () {
  const location = useLocation();
  const [links, setLinks] = useState([]);

  useEffect(() => {
    
    const aggregatedLinks = appendActivePathProp(
      location.pathname, cloneDeep(headerLinksMap)
    );

    setLinks(aggregatedLinks);
  }, [location]);

  return links;
}

function appendActivePathProp(pathname, links) {
  return links.map((link) => {
    link.isActive = !!matchPath(pathname, link.pathMatch);

    if (link.sublinks) {
      link.sublinks = appendActivePathProp(pathname, link.sublinks);
    }

    return link;
  });
}
