import React from "react";

import "./SupportEmail.css";
import "./SupportEmailFonts.css";

import Header from "components/landing_page/header/Header";
import Footer from "components/landing_page/footer/Footer";
import { locatorImages } from "components/img-content/img-content";
import BreadcrumbsBar from "components/landing_page/common/BreadcrumbsBar";

const breadcrumbs_path = ["HELP", "EMAIL SUPPORT"];

export default function supportEmail() {
  return (
    <>
      <div className="support-email">
        <Header />
        <div className="content">
          <div className="support-email-text">
            <img src={locatorImages.supportPage[1].url} alt="EmailSupportIcon" />
            Email support
          </div>
          <div className="email-support-title">
            <img src={locatorImages.supportPage[2].url} alt="HelpSupportIcon"></img>
            <p>How can we help you? </p>
            <p style={{ width: "52%", paddingLeft: "3%" }}>
              <hr />
            </p>
          </div>
          <div className="email-support-content">
            <img src={locatorImages.supportPage[0].url} alt="Support-Email" />
            <div className="email-support-box">
              <p>
                If you need help please send us an email telling us in detail
                your problem and we will happily help you.
              </p>
              <button className="support-button">support@gmail.com</button>
              <p className="contact">
                We will be in contact as soon as possible to follow your case.
              </p>
            </div>
          </div>
          <BreadcrumbsBar path={breadcrumbs_path} />
        </div>
        <Footer />
      </div>
    </>
  );
}
