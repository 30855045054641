import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const AuthButton = withStyles((theme) => ({
  root: {
    borderRadius: "0",
    fontFamily: '"Fira Sans"',
    margin: "0 0.4vw",
    paddingBottom: ".1%",
    paddingTop: ".1%",
    color: "white",
    fontSize: "19px",
    height: "40px",
    backgroundColor: "#00AEEF",
    border: "1px solid #00AEEF",
    fontWeight: "normal",
    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "white",
    },

    [theme.breakpoints.only("lg")]: {
      fontSize: "1.3125rem",
      fontFamily: '"Fira Sans"',
      padding: "0.1em",
      paddingLeft: "1em",
      paddingRight: "1em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1875rem",
      fontFamily: '"Fira Sans"',
      margin: "0 0.2vw",
      padding: "0.3em",
      paddingLeft: "0.8em",
      paddingRight: "0.8em",
    },
  },
}))(Button);

export default AuthButton;
