import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FooterMobile from "./footerMobile";
import HeaderMobile from "./headerMobile";
import "./whats-new-mobile.css";
import BreadcrumbsBar from "../landing_page/common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";
import ReactMarkdown from "react-markdown";
import API from "services/axiosCall";
import remarkGfm from "remark-gfm";
import Select from "@material-ui/core/Select";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";
import {getDateDayMonthYear} from "utils/helperFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const WhatsNew = () => {
  const { handle } = useParams();
  const [markdown, setmarkdown] = useState("");
  const [markdownShown, setmarkdownShown] = useState("");

  const [FilterMonth, SetFilterMonth] = useState("Browse by month");
  const [isLoading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const {loginWithRedirect} = useAuth0();

  useEffect(() => {
    if (width > 900) {
      history.push("/whatsNew");
    }
  }, [width, history]);

  function LinkRenderer(props) {
    if(props.href === "login"){
      return (

      <a href="#" onClick={() => signIn()} >
      {props.children}
    </a>);
    }
    else{
    return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a> );
    }
}

  const signIn = async () => {
    try {
      loginWithRedirect()
      window.location.replace("/landing");

    } catch (error) {
      console.error("error signing in", error);
    }
  }

  const MarkdownRenderer = ({ children }) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{ a: LinkRenderer }}
      >
        {children}
      </ReactMarkdown>
    );
  };
  useEffect(() => {
    document.title = "What's New | LoRa Cloud™ Locator | Semtech";
  }, []);

  useEffect(() => {
    getNews();
    // eslint-disable-next-line
  }, [handle]);

  useEffect(() => {

    let markdownString = markdown.toString();
    let markdownString1=markdownString.replaceAll(",#", "\n#");
    let arrayDeCadenas = markdownString1.split("\n");


    if (FilterMonth === "Browse by month") {
      setmarkdownShown(markdownString1);
    } else {
      setmarkdownShown("");
      let markdowntobeset = "";
      for ( var i = 0; i < arrayDeCadenas.length; i++) {
        if (arrayDeCadenas[i].includes(FilterMonth)) {
          markdowntobeset = markdowntobeset + arrayDeCadenas[i - 1] + "\n";
          let j=i;
          while(arrayDeCadenas.length > j){
            if(arrayDeCadenas[j].startsWith("# ") ){break;}

            markdowntobeset = markdowntobeset + arrayDeCadenas[j] + "\n";
            j++;
          }
        }
      }
      setmarkdownShown(markdowntobeset);
    }

  }, [FilterMonth, SetFilterMonth, markdown]);





  const breadcrumbs_path = [
    ["HOME", "/landing"],
    ["WHAT'S NEW", "/whatsNew"],
  ];

  const getNews = async () => {
    try {
      setmarkdown("");
      let new1;
      let allNews =[];
      await API.getNews().then((response) => {
        response.map(async (notice) => {
          new1 = notice.substring(notice.search("/") + 1);
          await API.getNew(new1).then((response2) => {
            allNews.push(response2[0])
            //allNews += response2[0];
            orderNews(allNews);
           setmarkdown(allNews);
           setmarkdownShown(allNews);

          });
        })
      })
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const SetMonth = (e) => {
    SetFilterMonth(e.target.value);
  }

const orderNews =  (allNews) => {
  allNews.sort( function(a, b) {
    return  ( getDateDayMonthYear( b.substring(b.search("## ")+2,b.search("## ")+13))   - getDateDayMonthYear(a.substring(a.search("## ")+2,a.search("## ")+13)))
  })
  let newsConcated="";

  const requests = allNews.map((notice) => {
    newsConcated += notice.toString() + "\n";
    return null
  })
  return Promise.all(requests).then(() => {
  setmarkdownShown(newsConcated);
  });

}


  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>
      <div className="page">
        <h3 className="mainHeaderPages">What's New?</h3>
        <div className="whatsNewBody">
          {isLoading ? (
            <div>
              <img className="LoadingGif" src={locatorImages.whatsNewPage[0].url} alt="LoadingGif"></img>
            </div>
          ) : (
            <div className="whatsNewContent whatsNewContentDocRendering">
              <div className="whatsNewHeaderLP">
                <div className="whatsNewContentFilter">
                  <p style={{ fontSize: "80%" }}>Filter</p>

                  <Select
                    labelId="select"
                    onChange={(e) => SetMonth(e)}
                    defaultValue={"Browse by month"}
                    className="whatsNewSelectMobile"
                    multiline={true}
                  >
                    <li
                      className="optionWhatsNew"
                      value="Browse by month"
                      selected
                    >
                      {" "}
                      Browse by month{" "}
                    </li>
                    <li className="optionWhatsNew" value="/01/">
                      January
                    </li>
                    <li className="optionWhatsNew" value="/02/">
                      February
                    </li>
                    <li className="optionWhatsNew" value="/03/">
                      March
                    </li>
                    <li className="optionWhatsNew" value="/04/">
                      April
                    </li>
                    <li className="optionWhatsNew" value="/05/">
                      May
                    </li>
                    <li className="optionWhatsNew" value="/06/">
                      June
                    </li>
                    <li className="optionWhatsNew" value="/07/">
                      July
                    </li>
                    <li className="optionWhatsNew" value="/08/">
                      August
                    </li>
                    <li className="optionWhatsNew" value="/09/">
                      September
                    </li>
                    <li className="optionWhatsNew" value="/10/">
                      October
                    </li>
                    <li className="optionWhatsNew" value="/11/">
                      November
                    </li>
                    <li className="optionWhatsNew" value="/12/">
                      December
                    </li>
                  </Select>
                </div>
              </div>
              <MarkdownRenderer>{markdownShown}</MarkdownRenderer>
            </div>
          )}
        </div>
        <FooterMobile />
      </div>
    </div>
  );
};
export default WhatsNew;
