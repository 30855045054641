import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

import FooterMobile from "../footerMobile";
import HeaderMobile from "../headerMobile";
import BreadcrumbsBar from "../../landing_page/common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "DigitalMatter"];

export default function DigitalMatterMobile() {
  const css = useStyle();
  useEffect(() => {
    document.title = "Digital Matter | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/references/digitalMatter");
    }
  }, [width, history]);


  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <div className="page">
        <h3 className="mainHeaderPages"> What our partners say about LoRa Cloud Locator</h3>
        <div className={css.landingPage}>
          <div className={css.introductionNetworks}>
            <div className={css.titleSection} id="title">
              <h3 style={{ color: "#333" }}>
          <center> <img
                style={{width:'70vw'}}
                src={locatorImages.referencePages[5].url}
                alt="DigitalMatterLogo"
              /></center>
              </h3>
            </div>
            <div className={css.contentNetwork}>
                <p style={{fontSize:"1.5rem", textAlign: 'justify'}}>
    Digital Matter is a leading global developer of low-power GPS and IoT hardware for asset tracking and sensor monitoring applications .  <br/>    <br/>      
    Engineered to outperform, we offer a versatile range of LPWAN asset-tracking hardware with the largest portfolio of integration-ready battery-powered 
    asset-tracking devices across a range of connectivity technologies. 
     <br/>    <br/>                                                                                                               
   
For this reason, we were looking for a solution that could demonstrate the power of our ultra-rugged battery-powered IoT asset-tracking device both indoors and outdoors. 
We were specifically looking for something that was easy for the customer to use and that allowed
 them to try different networks in the same application so the user could leverage both cellular and LoRaWAN®.     <br/>    <br/>      

Thanks to Semtech´s expertise in LoRa® and its evaluation tool, LoRa Cloud™ Locator, based on AWS IoT Core, customers can easily purchase one of our
 trackers from distributors in Europe and North America, provision the device into LoRa Cloud Locator, choose the appropriate network for their application
  and see the tracker working typically in less than 15 minutes.    <br/>    <br/>      

The integration between LoRa Cloud Services and AWS IoT Core, fulfilled our requirements in terms of best experience criteria we want for our customers. 
Since we have two different asset tracking devices (Cellular & LoRaWAN) we needed a solution that was able to manage both technologies:
 one to receive LoRaWAN messages through a LoRa Network Server, and in parallel, one that is able to handle the Cellular messages coming from our OEM Server,
  without having to make extra integration efforts.    <br/>    <br/>      

As of June 21st, 2022, our customers now have a simple and easy way to test our LoRaWAN and Cellular trackers, substantially reducing the provisioning time. 
Today, customers can see their position on the map in a couple of minutes, instead of hours. Furthermore, we can monitor the tracker’s performance and users’
 network preferences by knowing how many trackers of each type they are buying, and by tracking the time it takes for the user to get a dot on the map. And lastly 
 LoRa Cloud Locator allows our customers to experience different devices´performance and features, so they can realize their real devices´needs, 
 and by understanding these, we can then do custom device engagement,
 so instead of them building their own devices from scratch we can engage and so something for them, much faster and with lower risk for them.    <br/>    <br/>      
</p>


                
<center>
 <img
 style={{width:"70vw"}}
                src={locatorImages.referencePages[6].url}
                alt="DigitalMatterMap"
 /></center>


   <p style={{fontSize:"1.2rem"}}><b>

About Semtech</b><br/>
<br/>
Semtech Corporation is a high-performance semiconductor, IoT systems, and cloud connectivity service provider dedicated to delivering high-quality technology solutions that enable a smarter, more connected, and sustainable planet. Our global teams are committed to empowering solution architects and application developers to develop breakthrough products for the infrastructure, industrial, and consumer markets.  To ensure scalability for our services and customer offering, Semtech is now part of the APN (AWS Partner Network), and our AirVantage® Smart Connectivity product is available on AWS Marketplace.
</p>
            </div>
        </div>

</div>
</div><FooterMobile />
</div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important
  },
  introductionNetworks: {
    borderBottom: "1px solid #00AEEF",
    paddingBottom: "7%",
  },
  gatewayLandingMain: {
    textAlign: "center",
  },
  gatewayTitleLanding: {
    color: "#00AEEF",
    fontFamily: "Fira sans",
    fontWeight: "500",
    fontSize: "3.33vh",
  },

  gatewayLandingButton: {
    color: "#FFF",
    textDecoration: "none",
    backgroundColor: "#00AEEF",
    border: "solid 1px #00AEEF",

    fontWeight: "600",
    fontSize: "2.22vh",
    transition: ".4s background-color",

    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "#FFF",
    },
  },
  titleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "5vw",
    fontWeight: "450",
    width: "100%",

    textAlign: "center",
    marginBottom: "5%",
    "& h3": {
      fontWeight: "500",
    },
  },
  descriptionSection: {
    fontFamily: "Fira sans", //!important,
    fontSize: "4vw",
    color: "#333",
    "& p": {
      marginBlockStart: "0",
      marginBlockEnd: "0",
    },
    "& a": {
      color: "#00AEEF",
      fontWeight: "500",
    },
    "& span": {
      fontWeight: "500",
    },
  },

  titleNetwork: {
    color: "#00AEEF",
    fontSize: "2.80vh",
    fontWeight: "450",
    width: "62%",
    margin: "4.8% auto",
    textAlign: "center",
    marginBottom: "5%",
  },
  subtitleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "4.5vw",
    fontWeight: "450",
    textAlign: "center",
  },

  useCases: {
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  gateways: {
    "& img": {
      width: "30%",
    },
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  descriptionGateway: {
    display: "flex",
    flexDirection: "row",
  },

  networkSectionGrid: {
    "& center": {
      marginTop: "5%",
    },
    "& img": {
      width: "50vw",
    },
    width: "100%",
    marginBottom: "20%",
    marginTop: "5%",
  },
  swisscomLogo: {
    "& img": {
      width: "8vw",
    },
  },
  operator: {
    marginTop: "5%",
    "& img": {
      width: "34%",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
  coverage: {
    "& a": {
      color: "#333",
      textDecoration: "none",
      wordBreak: "break-all",
      fontWeight: "100",
    },
  },
  contactSupport: {
    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "2.222vh",
      textAlign: "center",
      transition: "opacity .15s ease-in-out",
      opacity: "100",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      marginRight: "5%",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      marginTop: "2%",
      padding: ".5% .6%",
      "&:hover": {
        color: "#fff",
        border: "1px solid #00aeef",
        transition: "opacity .15s ease-in-out",
        opacity: ".8",
      },
    },
  },
  logoDiv: {
    margin: "auto",
    "& img": {
      maxWidth: "25vw",
    },
  },
  rowBorder: {
    borderBottom: "1px solid #00AEEF",
    gridColumn: "1 / 3",
  },
}));
