import React, { useEffect } from "react";

import BreadcrumbsBar from "../common/BreadcrumbsBar";
import Header from "../header/Header";
import Footer from "components/landing_page/footer/Footer";
import TitleBanner from "../common/TitleBanner";
import { makeStyles } from "@material-ui/core";
import useWindowDimensions from "components/useWindowDimensions";
import { locatorImages } from "components/img-content/img-content";
import { useHistory } from "react-router-dom";
import { height } from "@fortawesome/free-solid-svg-icons/faCircle";

const breadcrumbs_path = [["HOME", "/landing"], "REFERENCES", "TRUVAMI"];
export default function Truvami() {
  const css = useStyle();
  useEffect(() => {
    document.title =
      "References - Truvami | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/references/TruvamiMobile");
    }
  }, [width, history]);

  return (
    <div className="page">
      <Header userData={"false"} />
      <TitleBanner title="Truvami"/>
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />

      <div className={css.landingPage}>
       
        <div className={css.introText}>

               <h2 style={{ color: "#333",fontWeight: '500',fontSize: '2.8rem' }}>
                 What our partners say about LoRa Cloud Locator</h2>
          
 
           
          <h3 style={{ color: "#333" }}>    <img
                className={css.logoRow1}
                src={locatorImages.referencePages[0].url}
                alt="TruvamiLogo"
              /></h3>
        </div>

        <div style={{display:"center"}}>
          <p style={{fontSize:"24px"}}>
Truvami is reshaping the future of security through innovative tracking solutions. We combine Swiss precision with advanced technology to offer tiny, 
yet powerful devices that enable continuous geolocation, both indoor and outdoor. Our mission is to empower our customers with the insights they need to 
achieve ultimate peace of mind for their assets and maximize their operational efficiency.
<br/>
<br/>
We have chosen to integrate our truvami tag XL device with Semtech’s LoRa Cloud Locator, 
to allow our customers to easily switch between any public LoRaWAN® network operator supported by LoRa Cloud Locator or any private LoRaWAN network built on AWS IoT Core.
 This integration showcases the performance of our tag XL device, 
 in addition to leveraging LoRa® benefits such as low power consumption and the LoRa Edge indoor and outdoor geolocation capabilities.
  Thanks to the integration of AWS IoT Core Services into Semtech’s LoRa Cloud Locator, the device is provisioned on AWS IoT Core, 
  utilizing services like AWS DynamoDB and AWS AppSync to extract and store all LoRaWAN messages automatically. 
  This empowers users to track their device’s historical and real-time location data and to view their location on a map with all data captured on a dashboard.
  <br/><br/>
Combined with our application platform,
 our customers have a simple and easy way to test our trackers and their features,
  reducing the time and effort required for provisioning and onboarding the devices. 
  They can see the geolocation of their devices on the map in just a couple of minutes, instead of hours. 
  LoRa Cloud Locator makes it easy for our customers to adopt our devices and scale their IoT asset tracking applications.
</p>
</div>
<br></br><div></div>
<center>
 <img
 style={{width:"100%"}}
                src={locatorImages.referencePages[1].url}
                alt="TruvamiMap"
 /></center>

   <div style={{display:"center"}}>
          <p style={{fontSize:"20px",lineHeight: 'normal'}}>
<br/><b>
About truvami</b><br/><br></br>
truvami is an IoT startup specialized in flexible end-to-end tracking solutions, based in Zürich, Switzerland. The company’s mission is to support enterprises in safeguarding their workforce and valuable assets through cutting-edge tracking technologies at smallest size. truvami offers a portfolio of different trackers that are designed to serve different use-cases in vertical markets like transport, logistics, industrial, construction, fleet- and cattle-tracking. The innovative multi-protocol approach combined with a dedicated geolocation engine allows seamless in- and outdoor tracking. Together with truvami’s cloud software platform, customers can easily integrate the solution into their existing IT landscape.
<br/><br/>
<b>About Semtech</b><br/><br></br>
Semtech Corporation is a high-performance semiconductor, IoT systems, and cloud connectivity service provider dedicated to delivering high-quality technology solutions that enable a smarter, more connected, and sustainable planet. Our global teams are committed to empowering solution architects and application developers to develop breakthrough products for the infrastructure, industrial, and consumer markets.  To ensure scalability for our services and customer offering, Semtech is now part of the APN (AWS Partner Network), and our AirVantage® Smart Connectivity product is available on AWS Marketplace.
</p></div>

      </div>
      <Footer />
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important

    "& h5": {
      fontSize: " 2.66vh",
      fontWeight: "450",
      color: "#00AEEF",
      textAlign: "center",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
      margin: "0.5%",
    },
  },

  introText: {
    textAlign: "center",
    color: "#333",
    "& h3": {
      fontWeight: "500",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      fontSize: "2.25rem",
      width: "62%",
      margin: "4.8% auto",
      textAlign: "center",
      marginBottom: "5%",
    },

    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "1.3125rem",
      textAlign: "center",
      transition: ".4s background-color",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      padding: ".5% .6%",
      "&:hover": {
        color: "#00aeef",
        backgroundColor: "#ffffff",
        border: "1px solid #00aeef",
      },
    },
  },
  operator: {
    borderBottom: "solid 1px #00AEEF",
    paddingBottom: "3%",
  },
  sectionGrid: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "47.5% 47.5%",
    width: "100%",
    margin: "0 auto",

    rowGap: "2vh",
    columnGap: "5%",
    marginTop: "10%",
    marginBottom: "20%",

    "& div": {
      textAlign: "center",
      minWidth: "30vw",
    },

    "& h3": {
      fontWeight: "550",
      color: "#333",
      fontFamily: "Fira sans",
      fontSize: "24px",
      marginTop: "1%",
      marginBottom: "5%",
    },

    "& a": {
      color: "#00AEEF",
      textDecoration: "underline",
      fontSize: "1.3125rem" /*!important*/,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },

    "& img": {},
  },

  descriptionSection: {
    fontSize: "1.3125rem",
    color: "#333",
  },
  logoRow1: {
    maxWidth: "15.255vw",
  },
  logoRow2: {
    maxWidth: "17vw",
  },
  logoRow3: {
    maxHeight: "17vh",
  },
}));
