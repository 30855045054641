import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { locatorImages } from "components/img-content/img-content";

export default function Logo() {
  const css = useStyle();
  const Title = "Locator";

  return (
    <div className={css.logoContainer}>
      <a className={css.logolink} href="/landing">
        <img className={css.logoImg} alt="LoraCloud™" src={locatorImages.headerImg[0].url} />
        <div className={css.div}></div>
        <div className={css.logoText}>{Title}</div>
      </a>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontFamily: '"Fira Sans"',
    alignItems: "center",
    width: "maxContent",
    height: "70.14px",
  },
  logoImg: {
    objectFit: "scale-down",
    height: "7vh",
  },
  logolink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#333",
  },
  logoText: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: "1.3125rem",
    fontFamily: '"Fira Sans"',
    fontWeight: "normal",
    color: "#333",
  },
  div: {
    height: "54px",
    padding: "0 8px",
    marginLeft: "25px",
    borderLeft: "1px solid #969696",
  },
}));
