import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {persistStore, persistReducer} from "redux-persist"
import storage from "redux-persist/lib/storage" //defaults to localStorage for web
import thunk from 'redux-thunk'

import settingsReducer from "./reducers/settingsReducers";
import gatewaysReducer from "./reducers/gatewaysReducers";
import trackingReducer from "./reducers/trackingReducer";
import userReducer from "./reducers/userReducers";
import documentsReducer from "./reducers/documentsReducers";

const reducer = combineReducers({
  settings: settingsReducer,
  gateways: gatewaysReducer,
  tracking: trackingReducer,
  user: userReducer,
  documents: documentsReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['tracking']
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({ 
  reducer: persistedReducer,
  middleware: [thunk]
});

export const  persistedStore = persistStore(store)