import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import "./NetworksMobile.css";
import FooterMobile from "./footerMobile";
import HeaderMobile from "./headerMobile";
import BreadcrumbsBar from "../landing_page/common/BreadcrumbsBar";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "NETWORKS"];

export default function NetworksMobile() {
  const css = useStyle();
  useEffect(() => {
    document.title = "Network | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/networks");
    }
  }, [width, history]);

  const donwloadFileMobile = (fileURL) => {
    window.location.href = fileURL;
  };

  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <div className="page">
        <h3 className="mainHeaderPages">Networks</h3>
        <div className={css.landingPage}>
          <div className={css.introductionNetworks}>
            <div className={css.titleSection} id="title">
              <h3 style={{ color: "#333" }}>
                Access to Multiple Deployment Options
              </h3>
            </div>
            <div className={css.contentNetwork}>
              <div
                className={css.descriptionSection}
                style={{ textAlign: "center" }}
                id="private"
              >
                To visualize your LoRa Edge™-enabled tracker’s positions on a
                geographic map, please select the type of LoRaWAN® network you
                wish to connect.
                <br />​<br />
                ​Depending on your location and needs, LoRaWAN offers multiple
                deployment models. These deployment options include Public and
                Private networks. Read on below and decide which path to go
                based on the use case you wish to explore.
              </div>

              <div className={css.privateNetwork} id="public">
                <h3 style={{ color: "#333" }} className={css.subtitleSection}>
                  Private LoRaWAN Networks
                </h3>
                <div className={css.descriptionSection}>
                  Choose the private LoRaWAN network option if you want to
                  deploy your own LoRaWAN gateways, providing your own gateways
                  backhaul, and deploy trackers in the range of those gateways.
                  Opting for a private LoRaWAN network is typically the right
                  choice when your trackers are outside of the coverage area
                  offered by public network operators, or when you want to
                  operate the network based on your specific requirements
                  (including security in case you do not want to share the
                  network infrastructure). LoRa Cloud™ Locator supports setting
                  up your private network using
                  <span style={{ fontWeight: "500", color: "#00AEEF" }}>
                    {" "}
                    <a
                      href="https://aws.amazon.com/iot-core/lorawan/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AWS IoT Core for LoRaWAN
                    </a>{" "}
                  </span>{" "}
                  and{" "}
                  <span style={{ fontWeight: "500" }}>
                    <a
                      href="https://devices.amazonaws.com/search?page=1&sv=iotclorawan&type=gateway"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      qualified gateways
                    </a>{" "}
                  </span>
                  .​
                </div>
              </div>
              <div className={css.publicNetwork}>
                <h3 style={{ color: "#333" }} className={css.subtitleSection}>
                  Public LoRaWAN Networks
                </h3>
                <div className={css.descriptionSection}>
                  Choose the public LoRaWAN network option if you want to
                  connect your LoRa Edge-enabled tracker to a third party
                  LoRaWAN Operator Network offering a wider coverage that can be
                  nationwide or multi-countrywide. By using a public network,
                  you reduce both setup complexity and cost by avoiding the need
                  to deploy gateways with backhaul. Bear in mind your trackers
                  will be constrained to the coverage area of your public
                  network operator (see below for each operator’s coverage
                  details) as well as their regulations.​
                </div>
              </div>
            </div>
          </div>

          <div className={css.networkSectionGrid}>
            {/*Network 1 Everynet*/}
            <center>
              {" "}
              <a
                href="https://www.everynet.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={locatorImages.networksPageImg[0].url} alt="everynet logo"></img>
              </a>
            </center>
            <div className={css.descriptionSection}>
              <p>
                Everynet is the largest, neutral-host, public network operator
                in the world. We build and maintain carrier-grade networks and
                offer fully managed, ultra-low cost connectivity as a service to
                enterprise users across all verticals.
                <br />
                Everynet operates national networks in Asia, EMEA and the
                Americas. We enable Mobile Network Operators (MNOs), Mobile
                Virtual Network Operators (MVNOs) and global Managed Service
                Providers (MSPs) to offer ultra-low cost IoT immediately and
                profitability with ZERO upfront capital expenditure. Everynet
                makes IoT accessible across any industry to enable
                enterprise-grade solutions, deployed using LoRaWAN technology.
              </p>
              <table className="tableProudctLPMobile">
                <tbody>
                  <tr>
                    <td className={css.titleType}>Coverage</td>
                    <td>
                      <a
                        style={{
                          color: "#00AEEF",
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          donwloadFileMobile(
                            "http://public-locator.loracloud.com/production/docs/Global_Deployments.pptx"
                          )
                        }
                        href="http://public-locator.loracloud.com/production/docs/Global_Deployments.pptx"
                        download="http://public-locator.loracloud.com/production/docs/Global_Deployments.pptx"
                        donwload
                      >
                        Map
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className={css.titleType}>Countries</td>
                    <td>
                      LoRa Cloud™ Locator currently only supports Everynet coverage in USA. For a full 
                      list of Everynet's coverage worldwide, see the above map.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className={css.rowBorder}></div>
              {/*Network 2 Hellium*/}
              <center>
                {" "}
                <a
                  href="https://www.helium.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={locatorImages.networksPageImg[1].url} alt="Hellium logo" />
                </a>
              </center>
              <div className={css.descriptionSection}>
                <p>
                  Helium is the world's fastest-growing decentralized wireless
                  network, democratizing access to the internet worldwide by
                  rewarding anyone to own and operate it. Launched initially as
                  an IoT network, the community approved the expansion of the
                  second major wireless network to be supported, Helium 5G, in
                  2021. The Network is now live in over 61,000 cities and 172
                  countries globally, and used by industry leaders including
                  Actility, Netmore, Kore, X-TELIA, DISH, Volvo Group, Accenture,
                  Olympus, ConnectedFresh, Invoxia, Victor, and others.
                </p>
              </div>
              <table className="tableProudctLPMobile">
                <tbody>
                  <tr className={css.coverage}>
                    <td className={css.titleType}>Coverage</td>
                    <td>
                      <a
                        href="https://explorer.helium.com/"
                        target="_blank"
                        style={{
                          color: "#00AEEF",
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        rel="noopener noreferrer"
                      >
                        https://explorer.helium.com/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className={css.titleType}>Countries</td>
                    <td>LoRaWAN </td>
                  </tr>
                </tbody>
              </table>
              <div className={css.rowBorder}></div>

              {/*Network 3 */}
              {/* No logo for orange yet*/}

              <center>
                <a
                  href="https://www.orange-business.com/en"
                  rel="noopener noreferrer"
                  target="_blank"
                 
                >
                 {" "}  
               <img style={{height:'15vh'}} src={locatorImages.networksPageImg[2].url} alt="OrangeLogo" /> {" "}
                </a>
                <br />
              </center>
              <br />
              <div className={css.descriptionSection}>
              Orange is one of the world’s leading telecommunications operators. The Group is present in 26 
              countries in Europe, Africa, and the Middle East.<br/>
              Orange operates a portfolio of fixed and mobile technologies that enable a 
              broad range of use case, among which is a public LoRaWAN network in mainland France (including Corsica) and the 
              island of Reunion, to address use cases that need very low power consumption.<br/>
              Orange is an active member of the LoRa Alliance and is also a leading provider of global IT and 
              telecommunication services to multinational companies under the brand Orange Business Services.<br/>
              <i>Orange and any other Orange product or service names included in this material are 
                trademarks of Orange or Orange Brand Services Limited.</i>
              </div>
              <table className="tableProudctLPMobile">
                <tbody>
                  <tr>
                    <td className={css.titleType}>Coverage</td>
                    <td>
                      <a
                        href="https://www.orange-business.com/fr/reseau-iot"
                        target="_blank"
                        style={{
                          color: "#00AEEF",
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        rel="noopener noreferrer"
                      >
                        https://www.orange-business.com/fr/reseau-iot
                      </a>
                      ​
                    </td>
                  </tr>
                  <tr>
                    <td className={css.titleType}>Countries</td>
                    <td>France</td>
                  </tr>
                </tbody>
              </table>
              <div className={css.rowBorder}></div>

              {/*Network 4 Netmore*/}
              <center>
                {" "}
                <a
                  href="https://netmoregroup.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={locatorImages.networksPageImg[3].url} alt="Netmore Logo"></img>
                  <br></br>
                
                </a>
              </center>
              <div className={css.descriptionSection}>
                <p><br></br>
                 Netmore Group is an IoT network operator, building the leading multinational network to advance sustainable business practices. We offer a reliable network platform and leading connectivity expertise for efficient measuring, monitoring, and optimization of resource use. With 10 years in the industry, we have a solid track record, operating borderless on the European market and have grown to become a leading global network operator.
                </p>
              </div>
              <table className="tableProudctLPMobile">
                <tbody>
                  <tr>
                    <td className={css.titleType}>Coverage</td>

                    <td>
                      {" "}
                      <a
                        href="https://netmoregroup.com/network-coverage/"
                        target="_blank"
                        style={{
                          color: "#00AEEF",
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        rel="noopener noreferrer"
                      >
                      https://netmoregroup.com/network-coverage/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className={css.titleType}>Countries</td>
                    <td>Global</td>
                  </tr>
                </tbody>
              </table>
              <div className={css.rowBorder}></div>

             
        <center>   <a href="https://www.swisscom.ch/en/business.html" rel="noopener noreferrer" target="_blank"> 
         <img src={locatorImages.networksPageImg[4].url}></img></a></center>
        <div className={css.descriptionSection}>
        <p>
        <b>Swisscom - Switzerland’s leading telecoms company </b><br/>
        Swisscom, Switzerland’s leading telecoms company and one of its leading IT companies, 
        is headquartered in Ittigen, close to the capital city Berne. 
        Outside Switzerland, Swisscom has a presence on the Italian market in the guise of Fastweb.  
        <br/>
        Swisscom IoT offers a best-in-class product portfolio that is 
        modularly-centric for simplified scale. 
        By taking an agnostic and consultative approach, Swisscom IoT can 
        deliver high value end-to-end 
        solutions that serve organizations with both global and regional footprints. 
        With over 20 years of IoT solution experience, Swisscom IoT has enabled start-ups, 
        SME`s along with large corporations create new business 
        models and achieve greater operational efficiency.    
        </p> 
        </div>
        <table className="tableProudctLPMobile">
          <tbody>
            <tr>
              <td className={css.titleType}>Coverage</td>
              
              <td> <a style={{wordBreak:'break-all'}} href="https://www.swisscom.ch/en/business/enterprise/offer/iot/lpn.html" target="_blank">https://www.swisscom.ch/en/business/enterprise/offer/iot/lpn.html</a></td>
            </tr>
            <tr>
              <td className={css.titleType}>Countries</td>
              <td>
              Switzerland, Liechtenstein
              </td>
            </tr>
          </tbody>
        </table>
        <div className={css.rowBorder}></div>


 

              {/*Network 6 TTI*/}
              <center>
                {" "}
                <a
                  href="https://www.thethingsnetwork.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={locatorImages.networksPageImg[5].url} alt="TTI LOGO"></img>{" "}
                </a>
              </center>
              <div className={css.descriptionSection}>
                <p>
                  The Things Industries is a well-established LoRaWAN
                  connectivity and services provider. With a global installed
                  base of over 40.000 gateways, {">"}160.000 users and 500+
                  enterprise customers, we assume a leading role in the global
                  ecosystem. Our mission is to break down the complexities of
                  LoRaWAN development, allow for integration and
                  interoperability across the supply chain, and lower the TCO of
                  LoRaWAN projects. Our core product is the LoRaWAN Network
                  Server. Secure by design, purpose-built to meet the needs of
                  our customers’ global deployments.
                </p>
              </div>
              <table className="tableProudctLPMobile">
                <tbody>
                  <tr>
                    <td className={css.titleType}>Coverage</td>

                    <td>
                      {" "}
                      <a
                        href="https://www.thethingsnetwork.org/map"
                        target="_blank"
                        style={{
                          color: "#00AEEF",
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                          wordBreak: "break-all",
                        }}
                        rel="noopener noreferrer"
                      >
                        https://www.thethingsnetwork.org/map
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className={css.titleType}>Countries</td>
                    <td>Global</td>
                  </tr>
                </tbody>
              </table>
              <div className={css.rowBorder}></div>
            </div>
          </div>
        </div>
        <FooterMobile />
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important
  },
  introductionNetworks: {
    borderBottom: "1px solid #00AEEF",
    paddingBottom: "7%",
  },
  gatewayLandingMain: {
    textAlign: "center",
  },
  gatewayTitleLanding: {
    color: "#00AEEF",
    fontFamily: "Fira sans",
    fontWeight: "500",
    fontSize: "3.33vh",
  },

  gatewayLandingButton: {
    color: "#FFF",
    textDecoration: "none",
    backgroundColor: "#00AEEF",
    border: "solid 1px #00AEEF",

    fontWeight: "600",
    fontSize: "2.22vh",
    transition: ".4s background-color",

    "&:hover": {
      color: "#00AEEF",
      backgroundColor: "#FFF",
    },
  },
  titleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "5vw",
    fontWeight: "450",
    width: "100%",

    textAlign: "center",
    marginBottom: "5%",
    "& h3": {
      fontWeight: "500",
    },
  },
  descriptionSection: {
    fontFamily: "Fira sans", //!important,
    fontSize: "4vw",
    color: "#333",
    "& p": {
      marginBlockStart: "0",
      marginBlockEnd: "0",
    },
    "& a": {
      color: "#00AEEF",
      fontWeight: "500",
    },
    "& span": {
      fontWeight: "500",
    },
  },

  titleNetwork: {
    color: "#00AEEF",
    fontSize: "2.80vh",
    fontWeight: "450",
    width: "62%",
    margin: "4.8% auto",
    textAlign: "center",
    marginBottom: "5%",
  },
  subtitleSection: {
    color: "#00AEEF",
    fontFamily: "Fira sans", //!important,
    fontSize: "4.5vw",
    fontWeight: "450",
    textAlign: "center",
  },

  useCases: {
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  gateways: {
    "& img": {
      width: "30%",
    },
    "& h4": {
      fontSize: "2.78vh",
      fontWeight: "450",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      marginBlockEnd: "0",
    },
  },
  descriptionGateway: {
    display: "flex",
    flexDirection: "row",
  },

  networkSectionGrid: {
    "& center": {
      marginTop: "5%",
    },
    "& img": {
      width: "50vw",
    },
    width: "100%",
    marginBottom: "20%",
    marginTop: "5%",
  },
  swisscomLogo: {
    "& img": {
      width: "8vw",
    },
  },
  operator: {
    marginTop: "5%",
    "& img": {
      width: "34%",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
    },
  },
  coverage: {
    "& a": {
      color: "#333",
      textDecoration: "none",
      wordBreak: "break-all",
      fontWeight: "100",
    },
  },
  contactSupport: {
    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "2.222vh",
      textAlign: "center",
      transition: "opacity .15s ease-in-out",
      opacity: "100",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      marginRight: "5%",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      marginTop: "2%",
      padding: ".5% .6%",
      "&:hover": {
        color: "#fff",
        border: "1px solid #00aeef",
        transition: "opacity .15s ease-in-out",
        opacity: ".8",
      },
    },
  },
  logoDiv: {
    margin: "auto",
    "& img": {
      maxWidth: "25vw",
    },
  },
  rowBorder: {
    borderBottom: "1px solid #00AEEF",
    gridColumn: "1 / 3",
  },
}));
