

export default [
  {
    title: "What regions is this service available in? ",
    content: "This service can be currently used in US915, EU868, AS923 and AU915. Please refer to the Networks page for details on which regions are covered by each of the supported public networks"
  },
  {
    title: "How can I create my own application?",
    content: "Once you have evaluated the platform using the LoRa Cloud - Locator application and validated the low-power and accuracy capabilities of LoRa Edge, you are welcome to create your very own application for your use case. Please go to [article] in which we walk you through all the steps required to build a solution around LoRa Edge. Link to blog: https://tech-journal.semtech.com/buy-versus-build-options-for-lora-cloud-geolocation-integrations?hs_preview=fgEyHYPM-72286544822"
  },
  {
    title: "Does the LoRa Cloud Locator application support adding my own network server?",
    content: "No (LoRa Cloud Services do though) but please contact us [link to support] if you are interested in support for an specific LNS Please note that you can setup your own private network for using LoRa Cloud - Locator"
  },
  {
    title: "Can I add my own tracker devices?",
    content: "Yes. To enable this service, you need a LoRa Edge-enabled tracker. If you have a new LoRa Edge-enabled tracker you would like to integrate with our application, please contact us [link to support] "
  },
  {
    title: "Are there any costs of signing up and using the service?",
    content: "LoRa Cloud Locator is a free service. The only investment you make is the purchasing of trackers and, in case you need to set up a private network, a gateway. You can find an offer of trackers that are fully compatible with our service here [link to tracker site]. You can register up to 5 trackers in your LoRa Cloud Locator account."
  },
  {
    title: "What is the battery life of my device using LoRa Edge?",
    content: "LoRa Edge is an ultra low-power platform with a cloud-based infrastructrue. This enables more comprehensive and cost-effective tracking capabilities, guaranteed indoor/outdoor coverage and device battery life of up to 10 years."
  },
  {
    title: "What network servers is this service compatible with?",
    content: "We support multiple public networks (Everynet, Netmore, Helium, Orange and TTN) as well as private networks using AWS IoT Core for LoRaWAN We are also currently evaluating other public networks to support"
  },
  {
    title: "How to interpret the location accuracy reported in the application?",
    content: "The accuracy value displayed in LoRa Cloud - Locator depends on the specific tracker (whether it is based on LR1110 or not) and the geolocation mechanism being used to capture each position. For Wi-Fi geo-positioning, it is derived from the Wi-Fi access points that are visible for the tracker and the accuracy is calculated with a 50% confidence level (meaning 50% of the data points will have at least the accuracy being reported). In general, the accuracy of the Wi Fi positioning will depend on the density of access points visible for the tracker. For GNSS geo-positioning, it is derived from the satellite signals and assistance parameters (such as the initial position of the device and the current time) and in this case, the accuracy is calculated with a 90% confidence level (meaning 90% of the data points will have at least the accuracy being reported). In general, the accuracy of the GNSS geo-positioning will be dependent of the usage conditions (open sky vs limited sky view as found in a urban environment).In the specific case of Browan Industrial Tracker, the accuracy comes from its on-chip GNSS and that is reported on the chipset datasheet as CEP (circular error probable) in meters. This means that 50% of the points are expected to be the provided value. "
  },
  {
    title: "How to interpret the battery level reported in the application?",
    content: "The battery level value displayed in the Locator is an estimated value derived from the power consumption reported by the LR1110 chip that is included in the trackers. Specifically, an heuristic is applied to the computed power value of the LR1110 to estimate the overall device’s consumption; that will account for the rest of the device’s components such as the main MCU, LEDs and supporting sensors."
  },
  {
    title: "Can I add my own gateways?",
    content: "Yes. LoRa Cloud Locator supports private networks and bringing your own gateways. Since Locator uses AWS IoT Core for LoRaWAN for setting up your private network, please make sure you use of the [qualified gateways] for the service. Link to qualified gateways: https://devices.amazonaws.com/search?page=1&sv=iotclorawan&type=gateway"
  },
];
