import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const TitleBanner = ({ title }) => {
  const css = useStyle();

  return (
    <div className={css.contentTitle}>
      <div className={css.useCasesText}>{title}</div>
    </div>
  );
};

const useStyle = makeStyles(() => ({
  contentTitle: {
    position: " relative",
    maxWidth: "100%",
    height: "13vh",
    margin: "0 auto",
  },
  titleUseCases: {
    margin: "0 auto",
    textAlign: "right",
    "& img": {
      margin: "0 auto",
      maxWidth: "100%",
      height: "auto",
    },
  },

  useCasesText: {
    color: "#00AEEF",
    fontWeight: "400",
    fontSize: "4.8vh",
    backgroundColor: "#ffffff",
    padding: "2.5vh",
    paddingLeft: "10.19%",
    fontFamily: "Fira Sans",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    position: "absolute",
    boxShadow: "0px 3px 6px #00000029",
    top: "0%",
    paddingRight: "2%",
    flexWrap: "nowrap",
    flexDirection: "row",
    display: "inline-block",
    minWidth: "5em",

    "& img": {
      width: "3em" /* !important */,
    },
  },

  howToGetStartedText: {
    color: "#00AEEF",
    fontWeight: "400",
    fontSize: "4.8vh",
    backgroundColor: "#ffffff",
    padding: "2.5vh",
    fontFamily: "Fira Sans",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    position: "absolute",
    boxShadow: "0px 3px 6px #00000029",
    top: "0%",
    textAlign: "right",
    paddingRight: "2%",
    flexWrap: "nowrap",
    flexDirection: "row",
    display: "inline-block",
    minWidth: "5em",

    "& img": {
      width: "3em" /* !important */,
    },
  },

  titleText: {
    margin: "0",
    marginTop: "1%",
    color: "#00AEEF",
    fontSize: "2.2rem",
    fontFamily: '"Fira Sans"',
    fontWeight: "normal",
  },
}));

export default TitleBanner;
