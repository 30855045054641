import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SiteSubLinkButton from "./SiteSubLinkButton";

export default function SiteSubLinks({ links, className }) {
  const css = useStyle();

  return (
    <div className={`${className} ${css.submenu}`}>
      {links.map((link, index) => (
        <SiteSubLinkButton
          key={index}
          target={link.target}
          data-active={link.isActive}
          href={link.href}
          disableElevation
        >
          {link.name}
        </SiteSubLinkButton>
      ))}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  submenu: {
    padding: '11px',
    position: "absolute",
    top: "100%",
    display: "none",
    flexDirection: "column",
    fontFamily: "'Fira sans'",
    backgroundColor: "white",
    fontSize: "1.3125rem",
    border: "1px solid #d3d3d3 ",
    zIndex: "1",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
}));
