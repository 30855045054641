import React, { useEffect } from "react";

import BreadcrumbsBar from "../common/BreadcrumbsBar";
import Header from "../header/Header";
import Footer from "components/landing_page/footer/Footer";
import TitleBanner from "../common/TitleBanner";
import { makeStyles } from "@material-ui/core";
import useWindowDimensions from "components/useWindowDimensions";
import { locatorImages } from "components/img-content/img-content";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "HELP", "GETTING STARTED"];
export default function HowToGetStarted() {
  const css = useStyle();
  useEffect(() => {
    document.title =
      "Help - Getting Started | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width <= 900) {
      history.push("/GetStartedMobile");
    }
  }, [width, history]);

  return (
    <div className="page">
      <Header userData={"false"} />
      <TitleBanner title="Getting Started"/>
      <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />

      <div className={css.landingPage}>
        <div className={css.introText}>
          <h3 style={{ color: "#333" }}>Get Started with Your Tracker</h3>
        </div>

        <div className={css.sectionGrid}>
          <div className={css.operator}>
            <div>
              <img
                className={css.logoRow1}
                src={locatorImages.trackersPageImg[14].url}
                alt="logoSemtech"
              />
            </div>
            <h3>LoRa Edge™ Tracker Reference Design, LR1110</h3>
            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG+-+Reference+Tracker.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>

          <div className={css.operator}>
            <div>
              <img
                className={css.logoRow1}
                src={locatorImages.trackersPageImg[14].url}
                alt="semtechLogo"
              />
            </div>
            <h3>LoRa Edge Development Kit, LR1110</h3>

            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG - DevKit.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>
          <div className={css.operator}>
            <div>
              <img
                className={css.logoRow2}
                src={locatorImages.trackersPageImg[15].url}
                alt="digitalMatterLogo"
              />
            </div>
            <h3>Yabby Edge for LoRaWAN®</h3>

            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG - Yabby Edge for LoRaWAN.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>
          <div className={css.operator}>
            <div>
              <img
                className={css.logoRow2}
                src={locatorImages.trackersPageImg[15].url}
                alt="digitalMatterLogo"
              />
            </div>
            <h3>Yabby Edge Cellular</h3>
            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG - Yabby Edge Cellular.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>
          <div className={css.operator}>
            <div>
              <img className={css.logoRow3} src={locatorImages.trackersPageImg[17].url} alt="browanLogo" />
            </div>
            <h3>Industrial Tracker</h3>

            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG - Browan Industrial Tracker.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>
          <div className={css.operator}>
            <div>
              <img
                className={css.logoRow3}
                src={locatorImages.trackersPageImg[16].url}
                alt="miromicoLogo"
              />
            </div>
            <h3>miro TrackIt</h3>

            <a
              style={{
                color: "#00AEEF",
                fontWeight: "500",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="https://public-locator.loracloud.com/production/docs/GSG - miro TrackIt.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download getting started guide
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important

    "& h5": {
      fontSize: " 2.66vh",
      fontWeight: "450",
      color: "#00AEEF",
      textAlign: "center",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
      margin: "0.5%",
    },
  },

  introText: {
    textAlign: "center",
    color: "#333",
    "& h3": {
      fontWeight: "500",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      fontSize: "2.25rem",
      width: "62%",
      margin: "4.8% auto",
      textAlign: "center",
      marginBottom: "5%",
    },

    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "1.3125rem",
      textAlign: "center",
      transition: ".4s background-color",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      padding: ".5% .6%",
      "&:hover": {
        color: "#00aeef",
        backgroundColor: "#ffffff",
        border: "1px solid #00aeef",
      },
    },
  },
  operator: {
    borderBottom: "solid 1px #00AEEF",
    paddingBottom: "3%",
  },
  sectionGrid: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "47.5% 47.5%",
    width: "100%",
    margin: "0 auto",

    rowGap: "2vh",
    columnGap: "5%",
    marginTop: "10%",
    marginBottom: "20%",

    "& div": {
      textAlign: "center",
      minWidth: "30vw",
    },

    "& h3": {
      fontWeight: "550",
      color: "#333",
      fontFamily: "Fira sans",
      fontSize: "24px",
      marginTop: "1%",
      marginBottom: "5%",
    },

    "& a": {
      color: "#00AEEF",
      textDecoration: "underline",
      fontSize: "1.3125rem" /*!important*/,
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
    },

    "& img": {},
  },

  descriptionSection: {
    fontSize: "1.3125rem",
    color: "#333",
  },
  logoRow1: {
    maxWidth: "15.255vw",
  },
  logoRow2: {
    maxWidth: "17vw",
  },
  logoRow3: {
    maxHeight: "17vh",
  },
}));
