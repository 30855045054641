import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SiteSubLinks from "./SiteSubLinks";
import SiteLinkButton from "./SiteLinkButton";
import useNavLinkList from "./useNavLinkList";
import LinkButtonWithSub from "./LinkButtonWithSub";

export default function SiteLinks(theme) {
  const css = useStyle();
  const navLinks = useNavLinkList();

  return (
    <div className={css.siteLinks}>
      {navLinks.map((link, index) => (
        <div key={index} className={css.linkContainer}>
          {link.sublinks ? (
            <LinkButtonWithSub
              href={link.href}
              data-active={link.isActive}
              disableElevation
            >
              {link.name.toUpperCase()}
            </LinkButtonWithSub>
          ) : (
            <SiteLinkButton
              href={link.href}
              data-active={link.isActive}
              disableElevation
            >
              {link.name.toUpperCase()}
            </SiteLinkButton>
          )}

          {link.sublinks && (
            <SiteSubLinks
              target={link.target}
              links={link.sublinks}
              hookClass={css.linkContainer}
              className={css.sublinks}
            />
          )}
        </div>
      ))}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  siteLinks: {
    color: "#00AEEF !important",
    fontFamily: '"Fira Sans"',
    display: "flex",
    alignItems: "center",
    fontSize: "1.3125rem !important",
    height: "130%",
  },
  linkContainer: {
    height: "12.1vh",
    position: "relative",
    alignItems: "center",
    display: "flex",
    padding: "5% , 5%",

    "&:hover $sublinks": { display: "block" },
  },
  sublinks: {
    display: "none",
   
  },
}));
