import React ,{useState, useEffect} from "react";
import Header from 'components/landing_page/header/Header';
import WhatsNew from './whatsNew/WhatsNew';
import PrincipalContent  from '../home/principal/PrincipalContent';
import Footer from 'components/landing_page/footer/Footer';
import FooterMobile from 'components/landing_page_mobile/footerMobile.jsx';
import './main.css'
import HeaderMobile from  'components/landing_page_mobile/headerMobile.jsx';
import WhatsNewMobile from 'components/landing_page_mobile/whatsNewMobile.jsx';
import PrincipalContentMobile from  'components/landing_page_mobile/principalMobile.jsx';
//import mobile from "is-mobile";
import useWindowDimensions from "components/useWindowDimensions"; 

//import WelcomeSection from '../landing-page-mobile_deprecated/welcomeSection';
//import FooterMobile from  '../landing-page-mobile_deprecated/footerMobile';



const Main = (user) => {

  const [isMobile, setisMobile] = useState(true);
  const { width } = useWindowDimensions();


  useEffect(() => {
    if(width <= 900){
      setisMobile(true);
    }else{
      setisMobile(false);
    }
    },[width])

  


  return (
    <div>
      { !isMobile ? ( 
        <div  style={{display: 'flex',justifyContent: 'center'}}>
              <div className='landing-page'id="landing-page">
              <Header/>
                <div id="content">
                  <WhatsNew/>
                  <PrincipalContent />
                </div>
              <Footer/>
              </div>
        </div>
      ):
      ( 
        <div  style={{display: 'flex',justifyContent: 'center'}}>
        <div className='landing-page'id="landing-page">
        <HeaderMobile/>
          <div id="content">
            <WhatsNewMobile/>
            <PrincipalContentMobile />
          </div>
        <FooterMobile/>
        </div>
        </div>
        )}
    </div>
  );
}

export default Main;
