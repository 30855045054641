import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./whatsNew.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import API from "services/axiosCall";
import ReactMarkdown from "react-markdown";
import {getDateDayMonthYear} from "utils/helperFunctions";
import { locatorImages } from "components/img-content/img-content";
import CompleteRegistration from "components/registration/CompleteRegistration";

const WhatsNew = () => {
  const { handle } = useParams();
  const [isLoadingNotices,setLoading] = useState(true);
  const [markdownShown, setmarkdownShown] = useState("");
  const isMobile = false;

  const getNews = async  () => {
    let allNews  = [];
    await API.getNews().then((response) => {
      let responseSplitted= [];
        response.map(async  (notice) => {
          notice = notice.substring(notice.search("/") + 1);
          await API.getNew(notice).then((response2) => {  
            responseSplitted=response2[0].split("\n");       
            allNews.push(responseSplitted); 
          })
          orderNews(allNews);
        })
    })
  
  }
  const orderNews =  (allNews) => { 
    allNews.sort( function(a, b) {
      return  ( getDateDayMonthYear(b[1].substring(4,14))   - getDateDayMonthYear(a[1].substring(4,14)))
    }) 
    let newsConcated="";
    let limit=0;
    // eslint-disable-next-line array-callback-return
    const requests = allNews.map((notice) => {
      if(limit>=3)
      {return "";}
      limit++;
      newsConcated += notice[0] + "\n";
    })
    return Promise.all(requests).then(() => {
    setmarkdownShown(newsConcated);
    });
    
  }


  useEffect(() => {
    getNews();
    setLoading(false)
    // eslint-disable-next-line
  }, [handle]);


  return (
    <div>
      {!isMobile ? (
        <section style={{ maxWidth: "98.74vw", overflow: "hidden" }}>
          <div className="imgContainerLandingPageMain">
            <img alt={"home"} className="img-home" src={locatorImages.homePageImg[0].url} />

            <div className="MessagesContainer">
              <div className="text-welcome-semtech">
                <span className="message-welcome">
                  <h3>Welcome to LoRa Cloud™ Locator </h3>
                  <p>
                    The easiest way to experience first hand the accuracy and
                    power consumption of LoRa Edge™ for asset tracking in any
                    IoT vertical!
                  </p>
                </span>
              </div>

              {isLoadingNotices ? (
                <div></div>
              ) : (
                <div className="whatsNewBox">
                  <h3>What's New?</h3>
                  <ReactMarkdown >{markdownShown}</ReactMarkdown>
                  <center>
                    <a href="/whatsNew">
                      SEE MORE <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </center>
                </div>
              )}
            </div>
          </div>
          <CompleteRegistration />
        </section>
      ) : (
       <div> </div>
      )}
    </div>
  );
};

export default WhatsNew;
