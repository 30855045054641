import { IMAGES_BUCKET_URL,IMAGES_BUCKET_ENVIRONMENT } from "environment"

export const locatorImages = {
    headerImg: [
        {
            name:'Locator-Logo-Header',
            url:IMAGES_BUCKET_URL+IMAGES_BUCKET_ENVIRONMENT+'src-images/NewLogoLocator.png'
        },
        {
            name:'Log-icon-Header',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/loggedIcon.PNG'
        },
        {
            name:'LogOut-icon-Header',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/LogoutMobile.png'
        },
    ],
    footerImg:[
        {
            name:'Semtech-Logo-Footer',
            url:IMAGES_BUCKET_URL +  IMAGES_BUCKET_ENVIRONMENT + 'src-images/Semtech_Logo.png'
        },
        {
            name:'Semtech-LogoMobile-Footer',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/Semtech_inline_logo.png'
        },
    ],
    homePageImg:[
        {
            name:'banner-home-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/principalHeader1920.png'
        },
        {
            name:'battery-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/batteryIcon.png'
        },
        {
            name:'anthena-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/AnthenaIcon.png'
        },
        {
            name:'chip-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/ChipIcon.png'
        },
        {
            name:'vermont-customer-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/vermont-logo.png'
        },
        {
            name:'infisense-customer-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/tcloud.PNG'
        },
        {
            name:'galileo-customer-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/Galileo-Logo_new.png'
        },
        {
            name:'indutrax-customer-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/indutrax_logo.png'
        },
        {
            name:'first-testimonial-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/first-testimonial-img.png'
        },
        {
            name:'first-testimonial-profile-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/first-testimonial.png'
        },
        {
            name:'double-quotes-img',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/double-quotes-icon.png'
        },
        {
            name:'arrows-carrousel-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/arrows.png'
        },
        {
            name:'banner-mobile-img',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/mainMobileLandingPage.png'
        },
    ],    
    networksPageImg: [
        {
            name:'Everynet-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/everynet-logox3.jpg'
        },
        {
            name:'Helium-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/helium-logox2.png'
        },
        {
            name:'Orange-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/Master_Logo_RGB.svg'
        },
      
        {
            name:'NetMore-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/netmore_logo.jpg'
        },
        {
            name:'Swisscom-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/SwisscomLogo.svg'
        },
        {
            name:'TTI-Logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/TTI-logox2.png'
        },
    ],
    useCasePageImg: [
        {
            name:'Use-case-supplyChainImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/supplyChainImg.png'
        },
        {
            name:'Use-case-agricultureImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/agricultureImg.png'
        },
        {
            name:'Use-case-smartCitiesImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/smartCitiesImg.png'
        },
        {
            name:'Use-case-retailImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/retailImg.png'
        },
        {
            name:'Use-case-healthCareImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/healthCareImg.png'
        },
        {
            name:'Use-case-utilitiesImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/utilitiesImg.jpg'
        },
        {
            name:'Use-case-industrialImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/industrialImg.png'
        },
        {   
            name:'Use-case-buildingImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/buildingImg.png'
        },
        {   
            name:'Use-case-bikeRackImg',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/Bike-rack.jpg'
        },
    ],
    trackersPageImg: [
        {   
            name:'Trackers-semtechM1',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/semtechM1.png'
        },
        {   
            name:'Trackers-semtechM2',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/semtechM2.png'
        },
        {   
            name:'Trackers-digitalMatter1',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digitalmatter1.png'
        },
        {   
            name:'Trackers-digitalMatter2',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digitalmatter2.png'
        },
        {   
            name:'Trackers-digitalMatter3',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digitalmatter3.png'
        },
        {   
            name:'Trackers-miromico1',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromico1.png'
        },
        {   
            name:'Trackers-miromico2',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromico2.png'
        },
        {   
            name:'Trackers-miromico3',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromico3.png'
        },
        {   
            name:'Trackers-miromicoEdge1',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromicoEdge1.png'
        },
        {   
            name:'Trackers-miromicoEdge2',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromicoEdge2.png'
        },
        {   
            name:'Trackers-miromicoEdge3',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/miromicoEdge3.png'
        },
        {   
            name:'Trackers-browan1',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/browan1.png'
        },
        {   
            name:'Trackers-browan2',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/browan2.png'
        },
        {   
            name:'Trackers-browan3',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/browan3.png'
        },
        {   
            name:'semtech-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/semtech.png'
        },
        {   
            name:'digital-matter-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digital-matter.png'
        },
        {   
            name:'digital-miromico-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/logo-miromico.png'
        },
        {   
            name:'digital-browan-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/logo-browan.png'
        },
        {   
            name:'calchip-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/Calchip-image.png'
        },
        {   
            name:'indesMatech-logo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/indesMatch.png'
        },
    ],
    whatsNewPage:[
        {   
            name:'whatsNew-loadingGif',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/loading.gif'
        },
    ],
    supportPage:[
        {   
            name:'support-email-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/support-email-image.png'
        },
        {   
            name:'support-email-icon',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/support-email-icon.png'
        },
        {   
            name:'support-help-icon',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/support-help-icon.png'
        },
    ],
    menuDocument:[
        {   
            name:'box-image',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/box-icon.png'
        },
        {   
            name:'logo-documentation',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/documentation-title.png'
        },
        {   
            name:'icon-title',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/title-documentation-icon.png'
        },
        {   
            name:'pointer-img',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/pointer.png'
        },
        {   
            name:'truck-img',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/truck.png'
        },
        {   
            name:'download-icon',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/download-icon.png'
        },
    ],
       referencePages:[
          {   
            name:'truvamiLogo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/truvamiLogo.png'
        },
         {   
            name:'truvamiMap',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/truvamiMap.PNG'
        },
           {   
            name:'everynetMap',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/everynetMap.PNG'
        },
           {   
            name:'browanLogo',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/browanLogo.png'
        },
           {   
            name:'browanMap',
            url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/browanMap.PNG'
        },
        {
             name:'digitalmatterlogo',
              url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digital-matter.png'
        },
         {
             name:'digitalmatterMap',
              url:IMAGES_BUCKET_URL + IMAGES_BUCKET_ENVIRONMENT + 'src-images/digitalMatterMap.PNG'
        }
        
    ],
}