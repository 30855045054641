
import { LORA_ATK } from "apis/_NAMES.js";
import {
  AWS_REGION,
  ATK_API_URL
} from "./environment"


export default {
  Auth: {
    mandatorySignIn: true,
    region: AWS_REGION,
  },
  API: {
    endpoints: [
      {
        name: LORA_ATK,
        endpoint: ATK_API_URL,
      },
    ],
  },
};
