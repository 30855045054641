import React from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../carrousel/carrousel.css";
import {useSelector } from "react-redux";
import { locatorImages } from "components/img-content/img-content";


const CarouselDocs = () => {

    const docs = useSelector((state) => state.documents.docs);
   
    return(
      
                <Carousel className="main-slide" 
                showArrows={true} 
                infiniteLoop={true} 
                interval={4000}
                //autoPlay
                showStatus={false} 
                showIndicators={false} 
                swipeScrollTolerance={5}
                showThumbs={false} 
                centerMode={true} 
                centerSlidePercentage={50}
                renderArrowPrev={(clickHandler,hasPrev, labelPrev) => 
                hasPrev && (
                <button type="button" className="buttonPrev" onClick={clickHandler} style={{ top:"45%", right:"96%"}}>
                      <img alt={'arrow'}  className="doubleArrowPrev" src={locatorImages.homePageImg[10].url} style={{width: "2.5vh" }}/>
                       
                </button>
                )
                }
                renderArrowNext={(clickHandler,hasNext, labelNext) => hasNext && (
                    <button type="button" className="buttonNext" onClick={clickHandler} style={{top:"45%" , left:"96%"}}>
                          <img alt={'arrow'}  className="doubleArrowNext" src={locatorImages.homePageImg[10].url} style={{width: "2.5vh" }}/>
                     
                    </button>
                )}>

                    {docs.map( (element, index)  => (  
                    <div className="document-carousel" key="{index}">
             
                        <div className="title-carousel"  >{element["title"]} </div>
                        <div className="content-carousel"> <p > {element["section1"]}</p></div>
                        <div className="button-content"><a className="button-carousel" href={'/docs/'+ index }>read more</a></div>
                 
                    </div>
                    ))}

                </Carousel>
    )
    
}

export default CarouselDocs;