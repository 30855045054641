import React from 'react';
import MenuDocument from 'components/landing_page/documentation/menu/menuDocument';
import TitleDocument from '../title/title';
import "../template/Documentation.css";
import Header from 'components/landing_page/header/Header';


const Documentation = () => {
    return (
        <section >
                <Header />
            <div className='documentation-page'>
                 <TitleDocument />
                <div className='content'>
                    <MenuDocument />
                </div>
            
            </div>
        </section>
    );
}

export default Documentation;

