import React, { useEffect } from "react";

import BreadcrumbsBar from "../landing_page/common/BreadcrumbsBar";
import FooterMobile from "./footerMobile";
import HeaderMobile from "./headerMobile";
import { locatorImages } from "components/img-content/img-content";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

const breadcrumbs_path = [["HOME", "/landing"], "HELP", "GETTING STARTED"];
export default function HowToGetStartedMobile() {
  const css = useStyle();
  useEffect(() => {
    document.title =
      "Help - Getting Started | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/help/gettingStarted");
    }
  }, [width, history]);

  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <div className="page">
        <h3 className="mainHeaderPages">Getting Started</h3>
        <div className={css.landingPage}>
          <div className={css.sectionGrid}>
            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow1}
                    src={locatorImages.trackersPageImg[14].url}
                    alt="semtechLogo"
                  />
                </center>
              </div>
              <h3>LoRa Edge™ Tracker Reference Design, LR1110</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG+-+Reference+Tracker.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>

            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow1}
                    src={locatorImages.trackersPageImg[14].url}
                    alt="semtechLogo"
                  />
                </center>
              </div>
              <h3>LoRa Edge Development Kit, LR1110</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG%20-%20DevKit.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>
            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow2}
                    src={locatorImages.trackersPageImg[15].url}
                    alt="digitalMatterLogo"
                  />
                </center>
              </div>
              <h3>Yabby Edge for LoRaWAN®</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG%20-%20Yabby%20Edge%20for%20LoRaWAN.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>
            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow2}
                    src={locatorImages.trackersPageImg[15].url}
                    alt="digitalMatterLogo"
                  />
                </center>
              </div>
              <h3>Yabby Edge Cellular</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG%20-%20Yabby%20Edge%20Cellular.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>
            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow3}
                    src={locatorImages.trackersPageImg[17].url}
                    alt="browanLogo"
                  />
                </center>
              </div>
              <h3>Industrial Tracker</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG%20-%20Browan%20Industrial%20Tracker.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>
            <div className={css.operator}>
              <div>
                <center>
                  {" "}
                  <img
                    className={css.logoRow3}
                    src={locatorImages.trackersPageImg[16].url}
                    alt="miromicoLogo"
                  />
                </center>
              </div>
              <h3>miro TrackIt</h3>
              <div className={css.descriptionSection}></div>
              <br />
              <a
                href="https://public-locator.loracloud.com/production/docs/GSG%20-%20miro%20TrackIt.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download getting started guide
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterMobile />
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  operator: {
    borderBottom: "solid 1px #00AEEF",
    paddingBottom: "10%",
  },
  landingPage: {
    paddingLeft: "13.92%",
    paddingRight: "13.92%",
    fontFamily: "Fira sans", //!important
    textAlign: "center",

    "& h5": {
      fontSize: " 2.66vh",
      fontWeight: "450",
      color: "#00AEEF",
      textAlign: "center",
    },
    "& a": {
      color: "#00AEEF",
      textDecoration: "none",
      margin: "0.5%",
    },
  },

  introText: {
    textAlign: "center",
    color: "#333",
    "& h3": {
      fontWeight: "500",
      color: "#00AEEF",
      fontFamily: "Fira sans", //!important,
      fontSize: "3.33vh",
      width: "62%",
      margin: "4.8% auto",
      textAlign: "center",
      marginBottom: "5%",
    },

    "& button": {
      color: "#FFF",
      border: "solid 1px #00AEEF",
      fontSize: "2.222vh",
      textAlign: "center",
      transition: ".4s background-color",
      fontFamily: "Fira sans",
      fontWeight: "lighter",
      textDecoration: "none",
      backgroundColor: "#00AEEF",
      cursor: "pointer",
      padding: ".5% .6%",
      "&:hover": {
        color: "#00aeef",
        backgroundColor: "#ffffff",
        border: "1px solid #00aeef",
      },
    },
  },
  sectionGrid: {
    display: "grid",
    width: "100%",
    margin: "0 auto",
    rowGap: "3%",
    columnGap: "5%",
    marginTop: "15%",
    marginBottom: "110%",

    "& h3": {
      fontWeight: "550",
      color: "#333",
      textAlign: "center",
      fontFamily: "Fira sans",
      fontSize: "5vw",
      marginTop: "1%",
      marginBottom: "5%",
    },

    "& a": {
      color: "#00AEEF",
      textDecoration: "underline",
      fontSize: "3.8vw",
      fontWeight: 500,
      marginTop: 0,
      marginBottom: 0,
      textAlign: "center",
    },

    "& img": {},
  },

  descriptionSection: {
    fontSize: "4vw",
    color: "#333",
  },
  logoRow1: {
    width: "45vw",
    textAlign: "center",
  },
  logoRow2: {
    width: "45vw",
  },
  logoRow3: {
    width: "45vw",
  },
}));
