export const LandingPageAuthLinks=
{
    LoginButton:"LOGIN",
    SignUpButton:"SIGN UP"
}
export const LandingPageAuthLinks2=
{
    LoginButton:"LOGIN 2",
    SignUpButton:"SIGN UP"
}
