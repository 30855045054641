import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Accordion from "./Accordion";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

export default ({ faqList }) => {
  const [expanded, setExpanded] = useState(0);

  const css = useStyle();

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded(0);
  }, [faqList]);

  return (
    <div className={css.questionsAccordion}>
      {/*faqList.map(({ title, content }, index) => (
        <Accordion
          key={index}
          square
          expanded={expanded === index}
          onChange={handleAccordionChange(index)}
        >
          <AccordionSummary
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography className={ isMobile ? css.questionTitleMobile :css.questionTitle} >{title}</Typography>
            {expanded === index ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} className={ isMobile && css.arrow }/>
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} className={ isMobile &&css.arrow } />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={ isMobile ? css.contentMobile :css.content}>{content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))*/}




          <Accordion
          key={1}
          square
          expanded={expanded === 1}
          onChange={handleAccordionChange(1)}>
          {/*Question 1*/}
          <AccordionSummary
            aria-controls={`panel1-content`}
            id={`panel1-header`}
          >
            <Typography className={css.questionTitle}>What regions is this service available in? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>This service can be currently used in US915, 
            EU868, AS923 and AU915. Please refer to the&nbsp;
            <a  style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="/networks">Networks page</a> for details on which regions are covered by each of the supported public networks</Typography>
          </AccordionDetails>
        </Accordion>





        <Accordion
          key={2}
          square
          expanded={expanded === 2}
          onChange={handleAccordionChange(2)}>
          {/*Question 2*/}
          <AccordionSummary
            aria-controls={`panel2-content`}
            id={`panel2-header`}
          >
            <Typography className={css.questionTitle}>How can I create my own application?</Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            Once you have evaluated the platform using the LoRa Cloud™ - Locator application and validated the low-power and accuracy capabilities of LoRa Edge™, you are welcome to create your very own application for your use case.<br/><br/> 

Please go to <a  style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="https://tech-journal.semtech.com/buy-versus-build-options-for-lora-cloud-geolocation-integrations?hs_preview=fgEyHYPM-72286544822" target="_blank" rel="noopener noreferrer"> article</a> in which we walk you through all the steps required to build a solution around LoRa Edge. 

            </Typography>
          </AccordionDetails>
        </Accordion>





        <Accordion
          key={3}
          square
          expanded={expanded === 3}
          onChange={handleAccordionChange(3)}>
          {/*Question 3*/}
          <AccordionSummary
            aria-controls={`panel3-content`}
            id={`panel3-header`}
          >
            <Typography className={css.questionTitle}>Does the LoRa Cloud™ Locator application support adding my own network server? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            No (LoRa Cloud™ Services do though) but please <a  style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="https://semtech.force.com/ldp/ldp_support"target="_blank" rel="noopener noreferrer"> contact us </a>if you are interested in support for an specific LNS
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion
          key={4}
          square
          expanded={expanded === 4}
          onChange={handleAccordionChange(4)}>
          {/*Question 4*/}
          <AccordionSummary
            aria-controls={`panel4-content`}
            id={`panel4-header`}
          >
            <Typography className={css.questionTitle}>Can I add my own tracker devices? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            Yes. To enable this service, you need a LoRa Edge™-enabled tracker.
If you have a new LoRa Edge-enabled tracker you would like to integrate with our application, please&nbsp;<a style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="https://semtech.force.com/ldp/ldp_support"target="_blank" rel="noopener noreferrer">contact us</a>
            </Typography>
          </AccordionDetails>
        </Accordion>




        <Accordion
          key={5}
          square
          expanded={expanded === 5}
          onChange={handleAccordionChange(5)}>
          {/*Question 5*/}
          <AccordionSummary
            aria-controls={`panel5-content`}
            id={`panel5-header`}
          >
            <Typography className={css.questionTitle}>Are there any costs of signing up and using the service? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            LoRa Cloud™ Locator is a free service. The only investment you make is the purchasing of trackers and, 
            in case you need to set up a private network, a gateway. 
            You can find an offer of trackers that are fully compatible with our service <a style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="/trackers">here</a>. 
            You can register up to 5 trackers in your LoRa Cloud Locator account.
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion
          key={6}
          square
          expanded={expanded === 6}
          onChange={handleAccordionChange(6)}>
          {/*Question 6*/}
          <AccordionSummary
            aria-controls={`panel6-content`}
            id={`panel6-header`}
          >
            <Typography className={css.questionTitle}>What is the battery life of my device using LoRa Edge™?</Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            LoRa Edge™ is an ultra low-power platform with a cloud-based infrastructrue. 
            This enables more comprehensive and cost-effective tracking capabilities, 
            guaranteed indoor/outdoor coverage and device battery life of up to 10 years.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion
          key={7}
          square
          expanded={expanded === 7}
          onChange={handleAccordionChange(7)}>
          {/*Question 7*/}
          <AccordionSummary
            aria-controls={`panel7-content`}
            id={`panel7-header`}
          >
            <Typography className={css.questionTitle}>What network servers is this service compatible with? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            We support multiple public networks (Everynet, Netmore, Helium, Orange and TTN) as well as private networks using AWS IoT Core for LoRaWAN®
            <br/><br/>
            We are also currently evaluating other public networks to support  
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion
          key={8}
          square
          expanded={expanded === 8}
          onChange={handleAccordionChange(8)}>
          {/*Question 8*/}
          <AccordionSummary
            aria-controls={`panel8-content`}
            id={`panel8-header`}
          >
            <Typography className={css.questionTitle}>How to interpret the location accuracy reported in the application? </Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            The accuracy value displayed in LoRa Cloud™ - Locator depends on the specific tracker (whether it is based on LR1110 or not) and the geolocation mechanism being used to capture each position. <br/><br/>

For Wi-Fi geo-positioning, it is derived from the Wi-Fi access points that are visible for the tracker and the accuracy is calculated with a 50% confidence level (meaning 50% of the data points will have at least the accuracy being reported). In general, the accuracy of the Wi Fi positioning will depend on the density of access points visible for the tracker. <br/><br/>

For GNSS geo-positioning, it is derived from the satellite signals and assistance parameters (such as the initial position of the device and the current time) and in this case, the accuracy is calculated with a 90% confidence level (meaning 90% of the data points will have at least the accuracy being reported). In general, the accuracy of the GNSS geo-positioning will be dependent of the usage conditions (open sky vs limited sky view as found in a urban environment).<br/><br/>

In the specific case of Browan Industrial Tracker, the accuracy comes from its on-chip GNSS and that is reported on the chipset datasheet as CEP (circular error probable) in meters. This means that 50% of the points are expected to be the provided value. 
            </Typography>
          </AccordionDetails>
        </Accordion>




        <Accordion
          key={9}
          square
          expanded={expanded === 9}
          onChange={handleAccordionChange(9)}>
          {/*Question 9*/}
          <AccordionSummary
            aria-controls={`panel9-content`}
            id={`panel9-header`}
          >
            <Typography className={css.questionTitle}>How to interpret the battery level reported in the application?</Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
           <b> LoRa Edge™ Reference Tracker: </b>The battery level value displayed in LoRa Cloud™ - Locator is an estimated value derived from the power consumption reported by the LR1110 chip that is included in the trackers. Specifically, an heuristic is applied to the computed power value of the LR1110 to estimate the overall device’s consumption; that will account for the rest of the device’s components such as the main MCU, LEDs and supporting sensors. The total power consumption is computed over the last several days (up to 7 days) and then scaled to a standard-capacity AA Alkaline battery of 2400 mAh. 
<br/><br/>
           <b>Browan Industrial Tracker: </b> The battery level for this device has been modelled from internal operations of the device which includes an embedded GNSS module. That model is used to estimate the total power consumption over the last several update (up to 7 days) and then scaled to a standard-capacity AA Alkaline battery of 2400 mAh.
<br/><br/>
           <b>All other devices: </b>at this time we do not have a power consumption model for this device. 

             </Typography>
          </AccordionDetails>
        </Accordion>









        <Accordion
          key={10}
          square
          expanded={expanded === 10}
          onChange={handleAccordionChange(10)}>
          {/*Question 10*/}
          <AccordionSummary
            aria-controls={`panel10-content`}
            id={`panel10-header`}
          >
            <Typography className={css.questionTitle}>Can I add my own gateways?</Typography>
            {expanded === 1 ? (
              <KeyboardArrowDownIcon style={{ color: "#00AEEF" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#00AEEF" }} />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.content}>
            Yes. LoRa Cloud™ Locator supports private networks and bringing your own gateways. <br/><br/>Since Locator uses AWS IoT Core for LoRaWAN® for setting 
            up your private network, please make sure you use of the &nbsp;
            <a style={{color:'#00AEEF',textDecoration:'underline',cursor:'pointer'}} href="https://devices.amazonaws.com/search?page=1&sv=iotclorawan&type=gateway" rel="noopener noreferrer" target="_blank">qualified gateways</a> for the service.
             </Typography>
          </AccordionDetails>
        </Accordion>










    </div>
  );
};

const useStyle = makeStyles({
  questionsAccordion: {
    fontFamily: "'Fira sans' !important",
    marginTop: ".8rem",
    fontWeight: "400",
    marginBottom: "1%",
    maxWidth: "90%",
    marginLeft: "3%",
  },
  arrowMobile:{
    fontSize:'4vw',

  },
  questionsAccordionMobile: {
    fontFamily: "'Fira sans' !important",
    marginTop: ".8rem",
    fontSize: "2.78vh",
    fontWeight: "400",
    marginBottom: "1%",
    maxWidth: "95%",
    marginLeft: "2%",
  },
  questionTitleMobile: {
    fontFamily: "'Fira sans' !important",
    color: "#00AEEF",
    fontWeight: "450",
    fontSize: "5vw",
    maxWidth: "90%",
    marginLeft: "3%",
  },
  questionTitle: {
    fontFamily: "'Fira sans' !important",
    color: "#00AEEF",
    fontWeight: "450",
    fontSize: "1.875rem",
    maxWidth: "90%",
    marginLeft: "3%",
  },
  content: {
    whiteSpace: "pre-line",
    fontSize: "1.3125rem",
    fontFamily: "'Fira sans' !important",
    fontWeight: "400",
    marginBottom: ".5%",
    color: "#333",
    marginTop: "0%",
    maxWidth: "90%",
    lineHeight: "1.5",
    marginLeft: "3%",
  },
  contentMobile: {
    whiteSpace: "pre-line",
    fontSize: "3vw",
    fontFamily: "'Fira sans' !important",
    fontWeight: "400",
    marginBottom: ".5%",
    color: "#333",
    marginTop: "0%",
    maxWidth: "90%",
    lineHeight: "1.5",
    marginLeft: "3%",
  },
});
