import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BreadcrumbsBar from "../landing_page/common/BreadcrumbsBar";

import FooterMobile from "./footerMobile";
import HeaderMobile from "./headerMobile";
import QuestionsAccordion from "../landing_page/faq/QuestionsAccordion";
import faqDataset from "../landing_page/faq/faqDataset";
import useWindowDimensions from "components/useWindowDimensions";
import { useHistory } from "react-router-dom";

const breadcrumbs_path = [["HOME", "/landing"], "HELP", "FAQ"];

export default () => {
  const css = useStyle();
  useEffect(() => {
    document.title = "Help - FAQ | LoRa Cloud™ Locator | Semtech";
  }, []);

  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    if (width > 900) {
      history.push("/help/faq");
    }
  }, [width, history]);

  return (
    <div>
      <HeaderMobile />
      <div style={{ marginTop: "12vh" }}></div>
      <div style={{ marginLeft: "-7%" }}>
        <BreadcrumbsBar path={breadcrumbs_path} id="homeLink" />
      </div>

      <h3 className="mainHeaderPages">FAQ</h3>

      <div className="page">
        <div
          className={css.sectionFAQ}
          style={{ fontFamily: "'Fira sans' !important" }}
        >
          <div className={css.pageContent}>
            <QuestionsAccordion faqList={faqDataset} />
          </div>
          <div className={css.sectionFAQButton}>
            {" "}
            <p className={css.sectionFAQtext}>
              If you have more questions, please
            </p>
            <a
              className={css.visitForum}
              href="https://forum.lora-developers.semtech.com/c/LoRa-Cloud-Services/LoRa-Cloud-Locator/44"
              rel="noopener noreferrer"
              target="_blank"
            >
              visit our forum
            </a>
          </div>
          <FooterMobile />
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  pageContent: {
    fontFamily: "'Fira sans' !important",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "100%",
  },
  sectionSubtitle: {
    fontFamily: "'Fira sans' !important",
    color: "#00AEEF",
    fontWeight: "550",
    fontSize: "4vw",
  },
  sectionFAQtext: {
    fontSize: "3.5vw",
    marginRight: "2%",
  },
  sectionFAQButton: {
    display: "flex",
    marginBottom: "10vh",
    color: "#333",
    marginRight: "10%",
    position: "relative",

    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  visitForum: {
    color: "#00AEEF",
    fontFamily: "FIra sans",
    fontSize: "3.5vw",
  },
}));
