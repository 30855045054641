import React, { useState, Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import DocumentationPage from "./components/landing_page/documentation/principal/principal";
import Main from "./components/landing_page/main/main";
import NetworksPage from "components/landing_page/networks/networksPage";
import Miromico from "components/landing_page/references/miromico";
import Truvami from "components/landing_page/references/truvami";
import TruvamiMobile from "components/landing_page_mobile/references/truvamiMobile";


import BrowanMobile from "components/landing_page_mobile/references/browanMobile";

import DigitalMatterMobile from "components/landing_page_mobile/references/digitalMatterMobile";

import EverynetMobile from "components/landing_page_mobile/references/everynetMobile";

import Browan from "components/landing_page/references/browan";
import Everynet from "components/landing_page/references/everynet";
import DigitalMatter from "components/landing_page/references/digitalMatter";
import TrackerProductsPage from "components/landing_page/trackers/TrackerProductsPage";
import SupportEmailPage from "components/landing_page/support/principal/SupportEmail";
import DocumentationPageMain from "components/landing_page/documentation/template/Documentation";
import SupportFaqPageMobile from "components/landing_page_mobile/SupportFaqPageMobile";
import WhatsNew from "components/landing_page/whats-new/whats-new";

import UseCases from "components/landing_page/use-cases/use-cases";
import HowToGetStartedMobile from "components/landing_page_mobile/HowToGetStartedMobile";
import TrakersMobile from "components/landing_page_mobile/trackersMobile";
import UseCasesMobile from "components/landing_page_mobile/useCasesMobile";
import WhatsNewMobile from "components/landing_page_mobile/whats-newMobile";


//import GatewayProductsPageMobile from "components/landing_page_mobile/GatewayProductsPageMobile";
import NetworksMobile from "components/landing_page_mobile/NetworksMobile";
import API from "services/axiosCall";
import SupportFaqPage from "components/landing_page/faq/SupportFaqPage";
import HowToGetStarted from "components/landing_page/how-to-get-started/HowToGetStarted";

const App = React.lazy(() => import("App"));

const fallbackComponent = <div>Loading...</div>;

const AppWithAuth = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [isUserVerified, setIsUserVerified] = useState(false);

  const [isReadingVariables, setIsReadingVariables] = useState(true);

  let userName = "";

  if (!isLoading) {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(async (token) => {
          window.localStorage.token = token;
          userName = user.name;

          if (!user.email_verified) {
            const userUpdated = await API.getAuth0UserInfo();
            user.email_verified=userUpdated.email_verified;
            setIsUserVerified(userUpdated.email_verified);
            setIsReadingVariables(false);
          } else {
            setIsUserVerified(true);
            setIsReadingVariables(false);
          }
        })
        .catch((e) => {
          if (e.error === "consent_required") {
            getAccessTokenWithPopup();
          } else {
            console.error("error", e);
            throw e;
          }
        });
    } else {
      userName = "";
      if (isReadingVariables) {
        setIsUserVerified(false);
        setIsReadingVariables(false);
      }
    }
  }

  return (
    <Suspense fallback={fallbackComponent}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            {isReadingVariables ? (
              fallbackComponent
            ) : isAuthenticated && isUserVerified ? (
              <Redirect to="/device-tracking" />
            ) : (
              <Main userData={userName} />
            )}
          </Route>
          <Route exact path="/landing">
            {isReadingVariables ? (
              fallbackComponent
            ) : isAuthenticated && isUserVerified ? (
              <Main userData={userName} />
            ) : (
              <Main userData={userName} />
            )}
          </Route>

          <Route path="/useCases">
            <UseCases />
          </Route>
          <Route path="/whatsNew">
            <WhatsNew />
          </Route>
          <Route path="/networks">
            <NetworksPage />
          </Route>
          <Route path="/useCasesMobile">
            <UseCasesMobile />
          </Route>
          <Route path="/whatsNewMobile">
            <WhatsNewMobile />
          </Route>
         
          <Route path="/trackersMobile">
            <TrakersMobile />
          </Route>
          <Route path="/GetStartedMobile">
            <HowToGetStartedMobile />
          </Route>
          <Route path="/networksMobile">
            <NetworksMobile />
          </Route>
          <Route exact path="/docs/:handle">
            <DocumentationPage userData={userName} />
          </Route>
          <Route path="/docs">
            <DocumentationPageMain />
          </Route>
          <Route path="/trackers">
            <TrackerProductsPage />
          </Route>
          <Route path="/help/email">
            <SupportEmailPage />
          </Route>
          <Route path="/help/faq">
            <SupportFaqPage />
          </Route>
          <Route path="/help/faqMobile">
            <SupportFaqPageMobile />
          </Route>
          <Route path="/help/gettingStarted">
            <HowToGetStarted />
          </Route>
           <Route path="/references/miromico">
            <Miromico/>
          </Route>
           <Route path="/references/digitalMatter">
            <DigitalMatter/>
          </Route>
           <Route path="/references/browan">
            <Browan/>
          </Route>
           <Route path="/references/everynet">
            <Everynet/>
          </Route>
            <Route path="/references/truvami">
            <Truvami/>
          </Route>
           <Route path="/references/miromicoMobile">
            <Miromico/>
          </Route>
           <Route path="/references/digitalMatterMobile">
            <DigitalMatterMobile/>
          </Route>
           <Route path="/references/browanMobile">
            <BrowanMobile/>
          </Route>
           <Route path="/references/everynetMobile">
            <EverynetMobile/>
          </Route>
             <Route path="/references/truvamiMobile">
            <TruvamiMobile/>
          </Route>

          
          <Route path="/:section">
            {isReadingVariables ? (
              fallbackComponent
            ) : isAuthenticated && isUserVerified ? (
              <App />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppWithAuth;
